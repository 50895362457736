import React from "react";

const Switch = ({ checked, onChange }) => {
  const toggleSwitch = () => {
    onChange(!checked);
  };

  return (
    <div
      className={`appxolo-switch ${checked ? "switch-on" : ""}`}
      onClick={toggleSwitch}
    >
      <div className="switch-handle"></div>
    </div>
  );
};

export default Switch;
