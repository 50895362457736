import React, { useCallback, useEffect } from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import CustomSelect from "../../../Components/etc/CustomSelect";

const DatabaseColumnNames = (props) => {
  const { value: columns = [], onChange, options } = props;

  const addNColumns = useCallback(
    (n) => {
      const newColumns = Array.from(Array(n)).map((x) => "");
      onChange([...columns, ...newColumns]);
    },
    [columns, onChange]
  );

  const setNthColumn = (column, n) => {
    onChange(update(columns, { $merge: { [n]: column } }));
  };

  const minNoOfInputs = options?.tableSelector?.minNoOfInputs;
  useEffect(() => {
    if (columns.length < minNoOfInputs) {
      addNColumns(minNoOfInputs - columns.length);
    }
  }, [columns, minNoOfInputs, addNColumns]);

  if (!options?.tableSelector?.additionalInput) return null;

  const database = props.databases?.find((x) => x._id === props.dbId);
  const table = database?.tables?.find((x) => x._id === props.tableId);
  const databaseColumns = table?.columns || [];

  return (
    <div className="optionDatabaseValueSelector">
      <div className="optionDatabaseValueSelectorLabel">{options.label}</div>

      {columns
        .filter((_, i) => i < options?.tableSelector?.maxNoOfInputs)
        .map((column, i) => {
          return (
            <CustomSelect
              key={i}
              value={column || ""}
              onChange={(option) => setNthColumn(option.value, i)}
              options={databaseColumns.map((x) => ({
                value: x.name,
                label: x.name,
              }))}
              placeholder={"Select Column"}
              listEmptyPlaceholder={<span>No Columns</span>}
              classNames={{
                head: "optionDatabaseValueSelectorSelect",
                label: "optionDatabaseValueSelectorSelectValue",
                chevron: "optionDatabaseValueSelectorSelectIco",
              }}
            />
          );
        })}
    </div>
  );
};

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(DatabaseColumnNames);
