import React, { PureComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import _ from "lodash";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import SideNav from "../Common/SideNav";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Avatar, { AvatarStack } from "../../Components/etc/Avatar";
import SearchInput from "../../Components/Input/SearchInput";
import config from "../../Config";
import CustomSelect from "../../Components/etc/CustomSelect";
import UserPresence from "../Builder/UserPresence";

const UsersScreen = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visibleModal, setVisibleModal] = useState(null);
  const [filter, setFilter] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, allUsers]);

  const isAdmin = ["owner", "admin"].includes(props.team.role);

  const load = async () => {
    try {
      setLoading(true);
      setError(null);
      await fetchMembers();
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
      setError(e.message);
      console.warn(e);
    }
  };

  const fetchProjects = async () => {
    try {
      const { projects } = await api.get("v1/project");
      setProjects(projects);
    } catch (e) {
      console.error(e.message);
    }
  };

  const fetchMembers = async () => {
    const { members } = await api.get(`v1/user/members`);
    setAllUsers(members);

    return members;
  };

  const applyFilter = async () => {
    let filteredUsers = allUsers;

    if (filter?.q) {
      const q = filter.q;
      const regex = new RegExp(`^${q}`, "i");
      filteredUsers = filteredUsers.filter(({ user }) => {
        let matchables = [
          user.firstName,
          user.email,
          user.lastName,
          user.firstName + " " + user.lastName,
        ];

        for (let i = 0; i < matchables.length; i++) {
          const matchable = matchables[i];
          if (regex.test(matchable)) return true;
        }
        return false;
      });
    }

    setFilteredUsers(filteredUsers);
  };

  const handleAddUser = async (user) => {
    try {
      setVisibleModal(null);

      const allUsers = await fetchMembers();

      const allMembers = [
        ...allUsers,
        { role: "member", user, createdAt: Date.now(), status: "active" },
      ];
      setAllUsers(allMembers);

      const members = allMembers.map((x) => ({
        ...x,
        user: x.user._id,
        projects: x.projects?.map((x) => ({ ...x, project: x.project._id })),
      }));

      await api.patch(`v1/user/members`, {
        members,
      });
    } catch (e) {
      console.warn(e);
      toast.error(e.message);
    }
    load();
  };

  const handleUpdateMember = async (member) => {
    try {
      const projects = member.projects?.map((x) => ({
        ...x,
        project: x.project._id,
      }));

      await api.patch(`v1/user/member/${member.user._id}`, {
        ..._.pick(member, ["role", "status"]),
        projects,
      });
    } catch (e) {
      toast.error(e.message);
    }
    load();
  };

  const handleRemoveMember = async (memberId) => {
    try {
      setModalData(null);
      setVisibleModal(null);

      const allMembers = await fetchMembers();

      let updatedMembers = allMembers?.filter((x) => x.user._id !== memberId);
      setAllUsers(updatedMembers);

      const members = updatedMembers.map((x) => ({
        ...x,
        user: x.user._id,
        projects: x.projects?.map((x) => ({ ...x, project: x.project._id })),
      }));

      await api.patch(`v1/user/members`, {
        members,
      });
    } catch (e) {
      toast.error(e.message);
    }
    load();
  };

  return (
    <>
      <div className="userPanel">
        <SideNav activeSidebarItem="users" key="sidenav" />

        <div className="upMain usersScreen">
          <NavBar activeLink="/users" />

          <div className="upHeader">
            <div className="upHeaderTop">
              <div className="upHeaderRow">
                <div className="upTitle">Users Console</div>
                {isAdmin ? (
                  <div
                    className="upAction"
                    onClick={() => setVisibleModal("addUser")}
                  >
                    <span>+</span> Add User
                  </div>
                ) : null}
              </div>

              <div className="upTabs">
                <div className="upTabsItem active">All Users</div>
              </div>
            </div>

            <div className="upHeaderBottom">
              <div className="projectSearch">
                <img
                  className="projectSearchIco"
                  src={require("../../Assets/img/user/search.png")}
                ></img>
                <SearchInput
                  type="text"
                  className="projectSearchInput"
                  placeholder="Search here"
                  value={filter?.q || ""}
                  onChange={(q) => setFilter({ ...(filter || {}), q })}
                />
              </div>

              <UserPresence component={{ _id: props.team.user._id }} />
            </div>
          </div>

          <div className="upBody">
            <div className="upBodyInner">
              <MemberList
                {...{
                  users: filteredUsers,
                  onRowClick: (member) => {
                    if (isAdmin) {
                      setModalData(member);
                      setVisibleModal("manageUser");
                      if (!projects) fetchProjects();
                    }
                  },
                }}
              />
            </div>
          </div>

          <AddUserModal
            key={"addUser_" + (visibleModal === "addUser")}
            visible={visibleModal === "addUser"}
            close={() => setVisibleModal(null)}
            existingMembers={allUsers}
            team={props.team}
            add={(user) => handleAddUser(user)}
          />

          <ManageUserModal
            key={"manageUser_" + (visibleModal === "manageUser" && modalData)}
            visible={visibleModal === "manageUser" && modalData}
            close={() => {
              setVisibleModal(null);
              setModalData(null);
            }}
            team={props.team}
            value={modalData}
            onChange={(member) => handleUpdateMember(member)}
            removeMember={(memberId) => handleRemoveMember(memberId)}
            projects={projects}
          />
        </div>
      </div>
    </>
  );
};

class MemberList extends PureComponent {
  groupMembersByInitial = (members) => {
    return members.reduce((acc, member) => {
      const user = member.user;
      const initial = (user.firstName || user.lastName || "")[0].toUpperCase();
      if (!acc[initial]) acc[initial] = [];
      acc[initial].push(member);
      return acc;
    }, {});
  };

  render() {
    const { users, onRowClick } = this.props;
    const grouped = this.groupMembersByInitial(users);

    return (
      <div className="fullDataTable">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Projects</th>
              <th>Role</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(grouped)
              .sort()
              .map((initial) => (
                <React.Fragment key={initial}>
                  <tr>
                    <td colSpan="5">
                      <div className="tableLetter">{initial}</div>
                    </td>
                  </tr>
                  {grouped[initial].map((item, index) => {
                    const {
                      user,
                      role,
                      projects,
                      status,
                      invitationKey,
                      acceptedAt,
                    } = item;
                    return (
                      <tr key={index} onClick={() => onRowClick(item)}>
                        <td>
                          <div className="userInfo">
                            <div className="userInfoIco">
                              <Avatar user={user} />
                            </div>
                            <div className="userInfoLabel">
                              {`${user.firstName || ""} ${
                                user.lastName || ""
                              }`.trim()}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tableRowify">
                            <div className="capsuleItem cpGray">
                              {user.email}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="projectsIcoList">
                            {!["member"].includes(role)
                              ? null
                              : projects?.map(({ project = {} }, projIndex) => (
                                  <div
                                    key={projIndex}
                                    className="projectIcoListItem"
                                  >
                                    <Avatar
                                      user={{
                                        firstName: project.name,
                                        image: project.image,
                                      }}
                                    />
                                  </div>
                                ))}
                          </div>
                        </td>
                        <td>
                          <div className="tableRowify">
                            <div
                              className={`capsuleItem ${
                                role === "admin" ? "cpBlue" : "cpGreen"
                              }`}
                            >
                              {
                                {
                                  admin: "Administarator",
                                  member: "Team Memeber",
                                }[role]
                              }
                            </div>
                          </div>
                        </td>
                        <td>
                          {status === "active" && !acceptedAt ? (
                            <>
                              Invite Sent{" "}
                              <a
                                style={{
                                  color: "#dedede",
                                  fontSize: "small",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(
                                    `${config.apiUrl}v1/user/invitation/${invitationKey}`
                                  );
                                }}
                              >
                                copy
                              </a>
                            </>
                          ) : (
                            _.startCase(status)
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const AddUserModal = (props) => {
  const { visible, close } = props;
  const [users, setUsers] = useState(null);
  const [q, setQ] = useState("");

  const validateEmail = async (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSearchUser = async (q) => {
    setQ(q);

    let email = q.length >= 3 && (await validateEmail(q)) ? q : null;
    if (
      props.team.user.email === email ||
      props.existingMembers?.find((x) => x.user.email === email)
    )
      email = null;

    if (!email) {
      setUsers(null);
    } else {
      await api
        .get("v1/user/search", { email })
        .then(({ users }) => setUsers(users?.filter((_, i) => i === 0)))
        .catch((e) => console.warn(e.message));
    }
  };

  const matchedUser = users?.[0];

  return (
    <div
      className="upModal centerModal"
      style={{ display: visible ? "flex" : "none" }}
    >
      <div className="upModalItem">
        <div className="upmPreHead customHeadAlign">
          <img
            onClick={close}
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
          ></img>

          <div className="upmPreHeadTitle">Add User</div>
        </div>

        <div className="upmBody">
          <h1 className="mb0">Add User</h1>
          <div className="bgNotes mb40 mt20">
            Add new users to your project by entering their details below. They
            will receive an invitation to join and collaborate.
          </div>

          <div className="upmAvatarWrapper">
            <div className="projectLineIco upmAvatar">
              <Avatar user={matchedUser || {}} />..
            </div>
          </div>

          <div className="upmRowGroup mt50">
            <div className="upmRow aCenter">
              <div className="upmRowLabel">Email</div>
              <div className="upmRowValue">
                <SearchInput
                  type="text"
                  placeholder="Email"
                  className="upmRowInput"
                  value={q}
                  onChange={handleSearchUser}
                />

                {matchedUser ? (
                  <div
                    className="defaultButton"
                    onClick={() => props.add(matchedUser)}
                  >
                    Add
                  </div>
                ) : null}
              </div>
            </div>
            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">First Name</div>
              <div className="upmRowValue">
                <input
                  value={matchedUser?.firstName || ""}
                  onChange={() => {}}
                  placeholder="Enter name"
                  className="upmRowInput"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="mpmActionRow mt50 mb15">
            <div></div>
            <div className="darkDefaultButton">Submit</div>
          </div>
        </div>

        {/* <div className="upmBody">
          <div className="">
            <div className="selectProjectsHead">
              <img
                className="projectSearchIco"
                src={require("../../Assets/img/user/search.png")}
              ></img>
              <SearchInput
                type="text"
                placeholder="Search User By Email"
                className="selectProjectsInput"
                value={q}
                onChange={handleSearchUser}
              />
            </div>
            <div className="selectProjectsBody">
              {users?.map((user) => (
                <div className="projectLine" key={user._id}>
                  <div className="projectLineContext">
                    <div className="projectLineIco">
                      <Avatar user={user} />
                    </div>
                    <div className="projectLineName">
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div
                    className="defaultButton"
                    onClick={() => props.add(user)}
                  >
                    Add
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const ManageUserModal = (props) => {
  const {
    visible,
    close,
    value: member,
    onChange,
    removeMember,
    projects: allProjects,
  } = props;

  const [role, setRole] = useState(member?.role);
  const [status, setStatus] = useState(member?.status);
  const [projects, setProjects] = useState(member?.projects);

  if (!member || !visible) return null;

  const { user, invitationKey, createdAt } = member;

  const _setRole = async (role) => {
    setRole(role);
    onChange({ role, user });
  };

  const _setStatus = async (status) => {
    setStatus(status);
    onChange({ status, user });
  };

  const _setProjects = async (projects) => {
    setProjects(projects);
    onChange({ projects, user });
  };

  return (
    <div className="upModal">
      <div className="upModalItem">
        <div className="upmPreHead customHeadAlign">
          <img
            onClick={close}
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
          ></img>

          <div className="upmPreHeadTitle">Manage User</div>
        </div>

        <div className="upmBody">
          <div className="upmMainRow">
            <div className="upmUserAvatar">
              <Avatar user={user} />
            </div>
            <div className="userTitleLarge">
              {user.firstName} {user.lastName}
            </div>
          </div>

          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Full Name</div>
              <div className="upmRowValue">
                {user.firstName} {user.lastName}
              </div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Email Address</div>
              <div className="upmRowValue">
                <div className="capsuleItem cpGray">{user.email}</div>
              </div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Role</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) => _setRole(option.value)}
                  value={role || ""}
                  options={[
                    { value: "member", label: "Team Member" },
                    { value: "admin", label: "Administrator" },
                  ]}
                  placeholder={"Select Role"}
                  classNames={{
                    head: "selectBox",
                    label: "",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Created</div>
              <div className="upmRowValue">
                {new Date(createdAt).toLocaleDateString()}
              </div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Status</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) => _setStatus(option.value)}
                  value={status || ""}
                  options={[
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                  ]}
                  placeholder={"Select Status"}
                  classNames={{
                    head: "selectBox",
                    label: "",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>
          </div>

          {["member"].includes(role) ? (
            <>
              <div className="upmRowLabel mt50 mb15">Projects</div>
              <ProjectList
                {...{ allProjects, projects, setProjects: _setProjects }}
              />
            </>
          ) : null}

          <div className="mpmActionRow mt50 mb15">
            <div
              className="negativeButton"
              onClick={() => removeMember(user._id)}
            >
              Remove User
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectList = ({ allProjects, projects, setProjects }) => {
  const [q, setQ] = useState("");
  const [searchedProjects, setSearchedProjects] = useState(null);

  useEffect(() => {
    if (!q) {
      setSearchedProjects(null);
    } else {
      const regex = new RegExp(`^${q}`, "i");

      setSearchedProjects(
        allProjects
          .filter((x) => regex.test(x.name) || regex.test(x.subdomain))
          .map((x) => ({ project: x }))
      );
    }
  }, [q]);

  const handleRemove = (project) => {
    try {
      const index = projects.findIndex(
        (x) => x.project?._id?.toString() === project?._id?.toString()
      );

      setProjects(update(projects, { $splice: [[index, 1]] }));
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleAdd = (project) => {
    try {
      setProjects(
        update(projects, { $push: [{ permission: "admin", project }] })
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className="selectProjects">
      <div className="selectProjectsHead">
        <img
          className="projectSearchIco"
          src={require("../../Assets/img/user/search.png")}
        ></img>
        <SearchInput
          value={q}
          onChange={setQ}
          type="text"
          placeholder="Search here"
          className="selectProjectsInput"
        />
      </div>
      <div className="selectProjectsBody">
        {(searchedProjects || projects)?.map(({ project = {} }, i) => {
          const isSelected = projects?.find(
            (x) => x.project?._id?.toString() === project?._id?.toString()
          );
          return (
            <div className="projectLine" key={project._id || i}>
              <div className="projectLineContext">
                <div className="projectLineIco">
                  <Avatar
                    user={{ firstName: project.name, image: project.image }}
                  />
                </div>
                <div className="projectLineName">
                  {project.name}{" "}
                  {project.data?.platform ? `(${project.data?.platform})` : ""}
                </div>
              </div>
              {isSelected ? (
                <div
                  className="defaultButton"
                  onClick={() => handleRemove(project)}
                >
                  Remove
                </div>
              ) : (
                <div
                  className="defaultButton"
                  onClick={() => handleAdd(project)}
                >
                  Add
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

export default connect(mapStateToProps)(UsersScreen);
