import React, { createRef, useRef, useState } from "react";
import _ from "lodash";
import update from "immutability-helper";

import api from "../../Services/Api/api";
import { toast } from "react-toastify";
import CustomSelect from "../../Components/etc/CustomSelect";
import DraggableList from "../../Components/etc/DraggableList";
import PaymentComponent from "../../Components/etc/PaymentComponent";

const AddDatabasePopup = (props) => {
  const { visible, close, callback } = props;

  const [form, setForm] = useState({
    type: "remote",
    tables: [],
  });
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [newTable, setNewTable] = useState("");
  const [newColumn, setNewColumn] = useState("");
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [selectedTableName, setSelectedTableName] = useState(null);
  const [costDetails, setCostDetails] = useState(null);

  const newDatabaseCost =
    form.type === "local"
      ? costDetails?.newLocalDatabaseCost
      : costDetails?.newDatabaseCost;

  const paymentInformationRef = useRef();

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const selectedTableIndex = form.tables?.findIndex(
    (x) => x.name === selectedTableName
  );
  const selectedTable = form.tables?.[selectedTableIndex];
  const setSelectedTable = (table) => {
    if (selectedTableIndex > -1)
      updateForm({
        tables: update(form.tables || [], {
          $merge: { [selectedTableIndex]: table },
        }),
      });
  };

  const handleSubmit = async () => {
    try {
      if (loading) return;

      let invalidValues = [];
      ["name", "type"].map((x) => {
        if (!form[x]) invalidValues.push(x);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      const paymentMethodId =
        await paymentInformationRef.current.getPaymentMethodId();

      if (!paymentMethodId && newDatabaseCost) return null;

      const { database } = await api.post("v1/database", {
        ..._.pick(form, ["name", "type"]),
        tables: (form.tables || []).map((x) => x.name),
        paymentMethodId: paymentMethodId || null,
        cost: newDatabaseCost || 0,
      });

      setLoading(false);
      close();

      callback(null, database);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);

      /* eslint-disable-next-line no-unused-expressions */
      paymentInformationRef.current?.load?.().catch(console.log);
    }
  };

  const handleAddTableForm = (e) => {
    e.preventDefault();
    if (newTable) {
      updateForm({
        tables: [...(form.tables || []), { name: newTable }].filter(
          (x, i, arr) => i === arr.findIndex((y) => y.name === x.name)
        ),
      });
      setNewTable("");
    }
  };

  const handleAddColumn = (e) => {
    e.preventDefault();
    if (newColumn) {
      setSelectedTable({
        ...(selectedTable || {}),
        columns: [
          ...(selectedTable?.columns || []),
          { name: newColumn },
        ].filter((x, i, arr) => i === arr.findIndex((y) => y.name === x.name)),
      });
      setNewColumn("");
    }
  };

  const validateForm = async ({ required, validateTableLength = false }) => {
    try {
      let invalidValues = [];
      (required || []).map((x) => {
        if (!form[x]) invalidValues.push(`"${_.startCase(x)}"`);
      });

      if (invalidValues.length) {
        throw new Error(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      if (validateTableLength) {
        if (!form.tables?.length) throw new Error("Please add table(s)");
        setSelectedTableName(form.tables?.[0].name);
      }

      setFormError(null);
      setLoading(false);

      return true;
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      return false;
    }
  };

  const step1 = () => {
    return (
      <>
        <div className="bgNotes mb40 mt20">
          Create a new database to organize and manage your app's data. Give it
          a name to get started.
        </div>
        {formError ? <div className="errormsg">{formError}</div> : null}
        <div className="upmRowGroup mt50">
          <div className="upmRow aCenter">
            <div className="upmRowLabel">Database Name</div>
            <div className="upmRowValue">
              <input
                type="text"
                placeholder="Enter name"
                className="upmRowInput"
                required
                value={form?.name || ""}
                onChange={(e) => {
                  updateForm({ name: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="upmRow aCenter removeBorderBottom">
            <div className="upmRowLabel">Type</div>
            <div className="upmRowValue">
              <CustomSelect
                onChange={(option) =>
                  updateForm({
                    type: option.value,
                  })
                }
                value={form?.type || ""}
                options={[
                  {
                    value: "remote",
                    label: "Remote Database",
                    titleLabel: "Remote",
                  },
                  {
                    value: "local",
                    label: "Local Database",
                    titleLabel: "Local",
                  },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "selectBox",
                  label: "optionInputIconBoxField",
                }}
                jointActionRow={
                  <img
                    className="selectIco"
                    src={require("../../Assets/img/user/select.png")}
                  ></img>
                }
              />
            </div>
          </div>
        </div>

        <div className="mpmActionRow mt50 mb15">
          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
          <div
            className="darkDefaultButton"
            onClick={async () => {
              if (
                await validateForm({
                  required: ["name", "type"],
                })
              )
                setActiveStepIndex(1);
            }}
          >
            {loading ? "Loading" : "Continue"}
          </div>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <>
        <div className="bgNote mb20 mt20">
          Adding tables is optional; you can choose to add them now or later as
          needed.
        </div>
        {formError ? <div className="errormsg">{formError}</div> : null}
        <div className="upmRowGroup mt50">
          <form onSubmit={handleAddTableForm}>
            <div className="upmRow">
              <div className="upmRowLabel">Add Table</div>
              <div className="upmRowValue">
                <input
                  type="text"
                  placeholder="Enter Table Name"
                  className="upmRowInput"
                  required
                  value={newTable || ""}
                  onChange={(e) => {
                    const columnNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
                    const value = e.target.value;
                    if (!value || columnNameRegex.test(value))
                      setNewTable(value);
                  }}
                />
              </div>
              <div onClick={handleAddTableForm}>Submit</div>
            </div>
          </form>

          <div>
            <DraggableList
              droppableId="new-db-table-list"
              value={form.tables?.map((x) => ({
                id: x.name,
                content: (
                  <div
                    style={{
                      padding: "10px",
                      marginBottom: "10px",
                      background: "#fff",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      cursor: "grab",
                    }}
                  >
                    <div>_icon_</div>
                    <div>{x.name}</div>
                    <div
                      onClick={() =>
                        updateForm({
                          tables: form.tables?.filter(
                            (table) => table.name !== x.name
                          ),
                        })
                      }
                    >
                      Delete
                    </div>
                  </div>
                ),
                item: x,
              }))}
              onChange={(x) => updateForm({ tables: x.map((x) => x.item) })}
              classNames={{
                droppableList: "tablelist",
              }}
            />
          </div>
        </div>

        <div className="mpmActionRow mt50 mb15">
          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
          <div
            className="darkDefaultButton"
            onClick={async () => {
              //   if (
              //     await validateForm({
              //       required: ["name"],
              //       validateTableLength: true,
              //     })
              //   )
              setActiveStepIndex(2);
            }}
          >
            {loading ? "Loading" : "Continue"}
          </div>
        </div>
      </>
    );
  };

  const step3 = () => {
    return (
      <>
        <div className="upmRowGroup mt50">
          <div className="bgNote mb20 mt20">Summary</div>
          <div className="upmRow">
            <div className="upmRowLabel">Database Name</div>
            <div className="upmRowValue">
              <div>{form.name}</div>
            </div>
          </div>

          <div className="upmRow">
            <div className="upmRowLabel">Type</div>
            <div className="upmRowValue">{_.startCase(form.type)}</div>
          </div>

          <div>Tables</div>
          {form.tables?.map((table) => (
            <div key={table.name}>
              <div>{table.name}</div>
              {table.columns?.map((column) => (
                <div key={column.name} style={{ marginLeft: "20px" }}>
                  {column.name}
                </div>
              ))}
            </div>
          ))}
        </div>

        <PaymentInformation
          databaseType={form.type}
          team={props.team}
          ref={paymentInformationRef}
          onLoad={(x) => setCostDetails(x)}
        />

        {formError ? <div className="errormsg">{formError}</div> : null}
        <div className="mpmActionRow mt50 mb15">
          <div
            className="darkDefaultButton"
            onClick={async () => {
              if (
                await validateForm({
                  required: ["name", "type"],
                })
              )
                handleSubmit();
            }}
          >
            {loading ? "Loading" : "Submit"}
          </div>

          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
        </div>
      </>
    );
  };

  const steps = [
    {
      title: "Database Details",
      render: step1,
    },
    {
      title: "Database Tables",
      render: step2,
    },
    {
      title: "Review",
      render: step3,
    },
  ];

  return (
    <div
      className={"upModal centerModal"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          {/* <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img> */}

          {/* <div className="upmPreHeadTitle">{steps[activeStepIndex].title}</div> */}

          <div className="stageRow">
            {["Details", "Columns", "Review"].map((step, i) => (
              <div
                className={`stageRowItem ${
                  activeStepIndex === i ? "active" : ""
                } ${activeStepIndex > i ? "enabled" : ""}`}
                onClick={() =>
                  activeStepIndex > i ? setActiveStepIndex(i) : null
                }
                key={step}
              >
                <div className={`stageNumber`}>{i + 1}</div>
                <div className="stageLabel">{step}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="upmBody">
          <h1 className="mb0">Database Details</h1>

          {steps[activeStepIndex].render()}
        </div>
      </div>
    </div>
  );
};

class PaymentInformation extends React.Component {
  state = {
    selectedMethod: this.paymentMethods?.length
      ? this.paymentMethods?.[this.paymentMethods.length - 1]?.id || ""
      : "new",
    clientSecret: null,
    loading: false,
    costDetails: null,
    error: null,
  };

  paymentComponentRef = createRef();

  get paymentMethods() {
    return this.props.team.subscription?.paymentMethods;
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const costDetails = await api.get("v1/user/subscription/cost");
      this.setState({ costDetails, loading: false });
      this.props.onLoad(costDetails);
    } catch (e) {
      this.setState({ error: e.message });
      this.setState({ loading: false });
      toast.error("Error loading payment information");
    }
  }

  async getPaymentMethodId() {
    if (this.state.selectedMethod && this.state.selectedMethod !== "new")
      return this.state.selectedMethod;
    else {
      const setupIntent =
        await this.paymentComponentRef.current?.handleSetupIntent?.();

      return setupIntent?.payment_method;
    }
  }

  getLabel(paymentMethod) {
    return (
      <span>{`${paymentMethod.type}: ${
        paymentMethod.type === "card"
          ? `${paymentMethod?.card?.brand || ""} ${paymentMethod.card.last4}`
          : ""
      }`}</span>
    );
  }

  render() {
    const newDatabaseCost =
      this.props.databaseType === "local"
        ? this.state.costDetails?.newLocalDatabaseCost
        : this.state.costDetails?.newDatabaseCost;

    if (this.state.loading && !this.state.costDetails)
      return (
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      );
    else if (this.state.error) {
      return <div style={{ color: "red" }}>{this.state.error}</div>;
    } else if (!this.state.costDetails) {
      return null;
    }

    return (
      <>
        <div className="upmRowGroup mt50">
          <div className="upmRow aCenter">
            <div className="upmRowLabel">Cost</div>
            <div className="upmRowValue">${newDatabaseCost}</div>
          </div>
          {newDatabaseCost ? (
            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">Payment Method</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) =>
                    this.setState({ selectedMethod: option.value })
                  }
                  value={this.state.selectedMethod || ""}
                  options={[
                    { value: "new", label: "Add New" },
                    ...(this.paymentMethods || []).map((x) => ({
                      value: x.id,
                      label: this.getLabel(x),
                    })),
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "selectBox",
                    label: "optionInputIconBoxField",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        {this.state.selectedMethod === "new" && newDatabaseCost ? (
          <PaymentComponent
            ref={this.paymentComponentRef}
            clientSecret={this.state.clientSecret}
            setClientSecret={(x) => this.setState({ clientSecret: x })}
          />
        ) : null}
      </>
    );
  }
}

export default AddDatabasePopup;
