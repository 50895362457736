import React, {
  Component,
  createRef,
  useRef,
  useEffect,
  useState,
} from "react";
import _ from "lodash";

class EditableCell extends Component {
  constructor(props) {
    super(props);
    this.editableRef = createRef();

    let items = this.parseValue(this.props.value);
    items = items.length ? items : [{ key: Math.random(), value: "" }];

    if (items.length > 1) {
      items = [...items, { key: Math.random(), value: "" }];
    }

    this.items = items;
    this.state = {
      items,
      editingIndex: items.length - 1,
      cursorPosition: "end",
    };
  }

  filterOutEmptyItems(items, opt = {}) {
    return items.filter((x, i) => {
      return (
        (opt.leaveLast ? i === this.items.length - 1 : false) ||
        x.value.trim() !== ""
      );
    });
  }

  parseValue = (_value = "") => {
    let value = _value?.toString();
    return (value || "")
      .split(",")
      .map((str) => ({ value: str.trim(), key: Math.random().toString() }))
      .filter((item) => item.value.length > 0);
  };

  splitStrByComma(inputString) {
    // Split the string by comma
    const parts = inputString.split(",");
    let emptyIndices = [];
    let result = [];
    let emptyValueAdded = false;

    parts.forEach((part, index) => {
      if (part === "") {
        if (!emptyValueAdded && parts.some((p) => p !== "")) {
          // Allow one empty value if there's at least one non-empty value
          result.push(part);
          emptyIndices.push(index);
          emptyValueAdded = true;
        }
      } else {
        result.push(part);
      }
    });

    return {
      result,
      emptyIndices,
    };
  }

  handleChange(item, index) {
    let value = item.value || "";
    let editingIndex = this.state.editingIndex;

    let { result: valueList, emptyIndices } = this.splitStrByComma(value);

    if (!valueList.length) valueList.push(""); // Prevent changing focus on item edit

    const newItems = valueList.map((val, i) => ({
      key:
        i === 0 && valueList.length === 1 ? item.key : Math.random().toString(),
      value: val,
    }));

    editingIndex += emptyIndices.length ? emptyIndices[0] : 0;

    if (!newItems.length) editingIndex = Math.max(0, editingIndex - 1);

    let updatedItems = this.items.flatMap((currentItem, i) =>
      i === index ? newItems : [currentItem]
    );

    if (this.state.selectAllPressed) {
      updatedItems = [];
      editingIndex = 0;
    }

    if (!updatedItems.length) updatedItems = [{ ...item, value: "" }];

    this.items = updatedItems;

    if (
      valueList.length !== 1 ||
      editingIndex !== this.state.editingIndex ||
      this.state.selectAllPressed
    )
      this.setState({
        editingIndex,
        selectAllPressed: null,
      });

    // this.props.onChange(updatedItems.map((x) => x.value).join(","));
  }

  updateEditingIndex(direction) {
    this.setState((prevState) => {
      const { editingIndex } = prevState;
      const items = this.items;

      let nextKey = null;
      for (
        let i = editingIndex + direction;
        i >= 0 && i < items.length;
        i += direction
      ) {
        if (items[i]?.value.trim() !== "") {
          nextKey = items[i].key;
          break;
        }
      }

      // let filteredItems = items.filter((item) => item.value.trim() !== "");
      let filteredItems = this.filterOutEmptyItems(items, { leaveLast: true });

      filteredItems = filteredItems.length
        ? filteredItems
        : [{ key: Math.random(), value: "" }];

      const newIndex =
        nextKey !== null
          ? filteredItems.findIndex((item) => item.key === nextKey)
          : Math.max(
              0,
              Math.min(editingIndex + direction, filteredItems.length - 1)
            );

      this.items = filteredItems;
      return {
        editingIndex: newIndex,
        cursorPosition: direction > 0 ? "start" : "end",
      };
    });
  }

  focusPrev(opt) {
    if (opt.key === "Backspace" && this.state.selectAllPressed) {
      this.items = [{ key: Date.now(), value: "" }];
      this.setState({ editingIndex: 0, selectAllPressed: null });
    } else {
      this.updateEditingIndex(-1);
    }
  }

  focusNext() {
    this.updateEditingIndex(+1);
  }

  handleClick() {
    this.setState(
      (prevState) => {
        // let filteredItems = this.items.filter(
        //   (item) => item.value.trim() !== ""
        // );

        let filteredItems = this.filterOutEmptyItems(this.items, {
          leaveLast: true,
        });

        filteredItems = filteredItems.length
          ? filteredItems
          : [{ key: Math.random(), value: "" }];

        this.items = filteredItems;
        return {
          editingIndex: null, // Clear editing index temporarily
          selectAllPressed: null,
        };
      },
      () => {
        this.setState((prevState) => ({
          editingIndex: Math.max(0, this.items.length - 1),
        }));
      }
    );
  }

  render() {
    return (
      <div
        className={
          "editableCellWrapper" +
          (this.state.selectAllPressed ? " selected" : "")
        }
        onClick={this.handleClick.bind(this)}
      >
        {this.items.map((item, index) => {
          const isEditing = this.state.editingIndex === index;

          return (
            <EditableCapsule
              {...{
                key: item.key,
                isEditing,
                value: item.value,
                onChange: (str) =>
                  this.handleChange({ ...item, value: str }, index),
                focusPrev: this.focusPrev.bind(this),
                focusNext: this.focusNext.bind(this),
                onClick: (e) => {
                  e.stopPropagation();
                  if (
                    this.state.editingIndex !== index &&
                    (item.value || this.items.length - 1 === index)
                  ) {
                    // this.items = this.items.filter((x) => x.value?.length);
                    this.items = this.filterOutEmptyItems(this.items, {
                      leaveLast: true,
                    });

                    this.setState({
                      editingIndex: this.items.findIndex(
                        (x) => x.key === item.key
                      ),
                    });
                  }
                },
                submit: () =>
                  this.props.submit(this.items.map((x) => x.value).join(",")),
                cursorPosition: this.state.cursorPosition,
                showTrailingComma: index < this.items.length - 1,
                handleSelectAll: (e) => {
                  if (this.items.length > 1) {
                    e.preventDefault();
                    this.setState({ selectAllPressed: Date.now() });
                  }
                },
              }}
            />
          );
        })}
      </div>
    );
  }
}

const isValidUrl = (url) => {
  try {
    return new URL(url);
  } catch (_) {
    return false;
  }
};

const EditableCapsule = (props) => {
  const {
    isEditing,
    value,
    onChange,
    focusPrev,
    focusNext,
    onClick,
    submit,
    cursorPosition,
    showTrailingComma,
    handleSelectAll,
  } = props;
  const spanRef = useRef();

  const focus = (position = "end") => {
    spanRef.current.focus();
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(spanRef.current);

    if (!spanRef.current.childNodes[0]) {
      range.collapse(false);
    } else {
      if (position === "start") {
        range.setStart(spanRef.current.childNodes[0], 0); // Start of the content
      } else {
        range.setStart(
          spanRef.current.childNodes[0],
          spanRef.current.textContent.length // End of the content
        );
      }
      range.collapse(true); // Collapse the range to the cursor position
    }

    selection.removeAllRanges();
    selection.addRange(range);
  };

  useEffect(() => {
    if (isEditing && spanRef.current) {
      focus(cursorPosition);
    }
  }, [isEditing]);

  const handleInput = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    // Save cursor position
    const cursorPosition = range.startOffset;

    // Update the value
    const currentValue = spanRef.current.textContent;
    onChange(currentValue);

    // Restore cursor position after state update
    // setTimeout(() => {
    //   if (spanRef.current?.childNodes?.[0]) {
    //     const updatedRange = document.createRange();
    //     updatedRange.setStart(
    //       spanRef.current.childNodes[0],
    //       Math.min(cursorPosition, spanRef.current.textContent.length)
    //     );
    //     updatedRange.collapse(true);

    //     selection.removeAllRanges();
    //     selection.addRange(updatedRange);
    //   }
    // }, 0);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      // Allow newline with Shift+Enter
      // e.preventDefault();
      // const currentValue = spanRef.current.textContent + "\n";
      // onChange(currentValue);
    } else if (e.key === "Enter") {
      // Submit on Enter
      e.preventDefault();
      submit();
    } else if (e.key === "ArrowLeft" || e.key === "Backspace") {
      // Move focus to the previous capsule
      if (window.getSelection().anchorOffset === 0) {
        focusPrev({ key: e.key });
      }
    } else if (e.key === "ArrowRight") {
      // Move focus to the next capsule
      const currentValue = spanRef.current.textContent;

      if (
        window.getSelection().anchorOffset === currentValue.length ||
        currentValue.length === 0
      ) {
        focusNext();
      }
    } else if ((e.ctrlKey || e.metaKey) && e.key === "a") {
      console.log("select all");
      handleSelectAll(e);
    }
  };

  const getUrlMime = (validUrl) => {
    const mime_type = validUrl.searchParams.get("mime_type");
    const mime = mime_type?.split("/")[0];

    return mime || "";
  };

  const validUrl = isValidUrl(value);
  const mime = validUrl ? getUrlMime(validUrl) : "";
  const urlString = _.startCase(mime || "URL");

  return (
    <span className={"editableMainBlock"} onClick={onClick}>
      <span
        className={
          "editable-span" +
          (isEditing ? " active" : " capsule") +
          (showTrailingComma ? "" : " last") +
          (validUrl ? " url " + mime : "") +
          (value.trim().length ? "" : " empty")
        }
        ref={spanRef}
        contentEditable={isEditing}
        suppressContentEditableWarning={isEditing}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
      >
        {isEditing || !validUrl ? value : urlString}
      </span>
      {showTrailingComma ? <span>,</span> : null}
    </span>
  );
};

export default EditableCell;
