import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import _ from "lodash";
import { connect } from "react-redux";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import SideNav from "../Common/SideNav";
import config from "../../Config";
import navigationModule from "../../Modules/navigation/navigation-module";
import { ProjectDetailPopup } from "./ProjectDetailPopup";
import withRouter from "../../Components/Wrapper/with-router";
import CustomSelect from "../../Components/etc/CustomSelect";
import SearchInput from "../../Components/Input/SearchInput";
import { toast } from "react-toastify";
import UserPresence from "../Builder/UserPresence";
import Avatar, { AvatarStack } from "../../Components/etc/Avatar";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import AddProjectPopup from "./AddProjectPopup";

const SCREEN_NAME = "PROJECTS";

const ProjectsScreen = (props) => {
  const allProjects = props.projects || null;
  const setAllProjects = (projects) => props.setScreenState({ projects });

  const [filteredProjects, setFilteredProjects] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [visibleModal, setVisibleModal] = useState(null);
  const [filter, setFilter] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  const projectDetailsId = props.router.searchParams?.project_detail;
  const isAdmin = ["owner", "admin"].includes(props.team.role);

  useEffect(() => {
    fetchProjects();
    fetchMembers();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [allProjects, filter]);

  const fetchMembers = async () => {
    const { members } = await api.get(`v1/user/members`);
    setAllUsers(members);

    return members;
  };

  const applyFilter = () => {
    if (!filter) {
      setFilteredProjects(allProjects);
    } else {
      let nameRegex = filter.q ? new RegExp("^" + filter.q, "i") : null;
      let filteredProjects = allProjects?.filter((project) => {
        if (filter.platform && project?.data?.platform !== filter.platform)
          return false;

        if (filter.q && nameRegex) {
          let match =
            nameRegex.test(project.name) || nameRegex.test(project.subdomain);
          if (!match) return false;
        }
        return true;
      });
      setFilteredProjects(filteredProjects);
    }
  };

  const fetchProjects = async () => {
    try {
      const { projects } = await api.get("v1/project");
      setAllProjects(projects);
    } catch (e) {
      console.error(e.message);
    }
  };

  const deleteProject = async (project) => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this project ? Confirm by typing the project name"
      );
      if (typed && typed === project.name) {
        await api.delete(`v1/project/${project._id}`);
        await fetchProjects();
      } else if (typed !== null) {
        window.alert("Name mismatched");
        deleteProject(project);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  // const handleEditSubmit = async (event, form) => {
  //   event.preventDefault();

  //   try {
  //     if (loading) return;

  //     setLoading(true);
  //     setFormError(null);

  //     let image = form.image;

  //     if (form.newImage && form.image) {
  //       const fileRes = await api.media("v1/file", { file: form.image });
  //       image = fileRes?.file?._id;
  //     }

  //     await api
  //       .put("v1/project", {
  //         _id: form._id,
  //         name: form.name,
  //         subdomain: form.subdomain,
  //         description: form.description,
  //         image,
  //       })
  //       .catch(async (e) => {
  //         if (form.newImage && image) {
  //           api.delete(`v1/file/${image}`).catch(console.error);
  //         }
  //         throw e;
  //       });
  //     setLoading(false);
  //     setEditModal(null);

  //     fetchProjects();
  //   } catch (e) {
  //     setLoading(false);
  //     setFormError(e.message);
  //   }
  // };

  return (
    <div className="userPanel">
      <SideNav activeSidebarItem="projects" key="sidenav" />
      <div className="upMain projectsScreen">
        <NavBar activeLink="/projects" />
        <div className="upHeader">
          <div className="upHeaderTop">
            <div className="upHeaderRow">
              <div className="upTitle">Projects</div>
              {isAdmin ? (
                <div
                  className="upAction"
                  onClick={() => setVisibleModal("addProject")}
                >
                  <span>+</span> Add Project
                </div>
              ) : null}
            </div>

            <div className="upTabs">
              {[
                { value: null, label: "All Projects" },
                { value: "web", label: "Web Applications" },
                { value: "mobile", label: "Mobile Applications" },
              ].map((item) => (
                <div
                  key={item.value}
                  className={
                    "upTabsItem " +
                    (filter?.platform == item.value ? "active" : "")
                  }
                  onClick={() =>
                    setFilter({ ...(filter || {}), platform: item.value })
                  }
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>

          <div className="upHeaderBottom">
            <div className="projectSearch">
              <img
                className="projectSearchIco"
                src={require("../../Assets/img/user/search.png")}
              ></img>
              <SearchInput
                type="text"
                className="projectSearchInput"
                placeholder="Search here"
                value={filter?.q || ""}
                onChange={(q) => setFilter({ ...(filter || {}), q })}
              />
            </div>

            <UserPresence component={{ _id: props.team.user._id }} />
          </div>
        </div>

        <div className="upBody">
          <div className="upBodyInner">
            <div className="upProjectList">
              {filteredProjects?.map((project) => (
                <div className="upProjectItem" key={project._id}>
                  <div className="upiHeader">
                    <div className="upiIco">
                      {project.image ? (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={api.getFileLink(project.image)}
                          alt="icon"
                        />
                      ) : null}
                    </div>
                    <div className="upiMeta">
                      <div className="upiTitle">{project.name}</div>
                      <div className="upiSubline">
                        {project.subdomain || ""}.{config.frontDomain}
                      </div>
                    </div>
                  </div>
                  <div className="upiBody">
                    <div className="upiPublishRow">
                      <div className="upiPublishItem">
                        {["web"].includes(project.data?.platform) ? (
                          <div className="upiPublishItemDecor web">
                            <img
                              className="upiPublishItemIco"
                              src={require("../../Assets/img/user/web.png")}
                            ></img>
                          </div>
                        ) : (
                          <>
                            <div className="upiPublishItemDecor ios">
                              <img
                                className="upiPublishItemIco"
                                src={require("../../Assets/img/user/ios.png")}
                              ></img>
                            </div>
                            <div className="upiPublishItemDecor android">
                              <img
                                className="upiPublishItemIco"
                                src={require("../../Assets/img/user/android.png")}
                              ></img>
                            </div>
                          </>
                        )}
                        <div className="upiPublishItemLabel">
                          {project.data?.published
                            ? "Published"
                            : "In Development"}
                        </div>
                      </div>
                    </div>
                    <div className="upiDescription">{project.description}</div>
                  </div>
                  <div className="upiFooter">
                    <div className="upiFooterLeft">
                      <Link
                        key={project._id}
                        to={`/canvas/${project.components?.[0]?._id}`}
                      >
                        <div className="openApp">Open</div>
                      </Link>
                      <AvatarStack
                        users={project.permissions?.map((x) => {
                          const userId = x.user;
                          return (
                            allUsers.find((x) => x.user._id === userId)?.user ||
                            {}
                          );
                        })}
                      />
                    </div>
                    <div
                      key={project._id}
                      onClick={() =>
                        navigationModule.addQuery(
                          { project_detail: project._id },
                          props.router.navigate
                        )
                      }
                    >
                      <div className="viewApp">Details</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <AddProjectPopup
          key={"project" + (visibleModal === "addProject")}
          visible={visibleModal === "addProject"}
          close={() => setVisibleModal(null)}
          callback={(err, project) => {
            if (!err && project) {
              setAllProjects(update(allProjects, { $push: [project] }));
            }
          }}
          team={props.team}
          allUsers={allUsers}
        />

        {projectDetailsId ? (
          <ProjectDetailPopup
            {...{
              visible: true,
              projectId: projectDetailsId,
              project: allProjects?.find((x) => x._id === projectDetailsId),
              // || {
              //   _id: projectDetailsId,
              // },
              onDelete: () => {
                props.router.navigate("/projects");
                fetchProjects();
              },
              close: () => {
                props.router.navigate("/projects");
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  projects: state.vState[SCREEN_NAME].projects,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsScreen));
