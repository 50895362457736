import React from "react";
import _ from "lodash";

import withRouter from "../../../Components/Wrapper/with-router";
import CustomSelect from "../../../Components/etc/CustomSelect";
import _dom from "../../../appxolo-engine/modules/dom/Dom";

const { DomTree, DomTreeNode } = _dom;

const PassedParamSelector = (props) => {
  const {
    value = {},
    onChange,
    styles = {}, // type: {container:object, textBox:object, menu:object}
    excludeParameters = [],
    passedParameters = [],
    // screenParams, // TODO
  } = props;

  const sourceType = value?.valueObj?.sourceType;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const optionGroups = getOptionGroups(props);

  const findOption = (value) => {
    let result = null;
    for (let i = 0; i < optionGroups.length && !result; i++) {
      const item = optionGroups[i];

      if (item.type === "group" && item.options) {
        for (let i = 0; i < item.options.length; i++) {
          const option = item.options[i];
          if (value === option.optionValue) result = option;
        }
      } else {
        if (value === item.optionValue) {
          result = item;
        }
      }
    }

    return result;
  };

  return (
    <div style={Object.assign({}, styleObj.container, styles.container)}>
      <select
        style={Object.assign({}, styleObj.textBox, styles.textBox)}
        type="text-box"
        value={value?.valueObj?.optionValue || ""}
        onChange={(event) => {
          const value = event.target.value;
          const option = findOption(value);
          mergeChange({ valueObj: option });
        }}
      >
        <option
          key="null"
          value=""
          style={{ ...styleObj.option, color: "#ddd" }}
        >
          -- Select --
        </option>
        {optionGroups.map((group) =>
          group.type === "option" ? (
            <option key={group.optionValue} value={group.optionValue}>
              {group.optionLabel}
            </option>
          ) : (
            <optgroup key={group.groupLabel} label={group.groupLabel}>
              {group.options?.map((item) => (
                <option key={item.optionValue} value={item.optionValue}>
                  {item.optionLabel}
                </option>
              ))}
            </optgroup>
          )
        )}
      </select>

      {sourceType === "urlParam" ? (
        <input
          className="underline-input"
          placeholder="Enter URL Param Name"
          value={value.urlParamName || ""}
          onChange={(event) =>
            mergeChange({ urlParamName: event.target.value })
          }
        />
      ) : null}
    </div>
  );
};

export const PassedParameterFromParentSelector = (props) => {
  const { value = {}, onChange, excludeParameters = [] } = props;

  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const optionGroups = getOptionGroups({
    ...props,
    excludeParameters: [
      ...(excludeParameters || []),
      "deviceId",
      "notificationToken",
      "microphoneStatus",
      "cameraStatus",
    ],
  });

  return (
    <>
      {optionGroups.map((optionGroup) => {
        const isActive =
          props.valueType === "passedParameter" &&
          (optionGroup.type === "group"
            ? optionGroup.groupId === value?.valueObj?.optionValue ||
              optionGroup.options?.find(
                (x) => x.optionValue === value?.valueObj?.optionValue
              )
            : optionGroup.type === "option"
            ? optionGroup.optionValue === value?.valueObj?.optionValue
            : false);

        return (
          <div
            className="calcItemUnit"
            key={optionGroup.groupLabel || optionGroup.optionValue}
            // onClick={() => {
            //   if (!isActive)
            //     mergeChange({ valueObj: optionGroup.options?.[0] });
            // }}
          >
            <div className="calcItemUnitBox">
              <div
                className={
                  isActive ? "calcItemUnitDecor" : "calcItemUnitDecorUnselected"
                }
              ></div>
              <div className="calcItemUnitMain">
                <div>
                  <div className="calcItemUnitMainUnselectedLabel">
                    {optionGroup.groupLabel || optionGroup.optionLabel}
                  </div>

                  {optionGroup.type === "option" ? (
                    optionGroup.optionValue === "urlParam" ? (
                      <div>
                        <CustomSelect
                          onChange={(option) =>
                            mergeChange({
                              valueObj: {
                                ...optionGroup,
                                optionValue: optionGroup.optionValue,
                                urlParamName: option.value,
                              },
                            })
                          }
                          value={value?.valueObj?.urlParamName || ""}
                          placeholder={
                            value?.valueObj?.urlParamName || "Select URL Param"
                          }
                          options={getUrlParmaNameOptions(props)?.map((x) => ({
                            value: x,
                            label: x,
                          }))}
                          classNames={{
                            head: "optionInputIconBox",
                            label: "optionInputIconBoxField",
                            chevron: "optionDatabaseSelectChevron",
                          }}
                          renderOptionListHeader={() => (
                            <div>
                              <input
                                placeholder="URL Param Name"
                                value={value?.valueObj?.urlParamName || ""}
                                onChange={(e) =>
                                  mergeChange({
                                    valueObj: {
                                      ...optionGroup,
                                      optionValue: optionGroup.optionValue,
                                      urlParamName: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                          )}
                        />
                      </div>
                    ) : null
                  ) : optionGroup?.sourceType === "repeatingContainer" &&
                    optionGroup?.dbData?.dbId === "externalApi" ? (
                    <div>
                      <input
                        placeholder="Path (Ex: data.Email)"
                        value={value?.valueObj?.dataPath || ""}
                        onChange={(e) =>
                          mergeChange({
                            valueObj: {
                              ...optionGroup,
                              optionValue: optionGroup.groupId,
                              dataPath: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  ) : (
                    <CustomSelect
                      className=""
                      onChange={(option) => mergeChange({ valueObj: option })}
                      value={value?.valueObj?.optionValue || ""}
                      options={optionGroup.options?.map((x) => ({
                        ...x,
                        label: x.optionLabel,
                        value: x.optionValue,
                      }))}
                      placeholder={"Select"}
                      classNames={{
                        head: "calcDropdown",
                        label: "calcDropdownLabel",
                      }}
                      jointActionRow={
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const getOptionGroups = (props) => {
  const { excludeParameters = [], passedParameters = [] } = props;

  return [
    ...[
      "deviceId",
      "notificationToken",
      "urlParam",
      "microphoneStatus",
      "cameraStatus",
    ]
      .filter((x) => !excludeParameters.includes(x))
      .map((x) => ({
        type: "option",
        sourceType: x,
        optionValue: x,
        optionLabel: _.startCase(x),
      })),

    ...(excludeParameters.includes("containerTabs")
      ? []
      : passedParameters
          ?.filter((x) => x.sourceType === "containerTabs")
          .map((group) => ({
            type: "group",
            groupLabel: `ContainerTabs: #${group.elementId} cond${group.conditionIndex}`,
            options: [
              {
                ...group,
                tabStatus: "active",
                optionValue: `ContainerTabs-${group.elementId}-${group.conditionIndex}-active`,
                optionLabel: "Is Active Tab",
              },
              {
                ...group,
                tabStatus: "inactive",
                optionValue: `ContainerTabs-${group.elementId}-${group.conditionIndex}-inactive`,
                optionLabel: "Is Inactive Tab",
              },
            ],
          }))),

    ...(excludeParameters.includes("repeatingContainer")
      ? []
      : passedParameters
          ?.filter((x) => x.sourceType === "repeatingContainer")
          .map((group) => ({
            ...group,
            type: "group",
            groupLabel: `Container: #${group.elementId} cond${group.conditionIndex} ${group.dbData?.table?.name}`,
            groupId: `repeatingContainer-${group.elementId}-${group.conditionIndex}-${group.dbData?.tableId}`,
            options: [...(group.dbData?.table?.columns || [])]?.map(
              (column) => ({
                ...group,
                column: column.name,
                optionValue: `repeatingContainer-${group.elementId}-${group.conditionIndex}-${group.dbData?.tableId}-${column.name}`,
                optionLabel: `${group.dbData?.table?.name}.${column.name}`,
              })
            ),
          }))),

    ...(excludeParameters.includes("dataGroup")
      ? []
      : passedParameters
          ?.filter((x) => x.sourceType === "dataGroup")
          .map((group) => ({
            type: "group",
            groupLabel: `${group.label}`,
            options: group.options,
          }))),

    ...(excludeParameters.includes("repeatingMapMarker")
      ? []
      : passedParameters
          ?.filter((x) => x.sourceType === "repeatingMapMarker")
          .map((group) => ({
            type: "group",
            groupLabel: `Map`,
            options: group.dbData?.table?.columns?.map((column) => ({
              ...group,
              column: column.name,
              optionValue: `repeatingMapMarker-${column.name}`,
              optionLabel: `${column.name}`,
            })),
          }))),
  ];
};

let urlParamNameCache = {};
const getUrlParmaNameOptions = (props) => {
  if (urlParamNameCache[props.project?._id])
    return urlParamNameCache[props.project?._id];
  else {
    urlParamNameCache[props.project?._id] = [];
    retriveParamNameCache(props.components).then(
      (x) => (urlParamNameCache[props.project?._id] = x)
    );
    return [];
  }
};

const retriveParamNameCache = async (components) => {
  let allParams = [];

  const processComponent = async (component) => {
    const domTreeNode = new DomTreeNode(
      "ROOT",
      { elementType: "container" },
      component?.data?.dom?.children
    );

    const domTree = new DomTree(domTreeNode, () => {});

    let params = [];

    const fn = (x) => {
      const tabs = x?.value?.data?.tabs;
      const elementType = x?.value?.elementType;

      for (let i = 0; i < tabs?.length; i++) {
        const tab = tabs?.[i];

        const linkings = tab?.[elementType + "Data"]?.linkings;

        for (let i = 0; i < linkings?.length; i++) {
          const linkingTabs = linkings[i]?.tabs;

          for (let i = 0; i < linkingTabs?.length; i++) {
            const linkingData = linkingTabs[i]?.linkingData;

            if (linkingData?.valueType === "linkToScreen") {
              let urlParams =
                linkingData?.valueObj?.linkToScreen?.urlParameters;

              for (let i = 0; i < urlParams?.length; i++) {
                const urlParam = urlParams[i];

                if (urlParam.parameterName?.valueType === "customText") {
                  const paramName =
                    urlParam.parameterName?.valueObj?.customText;

                  if (paramName) params.push(paramName);
                }
              }
            }
          }
        }
      }
    };

    domTree.loopOverAllNodes(fn);

    return params;
  };

  const promises = [];

  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const promise = processComponent(component).then(
      (params) => (allParams = [...allParams, ...params])
    );
    promises.push(promise);
  }

  await Promise.all(promises);

  return allParams.filter((x, i, arr) => i === arr.indexOf(x));
};

const styleObj = {
  container: {
    alignItems: "center",
    textAlign: "left",
    marginTop: "4px",
    widht: "90%",
  },
  textBox: {
    color: "#bbb",
    border: "0",
    fontSize: "small",
    // width: "162px",
    background: 0,
    margin: "0 0 7px",
  },
  menu: {
    color: "#333",
  },
};

export default withRouter(PassedParamSelector);
