import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import api from "../../../Services/Api/api";
import ProjectDetailsBase from "./ProjectDetailsBase";
import { Form } from "react-bootstrap";
import { RtcLogger } from "../../Common/RtcLogger";
import { OnlineDeviceLogger } from "../../Common/OnlineDeviceLogger";

class ProjectIntegrations extends ProjectDetailsBase {
  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const { credentials } = await api.get("v1/project/credential", {
        filter: JSON.stringify({
          where: { project: this.props.project?._id },
        }),
      });

      const { paymentMethods } = await api.get("v1/payment/payment-methods", {
        filter: JSON.stringify({
          where: { project: this.props.project?._id },
        }),
      });

      this.setState({
        loading: false,
        communication: credentials,
        paymentConfiguration: paymentMethods,
      });
    } catch (e) {
      toast.error(e.mssage);
      this.setState({ loading: false });
    }
  }

  integrations = [
    {
      value: "communication",
      options: [
        {
          value: "sendgridEmail",
          label: "Emails",
          altLabel: "Sendgrid",
          options: [
            { value: "apiKey", label: "Sendgrid API Key" },
            { value: "from", label: "From Email" },
          ],
        },
        {
          value: "firebaseAdmin",
          label: "Push Notifications",
          altLabel: "Firebase",
          options: [
            { value: "project_id", label: "Firebase Project ID" },
            { value: "private_key_id", label: "Firebase Private Key ID" },
            { value: "private_key", label: "Firebase Private Key" },
            { value: "client_email", label: "Firebase Client Email" },
            { value: "client_id", label: "Firebase Client ID" },
          ],
        },
        {
          value: "twilioSMS",
          label: "Text Messaging",
          altLabel: "Twilio",
          options: [
            { value: "accountSid", label: "Twilio Account ID" },
            { value: "authToken", label: "Twilio Auth Token" },
            { value: "from", label: "Twilio From" },
          ],
        },
      ],
      path: "credential",
    },
    {
      value: "paymentConfiguration",
      options: [
        {
          value: "stripe",
          label: "Stripe",
          altLabel: "Stripe",
          options: [
            { value: "publicKey", label: "Stripe Public Key" },
            { value: "secretKey", label: "Stripe Secret Key" },
          ],
        },
        {
          value: "paypal",
          label: "PayPal",
          altLabel: "PayPal",
          options: [
            { value: "publicKey", label: "Client ID" },
            { value: "secretKey", label: "Client Secret" },
            { value: "mode", label: "Mode", options: ["sandbox", "live"] },
          ],
        },
      ],
    },
  ];

  async submitIntegration(data, { item, integration }) {
    try {
      if (integration.value === "communication") {
        const payload = {
          project: this.props.project._id,
          credentialType: item.value,
          credential: data,
        };

        await api.post("v1/project/credential", payload);
      } else if (integration.value === "paymentConfiguration") {
        const payload = {
          project: this.props.project._id,
          provider: item.value,
          _id: data._id,
          ..._.pick(
            data,
            item.options?.map((x) => x.value)
          ),
        };

        await api.post("v1/payment/payment-methods", payload);
      }

      this.load();
    } catch (e) {
      throw e;
    }
  }

  render() {
    return (
      <>
        <div className="pageTitle">
          <div>Project Integrations</div>
          <span>View and manage your integrations</span>
        </div>

        <div className="contentBody">
          {this.integrations.map((integration, i) => {
            return (
              <Fragment key={integration.value}>
                <div className={"tLabel mb10" + (i ? " pt50" : "")}>
                  {_.startCase(integration.value)}
                </div>
                <div className="borderBox mb20">
                  {integration.options?.map((item, index) => (
                    <IntegrationItem
                      {...{
                        key: item.value,
                        item,
                        index,
                        integration,
                        value: this.state[integration.value]?.find(
                          (x) =>
                            x.credentialType === item.value ||
                            x.provider === item.value
                        ),
                        submit: (data) =>
                          this.submitIntegration(data, { item, integration }),
                      }}
                    />
                  ))}
                </div>
              </Fragment>
            );
          })}

          <Fragment>
            <div className={"tLabel mb10 pt50"}>Database Logger</div>
            <div className="borderBox mb20">
              <RtcLogger
                {...this.props}
                onSuccess={() => this.props.reload()}
              />
              <OnlineDeviceLogger
                {...this.props}
                onSuccess={() => this.props.reload()}
              />
            </div>
          </Fragment>
        </div>
      </>
    );
  }
}

const isEmpty = (obj) => {
  if (!obj) return true;

  // Check if the object is a plain object and has no keys
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }

  // Check if all keys have empty values
  for (let key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== "" &&
      obj[key] !== false
    ) {
      // If a non-empty value is found, return false
      return false;
    }
  }

  // If no non-empty values were found, return true
  return true;
};

const IntegrationItem = (props) => {
  const { item, value, submit, integration } = props;

  const propsData = integration.path ? value?.[integration.path] : value;

  const [visibleDetails, setVisibleDetails] = useState(false);
  const [data, setData] = useState(propsData || {});
  const [loading, setLoading] = useState(false);

  const isSet = !isEmpty(value);

  useEffect(() => {
    setData(propsData || {});
  }, [value]);

  const handleSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await submit(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
    }
  };

  return (
    <div className="borderItem">
      <div className="mLabel">{item.label}</div>
      <div className="publishRow">
        <div className="mValue">
          {item.altLabel}
          {isSet ? " is configured" : " not setup"}
        </div>
        <div
          className="defaultButton mtm15"
          onClick={() => {
            setData(propsData || {});
            setVisibleDetails(!visibleDetails);
          }}
        >
          {isSet ? "View" : "Link"}
        </div>
      </div>

      <div
        className="publishDetails"
        style={{ display: visibleDetails ? "flex" : "none" }}
      >
        {item.options?.map((option) => (
          <div key={option.value} className="pbRow">
            <div className="pbItem">
              <div className="pbLabel">{option.label}</div>
              {option.options ? (
                <Form.Control
                  value={data[option.value] || ""}
                  onChange={(e) =>
                    setData({ ...data, [option.value]: e.target.value })
                  }
                  as="select"
                  required
                >
                  <option value="" disabled>
                    Select Mode
                  </option>
                  <option value="sandbox">Sandbox</option>
                  <option value="live">Live</option>
                </Form.Control>
              ) : (
                <input
                  type="text"
                  className="mInput"
                  placeholder=""
                  value={data[option.value] || ""}
                  onChange={(e) =>
                    setData({ ...data, [option.value]: e.target.value })
                  }
                />
              )}
            </div>
          </div>
        ))}

        <div className="minActionRow">
          <div className="minButton" onClick={handleSubmit}>
            {loading ? "Processing" : "Submit"}
          </div>
          <div
            className="minLightButton"
            onClick={() => {
              setData(propsData || {});
              setVisibleDetails(!visibleDetails);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectIntegrations;
