import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import MapData from "./MapData";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import Spacing from "../../Common/StyleProperties/Spacing";

class MapProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const styleProps = {
      ...this.props,
      value: this.props.styles,
      onChange: this.props.setStyles,
    };

    return (
      <>
        <Dimensions {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />

        <MapData
          {...this.props}
          value={value.mapData}
          onChange={(x) => mergeChange({ mapData: x })}
        />
      </>
    );
  }
}

export default MapProperties;
