import React, { useState } from "react";
import { DropTarget } from "react-dnd";

import DraggableCanvasElement from "./DraggableCanvasElement";
import styleModule from "../../Modules/style/style-module";

const Canvas = (props) => {
  const [parentData, setParentData] = useState({});
  const { connectDropTarget, dom, builderData, setBuilderData } = props;
  const elements = dom.tree.children || [];

  return connectDropTarget(
    <div
      className="canvas-container"
      style={{
        background: props.hover ? "#dedef2" : "",
        height: "100%",
        padding: "10px",
        position: "relative",
        overflow: "auto",
      }}
    >
      {elements.map((element, index) => (
        <DraggableCanvasElement
          key={element.id}
          {...{
            ...props,
            element,
            indices: [index],
            builderData,
            setBuilderData,
            parentData,
            setParentData,
            dom,
            project: props.project,
            style: styleModule.getElementStyleData(element, 'builderWrapper'),
          }}
        />
      ))}
    </div>
  );
};

export default DropTarget(
  "item",
  {
    drop(props, monitor) {
      const item = monitor.getItem();
      if (monitor.didDrop()) return;

      const dom = props.dom;
      if (item.set !== "canvasElement")
        dom.addNodeValue("ROOT", item, dom.tree.children.length);
      else dom.moveNode(item.id, "ROOT", "inside");
    },
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      hover: monitor.isOver({ shallow: true }),
      item: monitor.getItem(),
    };
  }
)(Canvas);
