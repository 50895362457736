import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import "../../Styles/landing-pages.css";
import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import Header from "./Header";
import Footer from "./Footer";

const PricingScreen = ({ isLoggedIn }) => {
  if (isLoggedIn) return <Navigate to="/dashboard" />;

  return (
    <>
      <Header />
      <div className="section headerSection">
        <div className="container">
          <div className="wrapper padding">
            <div className="rowItem">
              <div className="pricingBox">
                <div className="mfiTitle lexend">Simple Pricing Plans</div>
                <div className="multiPack tCenter">
                  Flexible plans and solutions for everyone
                </div>
                <div className="pricingAction">
                  <div className="paItem active">Billed Monthly</div>
                  <div className="paItem">Billed Yearly</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="wrapper padding gap20">
            <div className="rowItem">
              <div className="pricingContainer">
                <div className="pcHead">
                  <div className="pcPlan">Builder</div>
                  <div className="pcCost">
                    $49<span>/month</span>
                  </div>
                  <div className="pcDesc">For the average Joe</div>
                </div>
                <div className="pcFooter">
                  <Link to="/signup?plan=builder" className="pcAction">Sign Up</Link>
                </div>
                <div className="pcBody">
                  <div className="pcItem">Apps</div>
                  <div className="pcItem">Databases</div>
                  <div className="pcItem">Users</div>
                  <div className="pcItem">Data Transfer</div>
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="pricingContainer">
                <div className="pcHead">
                  <div className="pcPlan">Agency</div>
                  <div className="pcCost">
                    $199<span>/month</span>
                  </div>
                  <div className="pcDesc">Companies with dedicated teams</div>
                </div>
                <div className="pcFooter">
                  <Link to="/signup?plan=agency" className="pcAction">Sign Up</Link>
                </div>
                <div className="pcBody">
                  <div className="pcItem">Apps</div>
                  <div className="pcItem">Databases</div>
                  <div className="pcItem">Users</div>
                  <div className="pcItem">Data Transfer</div>
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="pricingContainer">
                <div className="pcHead">
                  <div className="pcPlan">Enterprise</div>
                  <div className="pcCost">
                    $1999<span>/application</span>
                  </div>
                  <div className="pcDesc">
                    Whitelabel solution for companies
                  </div>
                </div>
                <div className="pcFooter">
                  <div className="pcAction">Sign Up</div>
                </div>
                <div className="pcBody">
                  <div className="pcItem">Apps</div>
                  <div className="pcItem">Databases</div>
                  <div className="pcItem">Users</div>
                  <div className="pcItem">Data Transfer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="wrapper padding gap20 pad100">
            <div className="rowItem">
              <FaqBox />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const FaqBox = () => {
  return (
    <div className="faqBox">
      <div className="faqTitle lexend">Frequently asked questions</div>
      {[
        {
          question: <>How does the AppWolo pricing syntax work?</>,
          answer: (
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              auctor, nisi id fermentum interdum, ipsum justo sodales magna,
              eget tempor orci ligula nec nulla. Proin in arcu et eros venenatis
              aliquam. Sed at dui sem. Vestibulum id erat vitae lorem vulputate
              tincidunt. Morbi ultricies metus id nunc sagittis, non condimentum
              nisi vulputate. Aliquam erat volutpat. Duis posuere tempor magna,
              nec malesuada risus porttitor ac. Integer eget magna vehicula,
              vestibulum leo a, suscipit ligula. Etiam in felis erat. Maecenas
              venenatis velit et nibh bibendum sagittis.
            </>
          ),
        },
        {
          question: <>How does the AppWolo pricing syntax work?</>,
          answer: (
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              auctor, nisi id fermentum interdum, ipsum justo sodales magna,
              eget tempor orci ligula nec nulla. Proin in arcu et eros venenatis
              aliquam. Sed at dui sem. Vestibulum id erat vitae lorem vulputate
              tincidunt. Morbi ultricies metus id nunc sagittis, non condimentum
              nisi vulputate. Aliquam erat volutpat. Duis posuere tempor magna,
              nec malesuada risus porttitor ac. Integer eget magna vehicula,
              vestibulum leo a, suscipit ligula. Etiam in felis erat. Maecenas
              venenatis velit et nibh bibendum sagittis.
            </>
          ),
        },
        {
          question: <>How does the AppWolo pricing syntax work?</>,
          answer: (
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              auctor, nisi id fermentum interdum, ipsum justo sodales magna,
              eget tempor orci ligula nec nulla. Proin in arcu et eros venenatis
              aliquam. Sed at dui sem. Vestibulum id erat vitae lorem vulputate
              tincidunt. Morbi ultricies metus id nunc sagittis, non condimentum
              nisi vulputate. Aliquam erat volutpat. Duis posuere tempor magna,
              nec malesuada risus porttitor ac. Integer eget magna vehicula,
              vestibulum leo a, suscipit ligula. Etiam in felis erat. Maecenas
              venenatis velit et nibh bibendum sagittis.
            </>
          ),
        },
        {
          question: <>How does the AppWolo pricing syntax work?</>,
          answer: (
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              auctor, nisi id fermentum interdum, ipsum justo sodales magna,
              eget tempor orci ligula nec nulla. Proin in arcu et eros venenatis
              aliquam. Sed at dui sem. Vestibulum id erat vitae lorem vulputate
              tincidunt. Morbi ultricies metus id nunc sagittis, non condimentum
              nisi vulputate. Aliquam erat volutpat. Duis posuere tempor magna,
              nec malesuada risus porttitor ac. Integer eget magna vehicula,
              vestibulum leo a, suscipit ligula. Etiam in felis erat. Maecenas
              venenatis velit et nibh bibendum sagittis.
            </>
          ),
        },
        {
          question: <>How does the AppWolo pricing syntax work?</>,
          answer: (
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              auctor, nisi id fermentum interdum, ipsum justo sodales magna,
              eget tempor orci ligula nec nulla. Proin in arcu et eros venenatis
              aliquam. Sed at dui sem. Vestibulum id erat vitae lorem vulputate
              tincidunt. Morbi ultricies metus id nunc sagittis, non condimentum
              nisi vulputate. Aliquam erat volutpat. Duis posuere tempor magna,
              nec malesuada risus porttitor ac. Integer eget magna vehicula,
              vestibulum leo a, suscipit ligula. Etiam in felis erat. Maecenas
              venenatis velit et nibh bibendum sagittis.
            </>
          ),
        },
      ].map((item, i) => (
        <FaqItem {...item} key={item._id || i} />
      ))}
    </div>
  );
};

const FaqItem = ({ question, answer }) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={"faqItem" + (active ? " active" : "")}
      onClick={() => setActive(!active)}
    >
      {question}
      <div className="faqAnswer">{answer}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PricingScreen);
