import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Config from "../Config";
import MainScreenNavigator from "./MainScreenNavigators";
import LoginScreen, { SignupScreen } from "../Views/Auth/LoginScreen";
import HomeScreen from "../Views/Home/HomeScreen";
import PricingScreen from "../Views/Home/PricingScreen";

function AppNavigator() {
  return (
    <Router basename={Config.relativePath}>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/pricing" element={<PricingScreen />} />
        <Route path="/login" exact element={<LoginScreen />} />
        <Route path="/signup" exact element={<SignupScreen />} />
        <Route path="*" element={<MainScreenNavigator />} />
      </Routes>
    </Router>
  );
}

export default AppNavigator;
