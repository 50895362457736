/* eslint-disable no-unused-expressions */
import React from "react";
import _ from "lodash";

import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";

class AddRecord extends React.Component {
  initialState = {
    loading: false,
    visible: null,
    error: null,
    record: {},
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true, error: null });

      const document = {};
      const item = this.state.record;

      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const value = item[key];
          if (key !== "_id") document[key] = { value };
        }
      }

      let payload = {
        dbId: this.props.database._id,
        tableId: this.props.tableId,
        document,
        valueType: "addRecord",
      };

      const res = await api.post("v1/database/write", payload);

      this.setState({ loading: false, visible: false });
      this.props.onSuccess(res);
      this.props.close();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  render() {
    const {
      state: { record, error, loading },
      props: { columns },
    } = this;

    return (
      <div>
        {columns
          .filter((x) => x !== "_id")
          .map((col) => (
            <div key={col}>
              <div>{col}</div>
              <input
                value={record[col] || ""}
                onChange={(e) =>
                  this.setState({
                    record: { ...this.state.record, [col]: e.target.value },
                  })
                }
              />
            </div>
          ))}

        <div onClick={this.onSubmit.bind(this)}>Submit</div>
      </div>
    );
  }
}

export class AddRecordModal extends React.Component {
  render() {
    return (
      <AppModal
        {...{
          title: "Add Record",
          visible: this.props.visible,
          handleClose: () => this.props.close(),
          showSubmitBtn: false,
        }}
      >
        {this.props.visible ? <AddRecord {...this.props} /> : null}
      </AppModal>
    );
  }
}

export class AddRecordBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <div onClick={() => this.setState({ visible: true })}>
          {this.props.children}
        </div>
        <AddRecordModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible:
              this.state.visible &&
              this.props.columns?.map &&
              this.props.tableId &&
              this.props.database?._id,
          }}
        />
      </>
    );
  }
}
