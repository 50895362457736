import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import { AppearanceForInput } from "./Appearance";
const InputAppearance = (props) => {
  const { value = {}, onChange, inputDataProps, inputStyleProps } = props;

  const styles = inputStyleProps.value || {};
  const mergeStyles = (obj) => inputStyleProps.onChange({ ...styles, ...obj });

  const data = inputDataProps.value || {};
  const mergeData = (obj) => inputDataProps.onChange({ ...data, ...obj });

  const selectedGroup = value.inputType?.group || "textInput";

  return (
    <>
      <OptionItem optionItemLabel={"Appearance"}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div className="oibLabel">Width</div>
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    value={styles.width || ""}
                    onChange={(event) =>
                      mergeStyles({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div>
                <div className="oibLabel">Height</div>
                <div>
                  <div className="optionInputIconBox">
                    <img
                      className="optionInputIconBoxIco rotate90"
                      src={require("../../../../../Assets/img/options/container/widthIco.png")}
                      alt="height"
                    ></img>
                    <ControlledInput
                      type="text"
                      onChange={(event) =>
                        mergeStyles({ height: event.target.value })
                      }
                      className="optionInputIconBoxField"
                      placeholder="Auto"
                      value={styles.height || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppearanceForInput {...inputStyleProps} />
        </div>
      </OptionItem>
    </>
  );
};

export const InputTypeSelector = (props) => {
  const { value = {}, onChange, inputDataProps, inputStyleProps } = props;

  const styles = inputStyleProps.value || {};
  const mergeStyles = (obj) => inputStyleProps.onChange({ ...styles, ...obj });

  const data = inputDataProps.value || {};
  const mergeData = (obj) => inputDataProps.onChange({ ...data, ...obj });

  const selectedGroup = value.inputType?.group || "textInput";

  return (
    <>
      <div className="optionItemRow">
        <div className="optionItemBox">
          <div>
            <div className="oibLabel">Type</div>
            <div>
              <CustomSelect
                onChange={(option) =>
                  onChange({
                    inputType: {
                      ...(value.inputType || {}),
                      group: option.value,
                      inputType: "default",
                    },
                  })
                }
                value={selectedGroup}
                options={[
                  { value: "textInput", label: "Text" },
                  { value: "textarea", label: "Long Text" },
                  { value: "number", label: "Number" },
                  { value: "password", label: "Password" },
                  { value: "locationInput", label: "Location" },
                  { value: "selectBox", label: "Select Box" },
                  { value: "checkBox", label: "Check Box" },
                  { value: "radio", label: "Radio" },
                  { value: "switch", label: "Switch" },
                  { value: "imagePicker", label: "Media Picker" },
                  { value: "filePicker", label: "File Picker" },
                  { value: "dateTimePicker", label: "Date Time" },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {selectedGroup === "imagePicker" ? (
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="oibLabel">Multi Select</div>
            <div>
              <CustomSelect
                value={data.multiple || false}
                onChange={(option) =>
                  mergeData({
                    multiple: option.value,
                  })
                }
                options={[
                  { value: false, label: "Disable" },
                  { value: true, label: "Enable" },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>

          <div className="optionItemBox">
            <div className="oibLabel">Media Type</div>
            <div>
              <CustomSelect
                value={data.acceptFileType || "photo"}
                onChange={(option) =>
                  mergeData({
                    acceptFileType: option.value,
                  })
                }
                options={[
                  { value: "photo", label: "Photo" },
                  { value: "video", label: "Video" },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>
        </div>
      ) : selectedGroup === "dateTimePicker" ? (
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="oibLabel">Mode</div>
            <div>
              <CustomSelect
                value={data.datetimePicker?.datepickerMode || "datepicker"}
                onChange={(option) =>
                  mergeData({
                    datetimePicker: {
                      ...(data.datetimePicker || {}),
                      datepickerMode: option.value,
                    },
                  })
                }
                options={[
                  { label: "Date", value: "datepicker" },
                  { label: "Time", value: "timepicker" },
                  { label: "DateTime", value: "datetimepicker" },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>

          {(data.datetimePicker?.datepickerMode || "datepicker") ===
          "datepicker" ? (
            <div className="optionItemBox">
              <div className="oibLabel">Style</div>
              <div>
                <CustomSelect
                  value={data.datetimePicker?.datepickerType || "select"}
                  onChange={(option) =>
                    mergeData({
                      datetimePicker: {
                        ...(data.datetimePicker || {}),
                        datepickerType: option.value,
                      },
                    })
                  }
                  options={[
                    { label: "Bar", value: "bar" },
                    { label: "Open", value: "open" },
                    { label: "Infinite", value: "infinite" },
                    { label: "Dropdown", value: "select" },
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default InputAppearance;
