import React, { useState } from "react";

import ColorButton from "../../../../../Components/Input/ColorButton";
import StaticImageSources from "../ImageSources/StaticImageSources";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";

const Background = (props) => {
  const [hover, setHover] = useState(false);

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  let backgroundColor = styles.backgroundColor;

  const changeColor = (hex) => {
    backgroundColor = hex;
    let updateObj = {};
    updateObj.backgroundColor = hex ? hex : "";
    // console.log({ updateObj, classId });
    mergeStyle(updateObj);
  };

  return (
    <OptionItem
      optionItemLabel="Background"
      key={hover}
    >
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                style={{ width: "100%", height: "100%" }}
                value={backgroundColor || "#FFFFFF"}
                onChange={(val) => changeColor(val)}
              />
              <div className="optionInputIconBoxText">Background Color</div>
            </div>
          </div>
        </div>
        {props.backgroundImage ? (
          <>
            <div className="optionDivider"></div>

            <div className="optionItemRow">
              <div className="optionItemBox">
                <StaticImageSources
                  maxLength={1}
                  value={styles.backgroundImage
                    ?.replace("url(", "")
                    .replace(")", "")}
                  onChange={(value) =>
                    mergeStyle({
                      backgroundImage: value && `url(${value})`,
                      backgroundSize: styles.backgroundSize || "contain",
                    })
                  }
                  label={
                    <div className="optionColorItem">
                      <div className="optionColorCircle bgImageIco">
                        <div className="bgImageLine one"></div>
                        <div className="bgImageLine two"></div>
                      </div>
                      <div className="optionInputIconBoxText">
                        Background Image
                      </div>
                    </div>
                  }
                />
              </div>
            </div>

            <div className="optionItemRow">
              <div className="optionItemBox">
                <CustomSelect
                  onChange={(option) =>
                    mergeStyle({ backgroundRepeat: option.value })
                  }
                  value={styles.backgroundRepeat || ""}
                  options={[
                    { value: "no-repeat", label: "No Repeat" },
                    { value: "repeat", label: "Repeat" },
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>

              <div className="optionItemBox">
                <CustomSelect
                  onChange={(option) =>
                    mergeStyle({ backgroundSize: option.value })
                  }
                  value={styles.backgroundSize || ""}
                  options={[
                    { value: "cover", label: "Cover" },
                    { value: "contain", label: "Contain" },
                    { value: "100% 100%", label: "Stretch" },
                    { value: "center", label: "Center" },
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </OptionItem>
  );
};

export default Background;
