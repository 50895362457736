import React, { useEffect, useState } from "react";

import CustomSelect from "../../../../../Components/etc/CustomSelect";

const StyleMediaQuerySelector = ({
  value: styleData = {},
  onChange: setStyleData,
}) => {
  const value = styleData?.conditionSelector || {};
  const onChange = (conditionSelector) =>
    setStyleData({ ...(styleData || {}), conditionSelector });

  const existingConditions = styleData?.conditions || [];
  const setExistingConditions = (conditions) =>
    setStyleData({ ...(styleData || {}), conditions });

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCreatCustomOpen, setIsCreatCustomOpen] = useState(false);
  const [localCondition, setLocalCondition] = useState({
    heightOrWidth: "screenWidth",
    value: "768",
    operator: "lessThan",
    hover: !!value?.hover,
  });

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const toggleCreateCustom = () => setIsCreatCustomOpen((prev) => !prev);

  useEffect(() => {
    if (!existingConditions.length)
      handleCreateCustoms([
        {
          heightOrWidth: "screenWidth",
          operator: "lessThan",
          value: "768",
          hover: false,
        },
        {
          heightOrWidth: "screenWidth",
          operator: "lessThan",
          value: "768",
          hover: true,
        },
      ]);
  }, [existingConditions]);

  const sortConditions = (conditions) => {
    let sorted = [...(conditions || [])];

    return sorted.sort((a, b) => {
      if (
        a.condition.heightOrWidth === "screenWidth" &&
        b.condition.heightOrWidth === "screenHeight"
      ) {
        return 1;
      }
      if (
        a.condition.heightOrWidth === "screenHeight" &&
        b.condition.heightOrWidth === "screenWidth"
      ) {
        return -1;
      }

      if (
        a.condition.operator === "greaterThan" &&
        b.condition.operator === "lessThan"
      ) {
        return -1;
      }
      if (
        a.condition.operator === "lessThan" &&
        b.condition.operator === "greaterThan"
      ) {
        return 1;
      }

      const aValue = parseInt(a.condition.value, 10);
      const bValue = parseInt(b.condition.value, 10);
      if (aValue !== bValue) {
        return (
          (aValue - bValue) * (a.condition.operator === "lessThan" ? -1 : 1)
        );
      }

      return 0;
    });
  };

  const handleCreateCustoms = (localConditions) => {
    let newConditions = [...existingConditions];

    for (let i = 0; i < localConditions.length; i++) {
      const localCondition = localConditions[i];
      const isExists = newConditions.some(({ condition: cond }) => {
        const keys = Object.keys(localCondition);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (cond[key] !== localCondition[key]) return false;
        }

        return true;
      });

      if (!isExists)
        newConditions = [...newConditions, { condition: localCondition }];
    }

    setExistingConditions(sortConditions(newConditions));
    setIsCreatCustomOpen(false);
  };

  let filteredConditions = existingConditions?.filter(
    ({ condition: x }) => !!x.hover === !!value?.hover
  );

  const getLabel = (value) => {
    if (!value?.heightOrWidth) return "All Screens";

    return `${
      value?.heightOrWidth === "screenHeight"
        ? "H"
        : value?.heightOrWidth === "screenWidth"
        ? "W"
        : ""
    } ${
      value?.operator === "greaterThan"
        ? ">"
        : value?.operator === "lessThan"
        ? "<"
        : ""
    } ${value?.value}`;
  };

  return (
    <div className="mqDropdown">
      <div className="mqDropdownHead" onClick={toggleDropdown}>
        <span>{getLabel(value)}</span>
        <img
          className="optionDatabaseSelectChevron"
          src={require("../../../../../Assets/img/options/container/chevronDown.png")}
        />
      </div>
      {isDropdownOpen && (
        <div className="mqDropdownBody">
          <div
            className="mqDropdownItem"
            onClick={() => {
              onChange({ hover: !!value?.hover });
              setDropdownOpen(false);
            }}
          >
            All Screens
          </div>

          {isCreatCustomOpen ? (
            <div style={{ display: "flex" }}>
              <CustomSelect
                onChange={(option) =>
                  setLocalCondition({
                    ...(localCondition || {}),
                    heightOrWidth: option.value,
                  })
                }
                value={localCondition.heightOrWidth}
                placeholder="Select"
                options={[
                  { value: "screenWidth", label: "Width" },
                  { value: "screenHeight", label: "Height" },
                ]}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
              <CustomSelect
                onChange={(option) =>
                  setLocalCondition({
                    ...(localCondition || {}),
                    operator: option.value,
                  })
                }
                value={localCondition.operator}
                placeholder="Select"
                options={[
                  { value: "lessThan", label: "<" },
                  { value: "greaterThan", label: ">" },
                ]}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
              <input
                type="number"
                value={localCondition.value || ""}
                onChange={(e) =>
                  setLocalCondition({
                    ...(localCondition || {}),
                    value: e.target.value,
                  })
                }
              />
              <div
                onClick={() =>
                  handleCreateCustoms([
                    { ...localCondition, hover: true },
                    { ...localCondition, hover: false },
                  ])
                }
              >
                Create
              </div>
            </div>
          ) : (
            <div onClick={toggleCreateCustom}>Create Custom</div>
          )}

          {filteredConditions?.map(({ condition }, i) => (
            <div
              onClick={() => {
                onChange({ ...condition, hover: !!value.hover });
                setDropdownOpen(false);
              }}
              key={i}
            >
              {getLabel(condition)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StyleMediaQuerySelector;
