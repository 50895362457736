import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { toast } from "react-toastify";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";

export const AddUserPopup = (props) => {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);

  const addUser = async (userId) => {
    if (submiting) return;
    try {
      setSubmiting(true);
      await props.addUser(userId);
      props.setVisible(false);
      setSubmiting(false);
    } catch (error) {
      setSubmiting(false);
      toast.error(error.message);
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleInput = async (email) => {
    setEmail(email);
    clearTimeout(searchTimer);

    if (!email) setUsers(null);

    if (email.length < 3 || !validateEmail(email)) return;

    const timer = setTimeout(() => {
      api
        .get("v1/user/search", { email })
        .then(({ users }) => setUsers(users))
        .catch((e) => console.warn(e.message));
    }, 200);

    setSearchTimer(timer);
  };

  const content = (
    <div>
      <div>
        <div>Add User</div>

        <input
          placeholder="Email Address"
          value={email}
          onChange={(e) => handleInput(e.target.value)}
        />

        {users?.map((user) => (
          <div>
            <div>
              {user.firstName} {user.email}
            </div>
            <div onClick={() => addUser(user._id)}>Add</div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Add User",
        visible: props.visible,
        handleClose: () => props.setVisible(false),
        showFooter: false,
      }}
    >
      {content}
    </AppModal>
  );
};
