import React from "react";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import OptionItem from "../../../../../Components/etc/OptionItem";

const Dimensions = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  return (
    <OptionItem optionItemLabel={"DIMENSIONS"}>
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="oibLabel">Width</div>
            <div>
              <div className="optionInputIconBox">
                <img
                  className="optionInputIconBoxIco"
                  src={require("../../../../../Assets/img/options/container/widthIco.png")}
                  alt="width"
                ></img>
                <ControlledInput
                  type="text"
                  value={styles.width || ""}
                  onChange={(event) =>
                    mergeStyle({ width: event.target.value })
                  }
                  className="optionInputIconBoxField"
                  placeholder="Auto"
                />
              </div>
            </div>
          </div>
          <div className="optionItemBox">
            <div>
              <div className="oibLabel">Height</div>
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco rotate90"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="height"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ height: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                    value={styles.height || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OptionItem>
  );
};

export default Dimensions;
