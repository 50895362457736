import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import api from "../../Services/Api/api";
import config from "../../Config";
import authModule from "../../Modules/auth/auth-module";

const stripePromise = loadStripe(config.stripe.publicKey);

const SubscriptionBanner = (props) => {
  const { team } = props;
  const subscription = team.subscription;

  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    api
      .post("v1/user/subscription/create-setup-intent")
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => {
        setError(err.message);
        toast.error(err.message);
      });
  }, []);

  const statusMap = {
    canceled: "Your subscription is canceled",
    expired: "Your subscription is expired",
    failed: "Payment attempt for subscription renewal was failed",
  };

  return (
    <div>
      <div>{statusMap[subscription.status]}</div>

      {!clientSecret ? (
        error ? (
          <div style={{ color: "red" }}>{error}</div>
        ) : (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="loader large" />
          </div>
        )
      ) : (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: "stripe",
            },
            loader: "auto",
          }}
        >
          <PaymentComponent {...props} />
        </Elements>
      )}

      {/* <div style={{ whiteSpace: "pre" }}>
        {JSON.stringify({ team, subscription }, null, 4)}
      </div> */}
    </div>
  );
};

const PaymentComponent = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (paymentMethodId) => {
    if (loading) return;

    setLoading(true);
    setError(null);

    try {
      if (!paymentMethodId) {
        const { setupIntent, error: stripeError } = await stripe.confirmSetup({
          elements,
          confirmParams: {},
          redirect: "if_required",
        });

        if (stripeError) {
          throw new Error(stripeError.message);
        }

        paymentMethodId = setupIntent.payment_method;
        console.log({ setupIntent });
      }

      const res = await api.post("v1/user/subscription/pay", {
        user: props.team.user._id,
        subscription: props.team.subscription._id,
        paymentMethodId,
      });

      console.log({ res });

      authModule.initAuth({
        teamId: props.team?.user?._id,
      });
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <PaymentElement options={{ layout: "tabs" }} />
      <div style={{ color: "red" }}>{error}</div>
      <div onClick={() => handleSubmit()}>{loading ? "Loading" : "Submit"}</div>

      {props.team.subscription?.paymentMethods?.map((paymentMethod) => (
        <div
          key={paymentMethod.id}
          style={{ margin: "10px", backgroundColor: "#dedede" }}
          onClick={() => handleSubmit(paymentMethod.id)}
        >
          {paymentMethod.type}
          <br />
          Added on {new Date(paymentMethod.created).toLocaleDateString()}
          <br />
          {paymentMethod.type === "card" ? paymentMethod.card.brand : ""}
          <br />
          {paymentMethod.type === "card" ? paymentMethod.card.last4 : ""}
          <br />
          {paymentMethod.type === "card" ? (
            <span>
              Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
            </span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionBanner);
