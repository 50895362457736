import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import CustomSelect from "../../../Components/etc/CustomSelect";

class DatabaseQueryFilter extends React.Component {
  render() {
    const database = this.props.databases?.find(
      (x) => x._id === this.props.dbId
    );
    const table = database?.tables?.find((x) => x._id === this.props.tableId);
    const columns = table?.columns || [];

    return <Filter {...{ ...this.props, columns }} />;
  }
}

class Filter extends React.Component {
  componentDidUpdate() {
    const filters = this.props.value?.filters || [];
    if (!filters.length) this.appendFilter();
  }

  updateFilters(filters) {
    this.props.onChange({ ...(this.props.value || {}), filters: filters });
  }

  setNthFilter(filter, n) {
    this.updateFilters(
      update(this.props.value?.filters || [], { $merge: { [n]: filter } })
    );
  }

  addFilter({ filterGroup, type }) {
    let filter = { uid: Math.random(), condition: "eq" };

    if (type === "filters") {
      let index = filterGroup[filterGroup.length - 1].index;

      if (index > -1) index = index + 1;
      else index = 0;

      const newFilters = update(this.props.value?.filters || [], {
        $splice: [[index, 0, filter]],
      });

      this.updateFilters(newFilters);
    } else if (type === "filterGroup") {
      const groupIndex = filterGroup.index;

      let index = filterGroup.filter.filters?.length || 0;

      const newFilters = update(filterGroup.filter.filters || [], {
        $splice: [[index, 0, filter]],
      });

      const newFilter = { ...filterGroup.filter, filters: newFilters };

      this.updateFilters(
        update(this.props.value?.filters || [], {
          $merge: { [groupIndex]: newFilter },
        })
      );
    }
  }

  removeFilterSet({ filterGroup, type }) {
    if (type === "filters") {
      let indices = filterGroup.map((x) => x.index);

      if (indices.length) {
        const newFilters = update(this.props.value?.filters || [], {
          $splice: indices.map((index) => [index, 1]),
        });

        this.updateFilters(newFilters);
      }
    } else if (type === "filterGroup") {
      const groupIndex = filterGroup.index;

      this.updateFilters(
        update(this.props.value?.filters || [], {
          $splice: [[groupIndex, 1]],
        })
      );
    }
  }

  appendFilter(filter) {
    filter = filter || { condition: "eq" };
    filter = { ...filter, uid: filter.uid || Math.random() };

    this.updateFilters(
      update(this.props.value?.filters || [], {
        $push: [filter],
      })
    );
  }

  removeFilter(i) {
    this.updateFilters(
      update(this.props.value?.filters || [], { $splice: [[i, 1]] })
    );
  }

  renderFilter({ value: filter, onChange, remove }) {
    const { columns } = this.props;

    return (
      <div className="calcItemDatabaseFilterBox" key={filter.uid}>
        <div className="calcItemDatabaseFilterBoxInner">
          <div className="calcItemDatabaseFilterBoxInnerRow">
            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              onChange={(option) => onChange({ ...filter, name: option.value })}
              value={filter.name || ""}
              placeholder="Column"
              listEmptyPlaceholder={<span>No Columns</span>}
              options={columns?.map((x) => ({ value: x.name, label: x.name }))}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
                chevron: "optionConditionTableFilterMetaRequirementIco",
              }}
              onChange={(option) =>
                onChange({ ...filter, condition: option.value })
              }
              value={filter.condition}
              options={
                this.props.customFilterConditions ||
                conditionObj.filterConditions
              }
              placeholder={"Select Condition"}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
          <div className="calcItemDatabaseFilterBoxInnerRow">
            <RichTextData
              {...{
                ...this.props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                value: filter.valueObj,
                onChange: (valueObj) =>
                  onChange({ ...filter, valueType: "textParts", valueObj }),
              }}
            />
          </div>

          <div onClick={() => remove()} className="removeFilterButton">
            Remove
          </div>
        </div>
      </div>
    );
  }

  renderFilterGroup(filterGroupData) {
    const { filter: filterGroup, index: groupIndex } = filterGroupData;

    return filterGroup.filters?.map((filter, filterIndex) => {
      return this.renderFilter({
        value: filter,
        onChange: (obj) => {
          const newFilters = update(filterGroup.filters || [], {
            $merge: { [filterIndex]: obj },
          });
          const newFilterGroup = {
            ...filterGroup,
            filters: newFilters,
          };

          this.setNthFilter(newFilterGroup, groupIndex);
        },
        remove: () => {
          this.setNthFilter(
            {
              ...filterGroup,
              filters: update(filterGroup.filters || [], {
                $splice: [[filterIndex, 1]],
              }),
            },
            groupIndex
          );
        },
      });
    });
  }

  renderFilters(filters) {
    return filters.map(({ filter, index }) =>
      this.renderFilter({
        value: filter,
        onChange: (filter) => {
          this.setNthFilter(filter, index);
        },
        remove: () => {
          this.removeFilter(index);
        },
      })
    );
  }

  render() {
    let filterGroups = [];
    const filters = this.props.value?.filters || [];
    let rawFilters = [];
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      if (filter?.type === "filterGroup") {
        if (rawFilters.length) {
          filterGroups.push({
            type: "filters",
            filterGroup: rawFilters,
            key: i + "-" + rawFilters[0].filter.uid,
          });
          rawFilters = [];
        }
        filterGroups.push({
          type: "filterGroup",
          filterGroup: { filter, index: i },
          key: filter.uid,
        });
      } else {
        rawFilters.push({ filter, index: i });
      }
    }

    if (rawFilters.length) {
      filterGroups.push({
        type: "filters",
        filterGroup: rawFilters,
        key: rawFilters[0].filter.uid,
      });
      rawFilters = [];
    }

    return (
      <>
        {filterGroups.map(({ type, filterGroup, key }, i) => {
          return (
            <div key={key} className="filterSet">
              <div className="calcItemDatabaseFilterLabelRow">
                {i === 0 ? (
                  <div className="calcItemDatabaseFilterLabel">Filter</div>
                ) : (
                  <div className="calcItemDatabaseFilterOptions">
                    {[
                      { value: "[AND]", label: "And" },
                      { value: "[OR]", label: "Or" },
                    ].map((item) => (
                      <div
                        key={item.value}
                        className={
                          "filterOptionItem " +
                          (filterGroup.filter.name === item.value
                            ? "active"
                            : "")
                        }
                        onClick={() => {
                          if (type === "filterGroup")
                            this.setNthFilter(
                              { ...filterGroup.filter, name: item.value },
                              filterGroup.index
                            );
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                )}

                <div className="filterFooter">
                  <div
                    className="addFilter"
                    onClick={() => this.addFilter({ filterGroup, type })}
                  >
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>

                  <div
                    className="removeFilter"
                    onClick={() => this.removeFilterSet({ filterGroup, type })}
                  >
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              </div>
              {type === "filters"
                ? this.renderFilters(filterGroup)
                : type === "filterGroup"
                ? this.renderFilterGroup(filterGroup)
                : null}
            </div>
          );
        })}

        <div className="pad6">
          {this.props.disbaleFilterGroup ? null : (
            <div
              className="addFilterButton dark"
              onClick={() => {
                this.appendFilter({
                  name: "[OR]",
                  type: "filterGroup",
                  filters: [{ uid: Math.random(), condition: "eq" }],
                });
              }}
            >
              Add Filter Set
            </div>
          )}
        </div>
      </>
    );
  }
}

const styleObj = {
  container: {
    background: "#a0a0a0",
    padding: "5px 9px",
  },
  orContainer: {
    background: "#6d6c6c",
    padding: "5px 0px",
    margin: "5px 0",
  },
  title: {
    fontSize: "9px",
    fontWeight: "bold",
    padding: "0px 0px 0px",
    color: "white",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2b2b2b",
    color: "#dedede",
    borderRadius: "2px",
    margin: "3px 0",
  },
  filterBox2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "2px",
  },
  roundMinus: {
    paddingTop: "unset",
    backgroundColor: "white",
    fontSize: "13px",
    color: "red",
    borderRadius: "52px",
    padding: "0px 3px",
    fontWeight: "bold",
    margin: "0px 5px",
    height: "fit-content",
    cursor: "pointer",
  },
  select: {
    border: 0,
    background: 0,
    color: "#dea800",
  },
  select1: {
    border: 0,
    background: 0,
    fontSize: "11px",
    color: "#9697d4",
    width: "100%",
  },

  addMore: {
    fontSize: "9px",
    fontWeight: "bold",
    color: "black",
    margin: "0 7px",
    paddingBottom: "3px",
    cursor: "pointer",
  },
  textBox: {
    color: "#bbb6b2",
    border: "0",
    fontSize: "small",
    background: 0,
    margin: "0 0 7px",
  },
};

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(DatabaseQueryFilter);
