import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import MediaData from "./MediaData";
import Background from "../../Common/StyleProperties/Background";
import Spacing from "../../Common/StyleProperties/Spacing";
import MediaAppearance from "../../Common/StyleProperties/MediaAppearance";

class MediaProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const styleProps = {
      ...this.props,
      value: this.props.styles,
      onChange: this.props.setStyles,
    };

    return (
      <>
        <MediaAppearance
          {...{
            ...this.props,
            onChange: mergeChange,
            styles: styleProps.value,
            changeStyles: styleProps.onChange,
          }}
        />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />
        <Background {...styleProps} />
        <MediaData
          {...this.props}
          value={value.mediaData}
          onChange={(x) => mergeChange({ mediaData: x })}
        />
      </>
    );
  }
}

export default MediaProperties;
