import React from "react";
import { Table } from "react-bootstrap";
import update from "immutability-helper";
import api from "../../Services/Api/api";

class RenderDataTable extends React.Component {
  state = {
    loading: false,
    error: null,
    items: null,
    count: null,
    page: 1,
    limit: 50,
    loadedPage: null,
    query: {},
  };

  componentDidMount() {
    this.load();
  }

  searchDb(propQuery) {
    const {
      props: { database, table },
    } = this;

    let query = {
      dbId: database._id,
      tableId: table._id,
      filters: [],
      limit: 50,
      skip: 0,
      sortby: "updatedAt",
      order: -1,
      ...propQuery,
    };

    return api.post("v1/database/read", query).then((x) => x.data);
  }

  async load() {
    try {
      const {
        props: { database, table },
        state: { page, limit, query },
      } = this;

      if (
        !database?._id ||
        !table ||
        !table.columns ||
        ["local"].includes(database.type)
      )
        return;

      this.setState({ loading: true, error: null });

      const filters = Object.keys(query).map((x) => ({
        name: x,
        value: query[x],
      }));

      const count = await this.searchDb({
        countOnly: true,
        filters,
      });
      this.setState({ count });

      let skip = limit * (parseInt(page) - 1);
      const items = await this.searchDb({
        limit,
        skip,
        filters,
      });
      this.setState({ items, loading: false, loadedPage: page });
    } catch (e) {
      window.alert(e.message || e.toString?.());
      this.setState({ loading: false, error: e.message || e.toString?.() });
    }
  }

  reload() {
    this.setState({ page: 1 }, () => this.load());
  }

  async deleteItem(_id) {
    const payload = {
      dbId: this.props.database._id,
      tableId: this.props.table._id,
      filters: [{ name: "_id", value: _id }],
      limit: 1,
      valueType: "deleteRecord",
    };

    return api.post("v1/database/write", payload).then((data) => {
      let index = this.state.items?.findIndex((x) => x._id === _id);
      if (index > -1)
        this.setState({
          items: update(this.state.items || [], { $splice: [[index, 1]] }),
        });
      return data;
    });
  }

  async updateItem(item) {
    const document = {};
    for (const key in item) {
      if (Object.hasOwnProperty.call(item, key)) {
        const value = item[key];
        if (key !== "_id") document[key] = { value };
      }
    }

    let payload = {
      dbId: this.props.database._id,
      tableId: this.props.table._id,
      document,
      valueType: "addRecord",
    };

    if (!item._id) {
      api.post("v1/database/write", payload).then((data) => {
      });

      //  (err, data) => {
      //   console.log({ err, data });

      //   if (err || !data) return reject(new Error(err?.toString?.()));

      //   let newRecordId = data.insertedIds && data.insertedIds[0];
      //   return resolve({ ...item, _id: newRecordId });
      // }
    } else {
      payload = {
        ...payload,
        valueType: "editRecord",
        filters: [{ name: "_id", value: item._id }],
        limit: 1,
      };

      api.post("v1/database/write", payload).then((data) => {

        let index = this.state.items?.findIndex((x) => x._id === item._id);
        if (index > -1)
          this.setState({
            items: update(this.state.items || [], {
              $merge: { [index]: item },
            }),
          });

        return data;
      });
    }
  }

  renderPagination() {
    const {
      state: { count, limit, page, loadedPage, loading },
    } = this;

    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          {loadedPage}
          {" / "}
          {Math.ceil(count / limit)}
          <button
            disabled={loadedPage <= 1}
            onClick={() =>
              this.setState({ page: loadedPage - 1 }, () => this.load())
            }
          >
            Prev Page
          </button>
          <button
            disabled={loadedPage >= Math.ceil(count / limit)}
            onClick={() =>
              this.setState({ page: loadedPage + 1 }, () => this.load())
            }
          >
            Next Page
          </button>
          {"    "}
          Page:{" "}
          <input
            value={page}
            min={1}
            max={Math.ceil(count / limit)}
            onChange={(e) => this.setState({ page: e.target.value })}
            type="number"
            style={{ width: "50px" }}
          />
          <button
            onClick={() => {
              this.setState(
                {
                  page: Math.max(1, Math.min(page, Math.ceil(count / limit))),
                },
                () => this.load()
              );
            }}
          >
            {loading ? "Loading" : "Load"}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { table, database },
      state: { items, query, loading },
    } = this;

    const columns = table.columns?.map((x) => x.name);

    return (
      <>
        <div>{loading ? "Loading..." : null}</div>
        {this.renderPagination()}
        <Table bordered>
          <tbody>
            <tr>
              {columns?.map((col) => (
                <th key={col}>
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.load();
                      }}
                    >
                      {col}{" "}
                      <input
                        value={query[col]?.toString() || ""}
                        onChange={(e) => {
                          let value = e.target.value;

                          this.setState({
                            query: value
                              ? { ...query, [col]: value }
                              : update(query, { $unset: [col] }),
                          });
                        }}
                      ></input>
                    </form>
                  </div>
                </th>
              ))}
              <th></th>
            </tr>
            {["local"].includes(database?.type) ? null : (
              <>
                <RenderItem
                  {...{
                    index: -1,
                    item: null,
                    edit: true,
                    columns,
                    updateItem: this.updateItem.bind(this),
                    reload: this.reload.bind(this),
                  }}
                />
                {items?.map((item, index) => (
                  <RenderItem
                    {...{
                      item,
                      index,
                      key: item?._id || index,
                      columns,
                      deleteItem: this.deleteItem.bind(this),
                      updateItem: this.updateItem.bind(this),
                      reload: this.reload.bind(this),
                    }}
                  />
                ))}
              </>
            )}
          </tbody>
        </Table>
        {this.renderPagination()}
      </>
    );
  }
}

class RenderItem extends React.PureComponent {
  state = {
    edit: !!this.props.edit,
    loading: false,
    error: null,
  };

  deleteItem(_id) {
    this.setState({ loading: true, error: null });
    this.props.deleteItem(_id).catch((e) => {
      if (window.alert(e.message)) this.setState({ loading: false });
    });
  }

  updateItem(item) {
    this.setState({ loading: true, error: null });
    this.props
      .updateItem(item)
      .then((updatedItem) => {
        if (item._id)
          this.setState({
            editingItem: null,
            loading: false,
            edit: false,
          });
        else {
          this.setState({ loading: false, editingItem: null });
          this.props.reload();
        }
      })
      .catch((e) => {
        window.alert(e.message);
        this.setState({ loading: false });
      });
  }

  onEditingItemChange(obj) {
    this.setState({
      editingItem: { ...(this.state.editingItem || {}), ...obj },
    });
  }

  renderTd({ col, item }) {
    return (
      <td key={col}>
        {["string", "number"].includes(typeof item?.[col])
          ? item?.[col]
          : ["object"].includes(
              item?.[col]
                ? JSON.stringify(item?.[col])
                : item?.[col]?.toString?.()
            )}
      </td>
    );
  }

  renderEditingTd({ col, item, onChange, index }) {
    if (item?.[col] && !["string", "number"].includes(typeof item?.[col])) {
      return this.renderTd({ col, item, index });
    }
    return (
      <td key={col}>
        <input
          disabled={this.state.loading || col === "_id"}
          value={item?.[col] || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </td>
    );
  }

  render() {
    const {
      props: { item, index },
      state: { edit, editingItem, loading },
    } = this;

    return (
      <tr key={item?._id || index}>
        {this.props.columns?.map((col) => {
          return edit
            ? this.renderEditingTd({
                col,
                item: editingItem,
                index,
                onChange: (val) => this.onEditingItemChange({ [col]: val }),
              })
            : this.renderTd({ col, item, index });
        })}
        <td>
          {edit ? (
            <>
              {item?._id ? (
                <button onClick={() => this.setState({ edit: false })}>
                  Cancel
                </button>
              ) : (
                <button onClick={() => this.setState({ editingItem: null })}>
                  Clear
                </button>
              )}
              <button
                disabled={loading}
                onClick={() => this.updateItem(editingItem)}
              >
                {item?._id ? "Update" : "Create New"}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => this.setState({ edit: true, editingItem: item })}
              >
                Edit
              </button>
              <button
                disabled={loading || !item?._id}
                onClick={() =>
                  window.confirm("Confirm Delete ?") &&
                  this.deleteItem(item._id)
                }
              >
                Delete
              </button>
            </>
          )}
        </td>
      </tr>
    );
  }
}

export default RenderDataTable;
