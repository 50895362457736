import React from "react";

import CustomSelect from "../../../Components/etc/CustomSelect";
import { getDatabaseSelectorOptions } from "./DatabaseSelector";
import DatabaseQueryFilter from "./DatabaseQueryFilter";

import DataSelectorFooter from "./DataSelectorFooter";
import DatabaseSortingQuery from "../DatabaseOperations/DatabaseSortingQuery";

const chev = (
  <div className="calcItemUnitMainDropdownFooter">
    <div className="calcItemUnitMainDropdownFooterArrow">
      <div className="one"></div>
      <div className="two"></div>
    </div>
  </div>
);

class DatabaseDataSelector extends React.Component {
  selectorId = Math.random();

  render() {
    const { value, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <div className="calcItemUnit">
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Database Table</div>

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              labelIcon={
                <img
                  className="calcItemUnitMainDropdownIco"
                  src={require("../../../Assets/img/options/options/database.png")}
                ></img>
              }
              placeholder={"Select"}
              {...getDatabaseSelectorOptions({
                ...this.props,
                selectorId: this.selectorId,
                onChange: (obj) => mergeChange(obj),
              })}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        </div>

        <div className="calcItemUnitBox">
          <div className="calcItemDatabaseFilter">
            <DatabaseQueryFilter
              {...this.props}
              dbId={value?.dbId}
              tableId={value?.tableId}
              value={value}
              onChange={(obj) => mergeChange(obj)}
              valueTypeData={null}
            />
          </div>
        </div>

        <DataSelectorFooter
          {...this.props}
          value={value}
          onChange={(obj) => mergeChange(obj)}
        />
      </div>
    );
  }
}

export class DatabaseSelector extends React.Component {
  selectorId = Math.random();

  render() {
    const { value, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <CustomSelect
        classNames={{
          head: "calcItemUnitMainDropdown",
          label: "calcItemUnitMainDropdownLabel",
        }}
        labelIcon={
          <img
            className="calcItemUnitMainDropdownIco"
            src={require("../../../Assets/img/options/options/database.png")}
          ></img>
        }
        placeholder={"Select"}
        {...getDatabaseSelectorOptions({
          ...this.props,
          selectorId: this.selectorId,
          onChange: (obj) => mergeChange(obj),
        })}
        jointActionRow={chev}
      />
    );
  }
}

export class RepeatingDatabaseSelector extends React.Component {
  render() {
    const { value = {}, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    const { databaseSortingQuery, pagination } =
      this.props.immediateProps || {};

    return (
      <>
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Database Table</div>

            <DatabaseSelector
              {...{
                ...this.props,
                immediateProps: null,
                externalApi: this.props.immediateProps.externalApi,
                webrtcStreams: this.props.immediateProps.webrtcStreams,
              }}
            />
          </div>
        </div>

        {["webrtcStreams"].includes(value.dbId) ? null : (
          <>
            <div className="calcItemUnitBox">
              <div className="calcItemDatabaseFilter">
                <DatabaseQueryFilter
                  {...this.props}
                  dbId={value?.dbId}
                  tableId={value?.tableId}
                  value={value}
                  onChange={(obj) => mergeChange(obj)}
                  valueTypeData={null}
                />
              </div>
            </div>

            {databaseSortingQuery ? (
              <DatabaseSortingQuery
                {...this.props}
                dbId={value.dbId}
                tableId={value.tableId}
                value={value}
                onChange={(value) => mergeChange(value)}
              />
            ) : null}

            {pagination ? (
              <Pagination
                value={value.pagination}
                onChange={(val) => mergeChange({ pagination: val })}
              />
            ) : null}
          </>
        )}
      </>
    );
  }
}

const Pagination = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const valueTypes = [
    { value: "limit", label: "Limit Results" },
    { value: "infinite", label: "Infinite Load" },
    { value: "page", label: "Pages" },
  ];

  const setValue = (value) => {
    let updatedValueObj = {
      ...(value.valueObj || {}),
    };
    for (let i = 0; i < valueTypes.length; i++) {
      updatedValueObj = { ...updatedValueObj, [valueTypes[i].value]: value };
    }

    mergeChange({ valueObj: updatedValueObj });
  };

  return (
    <div className="calcItemUnitFooter" style={{ flexDirection: "row" }}>
      <div className="calcItemUnitMain">
        <div className="calcItemUnitMainLabel">Pagination Type</div>

        <CustomSelect
          classNames={{
            head: "calcItemUnitMainDropdown",
            label: "calcItemUnitMainDropdownLabel",
          }}
          placeholder={"Select"}
          value={value?.valueType || ""}
          onChange={(option) => mergeChange({ valueType: option.value })}
          options={valueTypes}
          jointActionRow={chev}
        />
      </div>

      <div className="calcItemUnitMain">
        <div className="calcItemUnitMainLabel">Count</div>

        <div className="calcItemUnitMainDropdown">
          <input
            className="calcItemUnitMainDropdownLabel"
            placeholder={"Item count"}
            value={value.valueObj?.[value?.valueType || "limit"] || ""}
            onChange={(event) => setValue(event.target.value)}
            disabled={!value?.valueType}
          />
        </div>
      </div>
    </div>
  );
};

export class DatabaseTableSelector extends React.Component {
  selectorId = Math.random();

  render() {
    const { value, onChange, classNames, className } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <CustomSelect
        className={className}
        classNames={{
          head: "calcItemUnitMainDropdown",
          label: "calcItemUnitMainDropdownLabel",
          ...classNames,
        }}
        labelIcon={
          <img
            className="calcItemUnitMainDropdownIco"
            src={require("../../../Assets/img/options/options/database.png")}
          ></img>
        }
        placeholder={"Select"}
        {...getDatabaseSelectorOptions({
          ...this.props,
          selectorId: this.selectorId,
          onChange: (obj) => mergeChange(obj),
        })}
        jointActionRow={chev}
      />
    );
  }
}

export default DatabaseDataSelector;
