import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import withRouter from "../../Components/Wrapper/with-router";
import { DatabasesPopup } from "./ProjectDatabase";
import { AddUserPopup } from "./AddUser";
import Screens from "./Screens";
import ChangeLog from "./ChangeLog";
import navigationModule from "../../Modules/navigation/navigation-module";
import ProjectDetailsInfo from "./ProjectDetailsContainers/ProjectDetailsInfo";
import ProjectMembers from "./ProjectDetailsContainers/ProjectMembers";
import ProjectResources from "./ProjectDetailsContainers/ProjectResources";
import ProjectTriggers from "./ProjectDetailsContainers/ProjectTriggers";
import ProjectBackups from "./ProjectDetailsContainers/ProjectBackups";
import ProjectIntegrations from "./ProjectDetailsContainers/ProjectIntegrations";
import ProjectEmails from "./ProjectDetailsContainers/ProjectEmails";

const sideMenuItems = [
  { value: "details", component: ProjectDetailsInfo },
  { value: "members", component: ProjectMembers },
  { value: "resources", component: ProjectResources },
  { value: "triggers", component: ProjectTriggers },
  { value: "backups", component: ProjectBackups },
  { value: "integrations", component: ProjectIntegrations },
  { value: "emailTemplates", component: ProjectEmails },
];

const ProjectDetailsInner = withRouter((props) => {
  const [project, setProject] = useState(props.project);
  const [components, setComponents] = useState(props.components);
  const [loading, setLoading] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("details");
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const projectId = props.projectId;

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const { project, components } = await api.get("v1/project/" + projectId);
      const { databases } = await api.get("v1/database");
      props.setScreenState({ databases }, false, "BUILDER");

      setProject(project);
      setComponents(components);
      setLoading(false);
      setTimeout(() => {
        setReloadTrigger(reloadTrigger + 1);
      }, 10);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      if (props.close) props.close();
    }
  }, [projectId]);

  useEffect(() => {
    load();
  }, [load]);

  const SelectedComponent = sideMenuItems.find(
    (x) => x.value === selectedMenuItem
  )?.component;

  const propsToPass = {
    ...props,
    project,
    components,
    reload: () => load(),
    key: reloadTrigger,
  };

  if (!project)
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="loader large"></div>
      </div>
    );

  return (
    <div className="detailScreen">
      <div className="pageContainer">
        <div className="sideMenu">
          <div className="sideMenuLogo">{project?.name}</div>
          {sideMenuItems?.map(({ value, label }) => {
            const isActive = value === selectedMenuItem;
            return (
              <div
                key={value}
                className={"sideMenuItem" + (isActive ? " active" : "")}
                onClick={() => setSelectedMenuItem(value)}
              >
                {label || _.startCase(value)}
              </div>
            );
          })}
        </div>
        <div className="mainContainer">
          <div className="containerAdjust w600">
            {loading && !project ? (
              <div>Loading</div>
            ) : (
              <SelectedComponent {...propsToPass} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  user: state.pState.AUTH.user,
  team: state.pState.AUTH.team,
  databases: state.vState.BUILDER.databases,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "PROJECT_DETAILS") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const ProjectDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailsInner);

export const ProjectDetailPopup = (props) => {
  return (
    <div
      className="modalWrapper"
      style={{ display: props.visible ? "flex" : "none" }}
    >
      <div className="modalItem">
        <ProjectDetails {...{ ...props, key: props.projectId }} />
      </div>
    </div>
  );
};

export const ProjectDetailPopupButton = withRouter((props) => {
  const visible = props.router.searchParams?.project_detail === "1";

  return (
    <>
      <div
        className="currentSettings"
        onClick={() =>
          navigationModule.addQuery(
            { project_detail: 1 },
            props.router.navigate
          )
        }
      >
        <img
          className="currentSettingsIco"
          src={require("../../Assets/img/header/settings.png")}
        />
      </div>

      {props.projectId ? (
        <ProjectDetailPopup
          {...{
            close: () =>
              navigationModule.removeQuery(
                ["project_detail"],
                props.router.navigate
              ),
            ...props,
            visible,
          }}
        />
      ) : null}
    </>
  );
});
