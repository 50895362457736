import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";

const SCREEN_NAME = "ACCOUNT_DETAILS";

const AccountDetailsScreen = (props) => {
  return (
    <div className="userPanel">
      <SideNav
        activeSidebarItem=""
        activeSidebarSubItem={"details"}
        key="sidenav"
      />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/account/details" />
        <div className="upHeader">
          <div className="upHeaderTop">
            <div className="upHeaderRow">
              <div className="upTitle">Account Details</div>
            </div>
          </div>
        </div>

        <div className="upBody">
          <div className="upBodyInner"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsScreen);
