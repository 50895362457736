import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";

import modules from "../../Modules";
import api from "../../Services/Api/api";
import CustomSelect from "../../Components/etc/CustomSelect";
import config from "../../Config";
import { toast } from "react-toastify";
import Header from "../Home/Header";

const stripePromise = loadStripe(config.stripe.publicKey);

const PaymentInformation = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async getSetupIntent() {
      if (!stripe || !elements) {
        throw new Error("Stripe or elements not loaded.");
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error("CardElement not found.");
      }

      const { setupIntent, error: stripeError } =  await stripe.confirmCardSetup(
        props.clientSecret, // Client secret from Stripe
        {
          payment_method: {
            card: cardElement,
            // billing_details: {
            //   name: props.team.user.name,
            //   email: props.team.user.email,
            // },
          },
        }
      );

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      return setupIntent;
    },
  }));

  return <CardElement />;
});

class SignupPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientSecret: null,
      error: null,
      loaded: false,
    };

    this.enabled = false;
    this.paymentInfoRef = createRef();
  }

  componentDidMount() {
    this.conditionalLoading({ enabled: false });
  }

  componentDidUpdate(prevProps) {
    this.conditionalLoading(prevProps);
  }

  conditionalLoading(prevProps) {
    if (
      !this.enabled &&
      prevProps.enabled !== this.props.enabled &&
      this.props.enabled
    ) {
      this.load();
    }
  }

  async load() {
    this.enabled = true;
    this.setState({ error: false });
    this.props.setLoading("initiating");

    api
      .post("v1/builder-payment/stripe/create-setup-intent", {
        email: this.props.email,
      })
      .then((data) => {
        console.log({ data }, this);
        this.enabled = true;
        this.setState({ clientSecret: data.clientSecret, loaded: true });
      })
      .catch((err) => {
        this.enabled = false;
        this.props.setError(err.message);
        toast.error(err.message);
      })
      .finally(() => {
        this.props.setLoading(null);
        console.log("finaly done");
      });
  }

  async getSetupIntent() {
    return this.paymentInfoRef.current.getSetupIntent();
  }

  render() {
    const { clientSecret, error } = this.state;

    if (!this.enabled) return null;
    else if (!clientSecret) {
      if (error) return <div style={{ color: "red" }}>{error}</div>;
      else return <div className="loader"></div>;
    }

    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: "stripe",
          },
          loader: "auto",
        }}
      >
        <PaymentInformation {...this.props} ref={this.paymentInfoRef} clientSecret={clientSecret}/>
      </Elements>
    );
  }
}

const SignupScreen = (props) => {
  const plans = props.plans?.filter((x) => x.active);
  const planIds = plans?.map((x) => x.value);

  const { setLoginRes, onTeamSelect } = props;

  const [form, setForm] = useState({
    planName: planIds?.includes(props.router?.searchParams?.plan)
      ? props.router?.searchParams?.plan
      : "builder",
    trial: props.router?.searchParams?.trial === "0" ? false : true,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signupPaymentLoading, setSignupPaymentLoading] = useState(null);

  const signupPaymentRef = useRef();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      let { plans: updatedPlans } = await api.get("v1/user/subscription/plans");

      props.setScreenState({ plans: updatedPlans }, true);

      const planIds = updatedPlans.filter((x) => x.active).map((x) => x.value);

      if (!plans || !planIds.includes(form.planName)) {
        const planName = planIds.includes(props.router?.searchParams?.plan)
          ? props.router?.searchParams?.plan
          : "builder";

        setForm({ ...form, planName });
      }
    } catch (e) {
      throw new Error("Error getting plan details: ", e.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading("Signing Up");
    setError(null);

    try {
      const isTrial = !!form.trial;

      let paymentMethodId;
      if (!isTrial) {
        const setupIntent = await signupPaymentRef.current.getSetupIntent();
        paymentMethodId = setupIntent.payment_method;
      }

      const response = await modules.auth.signup({
        firstName: form.name,
        lastName: "",
        email: form.email,
        password: form.password,
        planName: form.planName,
        trial: isTrial,
      });

      if (!isTrial) {
        if (!response?.user?._id || !response?.subscription?._id) {
          throw new Error("Something went wrong during signup");
        }

        setLoading("Processing Payment");

        await api
          .post("v1/user/subscription/pay", {
            user: response.user._id,
            subscription: response.subscription._id,
            paymentMethodId,
          })
          .catch((e) => {
            toast.error("Error processing payment");
          });
      }

      setLoading("Logging In");

      const loginRes = await api.post("v1/user/login", {
        email: form.email,
        password: form.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      if (loginRes.user.teams.length > 1) {
        setLoginRes(loginRes);
      } else {
        await onTeamSelect(loginRes.user.teams[0], loginRes);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(null);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div className="frontWrapper">
      {/* <div className="bgDecorMain"></div> */}

      <div className="onboardingLeft">
        <div className="frontHeader">
          <div className="headLogo">AppWolo</div>
          <Link className="headerMain" to="/login">
            Login
          </Link>
        </div>
        <form onSubmit={handleSignup} autoComplete="off">
          <div className="onboardingBox">
            <div className="obBox">
              <h2>Create your account now</h2>
              <div className="obItem">
                <div className="obLabel">Full Name</div>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  value={form.name || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="obItem">
                <div className="obLabel">Email</div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={form.email || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="obItem">
                <div className="obLabel">Password</div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={form.password || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="obItem">
                <div className="obLabel">Confirm password</div>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={form.confirmPassword || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="trial"
                    checked={form.trial}
                    onChange={() => setForm({ ...form, trial: !form.trial })}
                  />
                  3 days trial
                </label>
              </div>
              <div style={{ display: !form.trial ? "block" : "none" }}>
                <SignupPayment
                  ref={signupPaymentRef}
                  {...{
                    email: form.email,
                    enabled: !form.trial,
                    setLoading: setSignupPaymentLoading,
                    setError: setError,
                  }}
                />
              </div>
              <div>
                {error ? (
                  <div style={{ color: "red" }}>{error}</div>
                ) : form.password && form.password != form.confirmPassword ? (
                  <div style={{ color: "red" }}>
                    Confirm Password does not match the Password field.
                  </div>
                ) : null}
                <button
                  className="frontDarkButton"
                  disabled={
                    loading ||
                    // !stripe ||
                    // !elements ||
                    signupPaymentLoading ||
                    form.password !== form.confirmPassword ||
                    !form.planName
                  }
                >
                  {loading ? "Processing..." : "Sign Up"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="enrollPlanOptions">
        {!form.trial ? (
          <>
            <h4>Plan Details</h4>
            <div className="epoLabel">Select your plan</div>
            <div className="epoWrapper">
              {plans?.map((plan) => (
                <div
                  key={plan.value}
                  className={
                    "epoItem" + (plan.value === form.planName ? " active" : "")
                  }
                  onClick={() => setForm({ ...form, planName: plan.value })}
                >
                  <div className="epoHead">
                    <div className="epoHeadContent">
                      <div className="epoLabel">Plan Name</div>
                      <div className="epoValue">{plan.label}</div>
                    </div>
                    <div className="epoHeadDecor"></div>
                  </div>

                  <div className="epoBody">
                    <div className="epoMulti">
                      <div className="epoMultiItem">
                        <div className="epoLabel">Frequency</div>
                        <div className="epoValue">Monthly</div>
                      </div>

                      <div className="epoMultiItem">
                        <div className="epoLabel">Plan Cost</div>
                        <div className="epoValue">${plan.monthlyPrice}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <img
              className="onboardingIco"
              src={require("../../Assets/img/landing-pages/decorBig.png")}
            />
            <div className="epoContentBox">
              <div className="enrollMain">Join over 2000+ builders.</div>
              <div className="enrollContextArea">
                Unleash your creativity and build powerful apps without writing
                a single line of code. Our no-code app builder makes turning
                ideas into reality fast, easy, and accessible for everyone!
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const SCREEN_NAME = "SIGNUP";
const mapStateToProps = (state) => ({
  plans: state.pState[SCREEN_NAME]?.plans,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen);
