import React from "react";

const ActiveContainerTabToggle = ({ checked, onChange }) => {
  return (
    <div
      className={`activeContainerTabStatus ${checked ? "active" : ""}`}
      onClick={() => onChange(!checked)}
    >
      Active Container Tab
    </div>
  );
};

export default ActiveContainerTabToggle;
