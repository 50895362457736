import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import OptionToggle from "../../../../../Components/Input/OptionToggle";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import Switch from "../../../../../Components/Input/Switch";

const ContainerOptions = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const itemMap = {
    scrolling: {
      value: styles.scrollable || "",
      onChange: (x) =>
        mergeStyle({ scrollable: x === styles.scrollable ? "" : x }),
      label: "Scrolling",
      options: [
        { value: "", label: "Off" },
        {
          value: "vertical",
          label: (
            <img
              className=""
              src={require("../../../../../Assets/img/options/container/widthIco.png")}
            />
          ),
        },
        {
          value: "horizontal",
          label: (
            <img
              className=""
              src={require("../../../../../Assets/img/options/container/widthIco.png")}
            />
          ),
        },
      ],
      style: "tabs",
    },
    wrap: {
      value: styles.flexWrap || "",
      onChange: (x) => mergeStyle({ flexWrap: x }),
      label: "Wrap",
      options: [
        { value: "", label: "Off" },
        { value: "wrap", label: "On" },
      ],
    },
    safeArea: {
      value: props.safeAreaViewConfig?.enable || "",
      onChange: (x) =>
        props.setSafeAreaViewConfig({
          enable: x,
        }),
      label: "Safe Area",
      options: [
        { value: "", label: "Off" },
        { value: "enable", label: "On" },
      ],
    },
    visibility: {
      value: styles.display || "flex",
      onChange: (x) => mergeStyle({ display: x }),
      label: "Visibility",
      options: [
        { value: "none", label: "Hide" },
        { value: "flex", label: "Show" },
      ],
    },
  };

  return (
    <OptionItem optionItemLabel={"Options"}>
      <div className="optionItemBody revealOffset containerOptions">
        <div className="optionItemRow">
          {[itemMap.scrolling, itemMap.wrap].map((item) => (
            <div key={item.label} style={{ display: "flex" }}>
              <div className="optionItemListLabel">{item.label}</div>

              <RenderItem {...{ item }} />
            </div>
          ))}
        </div>
        <div className="optionItemRow">
          {[itemMap.safeArea, itemMap.visibility].map((item) => (
            <div key={item.label} style={{ display: "flex" }}>
              <div className="optionItemListLabel">{item.label}</div>

              <RenderItem {...{ item }} />
            </div>
          ))}
        </div>
      </div>
    </OptionItem>
  );
};

const RenderItem = ({ item }) => {
  if (item.style === "tabs") {
    return (
      <ToggleButton
        classNames={{
          container: "optionHeadTabs",
          item: "optionHeadTabItem",
        }}
        overideDefaultStyle
        {...item}
      />
    );
  } else
    return (
      <Switch
        {...{
          ...item,
          checked: item.options[1]?.value === item.value,
          onChange: (checked) =>
            item.onChange(item.options[checked ? 1 : 0].value),
        }}
      />
    );
};

export default ContainerOptions;
