import React, { createRef, useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import _ from "lodash";

import api from "../../Services/Api/api";
import config from "../../Config";
import CustomSelect from "../../Components/etc/CustomSelect";
import SearchInput from "../../Components/Input/SearchInput";
import { toast } from "react-toastify";
import Avatar from "../../Components/etc/Avatar";
import PaymentComponent from "../../Components/etc/PaymentComponent";

const AddProjectPopup = (props) => {
  const { visible, close, callback } = props;

  const [form, setForm] = useState({
    ...(props.project || {
      data: { platform: "web" },
    }),
  });
  const [img, setImg] = useState(
    props.project?.image ? api.getFileLink(props.project.image) : null
  );
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [searchUserQ, setSearchUserQ] = useState("");
  const [searchedUsers, setSearchedUsers] = useState(null);
  const [permittedMembers, setPermittedMembers] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [costDetails, setCostDetails] = useState(null);

  const paymentInformationRef = useRef();

  const allUsers = props.allUsers?.map((x) => ({ ...x.user, role: x.role }));

  useEffect(() => {
    if (!searchUserQ) {
      setSearchedUsers(null);
    } else {
      const regex = new RegExp(searchUserQ, "i");
      setSearchedUsers(
        allUsers?.filter(
          (x) =>
            x.role === "member" &&
            regex.test(`${x.firstName || ""} ${x.lastName}||''`)
        )
      );
    }
  }, [searchUserQ]);

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      let invalidValues = [];
      ["name", "subdomain"].map((x) => {
        if (!form[x]) invalidValues.push(`"${_.startCase(x)}"`);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      const paymentMethodId =
        await paymentInformationRef.current.getPaymentMethodId();

      if (!paymentMethodId && costDetails.newProjectCost) return null;

      const fileRes = form.image
        ? await api.media("v1/file", { file: form.image })
        : null;

      const res = await api
        .post("v1/project", {
          name: form.name,
          subdomain: form.subdomain,
          description: form.description,
          image: fileRes?.file._id,
          data: form.data,
          permissions: permittedMembers?.map((x) => ({
            user: x._id,
            permission: "admin",
          })),
          paymentMethodId: paymentMethodId || null,
          cost: costDetails.newProjectCost || 0,
        })
        .catch(async (e) => {
          if (fileRes?.file?._id) {
            api.delete(`v1/file/${fileRes?.file._id}`).catch(console.error);
          }
          throw e;
        });
      setLoading(false);
      close();

      callback(null, res.project);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);

      /* eslint-disable-next-line no-unused-expressions */
      paymentInformationRef.current?.load?.().catch(console.log);
    }
  };

  const validateForm = async ({ required, validateSubdomain = false }) => {
    try {
      let invalidValues = [];
      (required || []).map((x) => {
        if (!form[x]) invalidValues.push(`"${_.startCase(x)}"`);
      });

      if (invalidValues.length) {
        throw new Error(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      if (validateSubdomain) {
        setLoading(true);
        const { available, subdomain } = await api.get(
          "v1/project/subdomain-check",
          {
            subdomain: form.subdomain,
          }
        );

        if (!available)
          throw new Error(`The subdomain "${subdomain}" is not available`);
      }

      setFormError(null);
      setLoading(false);

      return true;
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      return false;
    }
  };

  const step1 = () => {
    return (
      <>
        <h1 className="mb0">Create Application</h1>
        <div class="bgNotes mb40 mt20">
          Enter the required information below to create your new application.
          This will allow you to proceed to the next step.
        </div>
        {formError ? <div className="errormsg">{formError}</div> : null}
        <div className="upmRowGroup mt50">
          <div className="appIconModalRow">
            <label>
              <div className="upmAppAvatar">
                {img ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={img}
                    alt="img"
                  />
                ) : (
                  <div className="upmAppAvatarAction">+ Upload</div>
                )}
                <input
                  hidden
                  type="file"
                  accept="image/png, image/jpeg"
                  files={form?.image}
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    updateForm({ image: file, newImage: true });
                    setImg(file ? URL.createObjectURL(file) : null);
                  }}
                />
              </div>
            </label>
          </div>

          <div className="upmRow">
            <div className="upmRowLabel">App Name</div>
            <div className="upmRowValue">
              <input
                type="text"
                placeholder="Enter name"
                className="upmRowInput"
                required
                value={form?.name || ""}
                onChange={(e) => {
                  updateForm({ name: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="upmRow rowTopifys removeBorderBottom">
            <div className="upmRowLabel">Description</div>
            <div className="upmRowValue">
              <textarea
                type="text"
                placeholder="Provide application description"
                className="upmRowInput"
                value={form?.description || ""}
                onChange={(e) => {
                  updateForm({ description: e.target.value });
                }}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="mpmActionRow mt50 mb15">
          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
          <div
            className="darkDefaultButton"
            onClick={async () => {
              if (await validateForm({ required: ["name"] }))
                setActiveStepIndex(1);
            }}
          >
            Continue
          </div>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <>
        <div>
          <h1 className="mb0">App Options</h1>
          <div class="bgNotes mb40 mt20">
            Enter the required information below to create your new application.
            This will allow you to proceed to the next step.
          </div>

          {formError ? <div className="errormsg">{formError}</div> : null}

          <div className="upmRowGroup mt50">
            <div className="upmRow aCenter">
              <div className="upmRowLabel">Type</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) =>
                    updateForm({
                      data: { ...(form?.data || {}), platform: option.value },
                    })
                  }
                  value={form?.data?.platform || ""}
                  options={[
                    { value: "web", label: "Web Application" },
                    { value: "mobile", label: "Mobile Application" },
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "selectBox",
                    label: "optionInputIconBoxField",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>

            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">Subdomain</div>
              <div className="upmRowValue aCenter sdGap">
                <input
                  type="text"
                  placeholder="Subdomain"
                  className="upmRowInput"
                  required
                  value={form?.subdomain || ""}
                  onChange={(e) => {
                    updateForm({ subdomain: e.target.value });
                  }}
                />
                <div>.{config.frontDomain}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="upmRowLabel mt30 mb15">Users</div>
        <div className="selectProjects">
          <div className="selectProjectsHead">
            <img
              className="projectSearchIco"
              src={require("../../Assets/img/user/search.png")}
            ></img>
            <SearchInput
              value={searchUserQ}
              onChange={(q) => setSearchUserQ(q)}
              type="text"
              placeholder="Search here"
              className="selectProjectsInput"
            />
          </div>
          <div className="selectProjectsBody">
            {(searchedUsers || permittedMembers)?.map((user) => {
              const isSelected = !!permittedMembers.find(
                (x) => x._id === user._id
              );

              return (
                <div key={user._id} className="projectLine">
                  <div className="projectLineContext">
                    <div className="projectLineIco">
                      <Avatar user={user} />
                    </div>
                    <div className="projectLineName">
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  {isSelected ? (
                    <div
                      className="defaultButton"
                      onClick={() =>
                        setPermittedMembers(
                          (permittedMembers || []).filter(
                            (x) => x._id !== user._id
                          )
                        )
                      }
                    >
                      Remove
                    </div>
                  ) : (
                    <div
                      className="defaultButton"
                      onClick={() =>
                        setPermittedMembers([...(permittedMembers || []), user])
                      }
                    >
                      Add
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="mpmActionRow mt50 mb15">
          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
          <div
            className="darkDefaultButton"
            onClick={async () => {
              if (
                await validateForm({
                  required: ["name", "subdomain"],
                  validateSubdomain: true,
                })
              )
                setActiveStepIndex(2);
            }}
          >
            {loading ? "Loading" : "Continue"}
          </div>
        </div>
      </>
    );
  };

  const step3 = () => {
    return (
      <>
        <div>
          <h1 className="mb0">App Options</h1>

          <div className="bgNotes mb40 mt20">
            Review your application details.
          </div>

          {formError ? <div className="errormsg">{formError}</div> : null}

          <div className="upmRowGroup mt50">
            <div className="upmRow aCenter">
              <div className="upmRowLabel">Project Name</div>
              <div className="upmRowValue">{form.name}</div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Project Description</div>
              <div className="upmRowValue">{form.description}</div>
            </div>

            <div className="upmRow aCenter">
              <div className="upmRowLabel">Type</div>
              <div className="upmRowValue">
                {_.startCase(form.data?.platform)}
              </div>
            </div>

            <div className="upmRow aCenter">
              <div className="upmRowLabel">Subdomain</div>
              <div className="upmRowValue">
                {form?.subdomain}.{config.frontDomain}
              </div>
            </div>

            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">Users</div>
              <div className="upmRowValue">
                {permittedMembers?.length ? (
                  <div>
                    Users:
                    <div>
                      {permittedMembers.map((user) => (
                        <div className="projectLineContext" key={user?._id}>
                          <div className="projectLineIco">
                            <Avatar user={user} />
                          </div>
                          <div className="projectLineName">
                            {user.firstName} {user.lastName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="upmAppAvatar">
            {img ? (
              <img
                style={{ width: "100%", height: "100%" }}
                src={img}
                alt="img"
              />
            ) : (
              <div></div>
            )}
          </div> */}
        </div>

        <div className="bgNotes mb40 mt20">Payment Information</div>

        <PaymentInformation
          team={props.team}
          ref={paymentInformationRef}
          onLoad={(x) => setCostDetails(x)}
        />

        <div className="mpmActionRow mt50 mb15">
          <div onClick={() => close()} className="lightDefaultButton">
            Cancel
          </div>
          <div className="darkDefaultButton" onClick={handleSubmit}>
            {loading || !costDetails ? "Loading" : "Submit"}
          </div>
        </div>
      </>
    );
  };

  const steps = [step1, step2, step3];

  if (!visible) return null;

  return (
    <div
      className={"upModal centerModal"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          {/* <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img> */}

          {/* <div className="upmPreHeadTitle">Create New Application</div> */}

          <div className="stageRow">
            {["Information", "Options", "Review"].map((step, i) => (
              <div
                className={`stageRowItem ${
                  activeStepIndex === i ? "active" : ""
                } ${activeStepIndex > i ? "enabled" : ""}`}
                onClick={() =>
                  activeStepIndex > i ? setActiveStepIndex(i) : null
                }
                key={step}
              >
                <div className={`stageNumber`}>{i + 1}</div>
                <div className="stageLabel">{step}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="upmBody">{steps[activeStepIndex]()}</div>
      </div>
    </div>
  );
};

class PaymentInformation extends React.Component {
  state = {
    selectedMethod: this.paymentMethods?.length
      ? this.paymentMethods?.[this.paymentMethods.length - 1]?.id || ""
      : "new",
    clientSecret: null,
    loading: false,
    costDetails: null,
    error: null,
  };

  paymentComponentRef = createRef();

  get paymentMethods() {
    return this.props.team.subscription?.paymentMethods;
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const costDetails = await api.get("v1/user/subscription/cost");
      this.setState({ costDetails, loading: false });
      this.props.onLoad(costDetails);
    } catch (e) {
      this.setState({ error: e.message });
      this.setState({ loading: false });
      toast.error("Error loading payment information");
    }
  }

  async getPaymentMethodId() {
    if (this.state.selectedMethod && this.state.selectedMethod !== "new")
      return this.state.selectedMethod;
    else {
      const setupIntent =
        await this.paymentComponentRef.current?.handleSetupIntent?.();

      return setupIntent?.payment_method;
    }
  }

  getLabel(paymentMethod) {
    return (
      <span>{`${paymentMethod.type}: ${
        paymentMethod.type === "card"
          ? `${paymentMethod?.card?.brand || ""} ${paymentMethod.card.last4}`
          : ""
      }`}</span>
    );
  }

  render() {
    const newProjectCost = this.state.costDetails?.newProjectCost;

    if (this.state.loading && !this.state.costDetails)
      return (
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      );
    else if (this.state.error) {
      return <div style={{ color: "red" }}>{this.state.error}</div>;
    } else if (!this.state.costDetails) {
      return null;
    }

    return (
      <>
        <div className="upmRowGroup mt50">
          <div className="upmRow aCenter">
            <div className="upmRowLabel">Cost</div>
            <div className="upmRowValue">${newProjectCost}</div>
          </div>
          {newProjectCost ? (
            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">Payment Method</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) =>
                    this.setState({ selectedMethod: option.value })
                  }
                  value={this.state.selectedMethod || ""}
                  options={[
                    { value: "new", label: "Add New" },
                    ...(this.paymentMethods || []).map((x) => ({
                      value: x.id,
                      label: this.getLabel(x),
                    })),
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "selectBox",
                    label: "optionInputIconBoxField",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        {this.state.selectedMethod === "new" && newProjectCost ? (
          <PaymentComponent
            ref={this.paymentComponentRef}
            clientSecret={this.state.clientSecret}
            setClientSecret={(x) => this.setState({ clientSecret: x })}
          />
        ) : null}
      </>
    );
  }
}

export default AddProjectPopup;
