import React, { useState } from "react";
import update from "immutability-helper";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import ValueSelector from "./ValueSelector";
import Chevron from "../../../Components/etc/Chevron";
import CustomSelect from "../../../Components/etc/CustomSelect";
import {
  backendValueTypes,
  emailTepmplateValueTypes,
  frontValueTypes,
} from "../../../Modules/calculation/builder-value-types";
import CalculationValueSelector from "./CalculationValueSelector";
import { ConditionItemValueSelector } from "./CalculationBox";

const ConditionsBox = (props) => {
  const conditionBoxes = props.value || [{}];
  const setConditionBoxes = (conditionBoxes) => props.onChange(conditionBoxes);

  const setConditionBox = (conditionBox, i) =>
    setConditionBoxes(
      update(conditionBoxes, { $merge: { [i]: conditionBox } })
    );

  const updateConditionBox = (obj, i) =>
    setConditionBox({ ...(conditionBoxes[i] || {}), ...obj }, i);
  const addConditionBox = () => {
    setConditionBoxes([...conditionBoxes, {}]);
  };
  const removeConditionBox = (i) => {
    setConditionBoxes(update(conditionBoxes, { $splice: [[i, 1]] }));
  };

  const setOperator = (operator, i) => {
    updateConditionBox({ operator }, i);
  };
  const setValueType = (valueType, i) => {
    updateConditionBox({ valueType }, i);
  };
  const setValueObj = (valueObj, i) => {
    updateConditionBox({ valueObj }, i);
  };

  function haveCommonDataType(arr, dataType) {
    if (!arr || !arr.length) return true;
    else if (dataType === "any") return true;
    else if (arr.includes("any")) return true;
    return arr.includes(dataType);
  }

  const getValidConditionOperators = (i) => {
    if (!i) {
      return [
        {
          value: "if",
          label: "If",
        },
      ];
    } else {
      return conditionObj.operatorList.filter((x) => x.outputType !== "null");
    }
  };

  const getValidConditionValueTypes = (i, conditionBoxes) => {
    const conditionBox = conditionBoxes[i];
    if (!conditionBox.operator) {
      return [];
    } else {
      return conditionObj.valueTypes.filter(
        (x) =>
          (!props.availableFor ||
            x.availableFor.includes(props.availableFor)) &&
          haveCommonDataType(
            conditionObj.operators[conditionBox.operator]?.inputTypes,
            x.outputType
          )
      );
    }
  };

  const availableFor = props.availableFor;
  const builderValueTypes =
    availableFor === "backend"
      ? backendValueTypes
      : availableFor === "emailTemplate"
      ? emailTepmplateValueTypes
      : frontValueTypes;

  return (
    <div className="optionCondition" style={styleObj.container}>
      {conditionBoxes.map((conditionBox, i) => {
        const builderValueTypeInfo = builderValueTypes.find(
          (x) => x.value === conditionBox.valueType
        );

        return (
          <RenderConditionItem
            key={i}
            {...{
              ...props,
              setOperator,
              conditionBox,
              getValidConditionOperators,
              setValueType,
              builderValueTypes,
              builderValueTypeInfo,
              removeConditionBox,
              addConditionBox,
              i,
              updateConditionBox,
            }}
          />
        );
      })}
    </div>
  );
};

const RenderConditionItem = (props) => {
  const {
    setOperator,
    conditionBox,
    getValidConditionOperators,
    setValueType,
    builderValueTypes,
    builderValueTypeInfo,
    removeConditionBox,
    addConditionBox,
    i,
    updateConditionBox,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const categories = builderValueTypes
    .map((x) => x.category)
    .filter((x, i, arr) => arr.indexOf(x) === i);

  return (
    <div className="optionConditionItem">
      <div className="optionConditionItemInner">
        <div className="optionConditionItemHead">
          <div className="optionConditionItemContext">
            <CustomSelect
              classNames={{
                head: "optionConditionItemMeta",
                label: "optionConditionItemMetaField",
                chevron: "optionConditionItemChevron",
              }}
              onChange={(option) => {
                setOperator(option.value, i);
                if (option.value) setIsOpen(true);
              }}
              value={conditionBox?.operator || ""}
              options={getValidConditionOperators(i)}
              placeholder={"--"}
            />

            {/* <CustomSelect
              classNames={{
                head: "optionConditionItemValue",
                label: "optionConditionItemValueField",
                chevron: "optionConditionItemChevron",
              }}
              className="optionConditionItemValueField"
              onChange={(option) => setValueType(option.value, i)}
              value={conditionBox?.valueType || ""}
              // options={getValidConditionValueTypes(i, conditionBoxes)}
              options={builderValueTypes}
              placeholder={"Select Condition"}
            /> */}

            <div className="optionConditionItemValue">
              <div className="optionConditionItemValueField"></div>
            </div>

            <div className={`custom-select optionConditionItemValueField`}>
              <div
                className={`custom-select-head optionConditionItemValue`}
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <div
                  className={`selected-option optionConditionItemValueField`}
                >
                  {builderValueTypeInfo?.label || "Select Condition"}
                </div>
                <Chevron className={"optionConditionItemChevron"} />
              </div>
            </div>
          </div>

          {i ? (
            <div
              onClick={() => removeConditionBox(i)}
              className="optionConditionRemove"
            >
              <div className="optionConditionRemoveLine"></div>
            </div>
          ) : (
            <div onClick={addConditionBox} className="optionConditionAdd">
              <div className="optionConditionAddLine one"></div>
              <div className="optionConditionAddLine two"></div>
            </div>
          )}

          {/* <CalculationValueSelector
            {...props}
            valueType={conditionBox.valueType}
            value={conditionBox.valueObj}
            onChange={(obj) => setValueObj(obj, i)}
          /> */}
        </div>

        {(isOpen || conditionBox?.valueType) && (
          <div className="conditionDropdown">
            <ConditionItemValueSelector
              {...{
                ...props,
                key: i.toString() + conditionBox.id,
                calculation: conditionBox,
                i,
                removeCalculation: () => removeConditionBox(i),
                updateCalculation: (calculation) =>
                  updateConditionBox(calculation, i),
                builderValueTypes,
                calculationInputs: null,
                categories,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const styleObj = {
  container: {
    textAlign: "left",
  },
  condition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#00f1f1",
    padding: "0px 7px 0px",
    gap: "2%",
  },
  select: {
    width: "100%",
    border: "none",
    background: "none",
    color: "white",
    fontWeight: "bold",
    fontSize: "12px",
    padding: "7px 0 0 0",
  },
  valueType: {
    width: "100%",
    border: "none",
    background: "none",
    color: "gray",
    fontWeight: "bold",
    padding: "7px 0 0 0",
  },
  option: {
    backgroundColor: "#f1f1f1",
    color: "gray",
  },
};

export default ConditionsBox;
