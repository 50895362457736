import React from "react";
import { OptionFourInputs } from "../../../../../Components/etc/OptionItem";

const Padding = (props) => {
  return (
    <>
      <div className="spacingSubline">Padding (inside)</div>
      <div className="optionItemBody pt0">
        <OptionFourInputs
          {...{
            ...props,
            options: [
              { key: "paddingTop", iconClass: "" },
              { key: "paddingBottom", iconClass: "rotate180" },
              { key: "paddingLeft", iconClass: "rotate270" },
              { key: "paddingRight", iconClass: "rotate90" },
            ],
          }}
        />
      </div>
    </>
  );
};

export default Padding;
