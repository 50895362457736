import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";

const SCREEN_NAME = "TICKETS";

const TicketsScreen = (props) => {
  const [allTickets, setAllTickets] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState(null);
  const [filter, setFilter] = useState(null);
  const [visibleModal, _setVisibleModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);

  const setVisibleModal = (key, data) => {
    _setVisibleModal(key);
    setModalData(data);
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [allTickets, filter]);

  const applyFilter = () => {
    if (!filter) {
      setFilteredTickets(allTickets);
    } else {
      let nameRegex = filter.q ? new RegExp("^" + filter.q, "i") : null;
      let filteredTickets = allTickets?.filter((ticket) => {
        if (filter.status && ticket.status !== filter.status) return false;

        if (filter.q && nameRegex) {
          let match = nameRegex.test(ticket.title);
          if (!match) return false;
        }
        return true;
      });
      setFilteredTickets(filteredTickets);
    }
  };

  const fetchTickets = async () => {
    try {
      const { tickets } = await api.get("v1/ticket");
      setAllTickets(tickets);
      applyFilter(tickets);
    } catch (e) {
      console.error(e.message);
    }
  };

  const deleteTicket = async (ticket) => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this ticket ? Confirm by typing the ticket name"
      );
      if (typed && typed === ticket.name) {
        await api.delete(`v1/ticket/${ticket._id}`);
        await fetchTickets();
      } else if (typed !== null) {
        window.alert("Name mismatched");
        deleteTicket(ticket);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className="userPanel">
      <SideNav activeSidebarItem="tickets" key="sidenav" />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/tickets" />
        <div className="upHeader">
          <div className="upHeaderTop">
            <div className="upHeaderRow">
              <div className="upTitle">Tickets</div>
              <div
                className="upAction"
                onClick={() => setVisibleModal("addTicket")}
              >
                <span>+</span> Create New Ticket
              </div>
            </div>

            <div className="upTabs">
              {[
                { value: null, label: "All Tickets" },
                { value: "open", label: "Open Tickets" },
                { value: "closed", label: "Closed Tickets" },
              ].map((item) => (
                <div
                  key={item.value}
                  className={
                    "upTabsItem " +
                    (filter?.status == item.value ? "active" : "")
                  }
                  onClick={() =>
                    setFilter({ ...(filter || {}), status: item.value })
                  }
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>

          <div className="upHeaderBottom">
            <div className="projectSearch">
              <img
                className="projectSearchIco"
                src={require("../../Assets/img/user/search.png")}
              ></img>
              <SearchInput
                type="text"
                className="projectSearchInput"
                placeholder="Search here"
                value={filter?.q || ""}
                onChange={(q) => setFilter({ ...(filter || {}), q })}
              />
            </div>

            <UserPresence component={{ _id: props.team.user._id }} />
          </div>
        </div>

        <div className="upBody">
          <div className="upBodyInner">
            <div className="fullDataTable">
              <table>
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Title</th>
                    <th>Created At</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets?.map((ticket, index) => {
                    const {
                      _id,
                      title,
                      details,
                      createdAt,
                      status,
                      attachments,
                    } = ticket;
                    return (
                      <tr key={index} onClick={() => {}}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="tableData">{title}</div>
                        </td>
                        <td>
                          <div className="tableData">
                            {formatDate(createdAt)}
                          </div>
                        </td>
                        <td>{_.startCase(status)}</td>
                        <td>
                          <div className="tableData">
                            <div className="minActionRow">
                              <div
                                onClick={() =>
                                  setVisibleModal("ticket", ticket)
                                }
                                className="minButton"
                              >
                                Details
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <AddTicketPopup
        key={"addTicket_" + (visibleModal === "addTicket")}
        visible={visibleModal === "addTicket"}
        close={() => setVisibleModal(null)}
        callback={(err, ticket) => {
          if (!err && ticket) {
            setAllTickets(update(allTickets, { $push: [ticket] }));
          }
        }}
      />

      <TicketPopup
        key={"ticket_" + (visibleModal === "ticket" && modalData)}
        visible={visibleModal === "ticket" && modalData}
        ticket={modalData}
        close={() => setVisibleModal(null)}
      />
    </div>
  );
};

const AddTicketPopup = (props) => {
  const { visible, close, callback } = props;

  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      let invalidValues = [];
      ["title", "details"].map((x) => {
        if (!form[x]) invalidValues.push(x);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      let attachments = [];

      if (form.attachments?.length) {
        await Promise.all(
          form.attachments.map(async (attachment) => {
            const fileRes = attachment.file
              ? await api
                  .media("v1/file", { file: attachment.file })
                  .catch((e) => {
                    console.error("error uploading file: ", e.message);
                  })
              : null;

            if (fileRes?.file._id) attachments.push(fileRes?.file._id);

            return fileRes;
          })
        );
      }

      const res = await api
        .post("v1/ticket", {
          title: form.title,
          details: form.details,
          attachments: attachments,
        })
        .catch(async (e) => {
          if (attachments.length) {
            attachments.map((fileId) =>
              api.delete(`v1/file/${fileId}`).catch(console.error)
            );
          }
          throw e;
        });
      setLoading(false);
      close();

      callback(null, res.ticket);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
    }
  };

  return (
    <div
      className={"upModal"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">Create New Ticket</div>
        </div>

        <div className="upmBody">
          <h1>Create Ticket</h1>

          <div className="bgNote mt30">
            Need assistance? Fill out the form below to create a new support
            ticket, and our team will get back to you promptly!
          </div>
          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Title</div>
              <div className="upmRowValue">
                <input
                  type="text"
                  placeholder="Title"
                  className="upmRowInput"
                  required
                  value={form?.title || ""}
                  onChange={(e) => {
                    updateForm({ title: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Details</div>
              <div className="upmRowValue">
                <textarea
                  type="text"
                  placeholder="Details"
                  className="upmRowInput"
                  value={form?.details || ""}
                  onChange={(e) => {
                    updateForm({ details: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>

            <div className="upmRow checkBorder">
              <div className="upmRowLabel">Attachment(s)</div>
              <div className="upmRowValue">
                <div className="attachmentWrapper">
                  <div className="attachmentRow">
                    {form.attachments?.map(({ file, url, uid }, i) => (
                      <div className="ticketAttachmentItem" key={uid}>
                        <img className="attachmentIco" src={url} />
                        <div
                          className="attachmentRemove"
                          onClick={() => {
                            if (!loading) {
                              updateForm({
                                attachments: form.attachments.filter(
                                  (x) => x.uid != uid
                                ),
                              });
                            }
                          }}
                        >
                          <img
                            className="attachmentRemoveIco"
                            src={require("../../Assets/img/user/close.png")}
                          ></img>
                        </div>
                      </div>
                    ))}
                  </div>

                  <label>
                    <div className="mt20">
                      <div className="buttonBionic">+ Attachment</div>
                      <input
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          updateForm({
                            attachments: [
                              ...(form.attachments || []),
                              {
                                file,
                                url: URL.createObjectURL(file),
                                uid: Date.now(),
                              },
                            ],
                          });
                        }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {formError ? <div className="errormsg">{formError}</div> : null}
          </div>
          <div className="mpmActionRow">
            <div className="darkDefaultButton" onClick={handleSubmit}>
              {loading ? "Loading" : "Create Ticket"}
            </div>

            <div className="lightDefaultButton" onClick={handleSubmit}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TicketPopup = (props) => {
  const { visible, close, ticket } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [conversations, setConversations] = useState([]);

  const [form, setForm] = useState({});
  const [formError, setFormError] = useState(null);

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  useEffect(() => {
    if (ticket?._id) {
      load(ticket._id);
    }
  }, [ticket?._id]);

  const load = async (ticketId) => {
    try {
      setLoading(true);
      const { ticketConversations } = await api.get("v1/ticket/chat", {
        filter: JSON.stringify({ where: { ticket: ticketId } }),
      });

      setConversations(ticketConversations);
    } catch (e) {
      toast.error("Error loading messages: " + e.message);
    }
    setLoading(false);
  };

  if (!ticket) return null;

  const addConversation = async () => {
    try {
      if (loading || !form.details) return;

      setLoading(true);
      const ticketId = ticket._id;

      let attachments = [];

      if (form.attachments?.length) {
        await Promise.all(
          form.attachments.map(async (attachment) => {
            const fileRes = attachment.file
              ? await api
                  .media("v1/file", { file: attachment.file })
                  .catch((e) => {
                    console.error("error uploading file: ", e.message);
                  })
              : null;

            if (fileRes?.file._id) attachments.push(fileRes?.file._id);

            return fileRes;
          })
        );
      }

      await api
        .post("v1/ticket/chat", {
          ticket: ticketId,
          details: form.details,
          attachments,
        })
        .catch(async (e) => {
          if (attachments.length) {
            attachments.map((fileId) =>
              api.delete(`v1/file/${fileId}`).catch(console.error)
            );
          }
          throw e;
        });

      setLoading(false);
      setForm({});
      load(ticketId);
    } catch (e) {
      toast.error(e.message);
      setFormError(e.message);
      setLoading(false);
    }
  };

  return (
    <div
      className={"upModal"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">Ticket</div>
        </div>

        <div className="upmBody">
          <h1>{ticket.title}</h1>
          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Status</div>
              <div className="upmRowValue">
                <div className="statusHolder">
                  <div className="statusItem">{ticket.status}</div>
                </div>
              </div>
            </div>
            <div className="upmRow">
              <div className="upmRowLabel">Created</div>
              <div className="upmRowValue">{formatDate(ticket.createdAt)}</div>
            </div>

            <div className="upmRow">
              <div className="upmRowLabel">Details</div>
              <div className="upmRowValue">{ticket.details}</div>
            </div>

            <div className="upmRow checkBorder">
              <div className="upmRowLabel">Attachment(s)</div>
              <div className="upmRowValue">
                <div className="attachmentWrapper">
                  <div className="attachmentRow">
                    {ticket.attachments?.map((_id, i) => (
                      <div className="ticketAttachmentItem" key={_id}>
                        <img
                          className="attachmentIco"
                          src={api.getFileLink(_id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div>
              {conversations?.map((conversation) => {
                const {
                  _id,
                  title,
                  details,
                  attachments,
                  side,
                  createdBy,
                  user,
                  createdAt,
                } = conversation;

                return (
                  <div className={`chatitem ${side}`} key={_id}>
                    <div className="chatItemHead">
                      <div className="chatItemHeadAvatar">AS</div>
                      <div className="chatItemHeadContext">
                        <div className="chatItemHeadLabel">AppWolo Support</div>
                        <div className="chatItemHeadTimeLabel">{formatDate(ticket.createdAt)}</div>
                      </div>
                      
                    </div>
                    {details}

                    <div>
                      {attachments?.map((_id) => (
                        <div key={_id}>
                          <img
                            src={api.getFileLink(_id)}
                            style={{ width: "40px" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>







          <div className="ticketReplyWrapper">

          <div className="attachmentRow mt20">
              {form.attachments?.map(({ file, url, uid }, i) => (
                <div className="ticketAttachmentItem" key={uid}>
                  <img src={url} className="attachmentIco" />
                  <div
                    className="attachmentRemove"
                    onClick={() => {
                      if (!loading) {
                        updateForm({
                          attachments: form.attachments.filter(
                            (x) => x.uid != uid
                          ),
                        });
                      }
                    }}
                  >
                    <img
                      className="attachmentRemoveIco"
                      src={require("../../Assets/img/user/close.png")}
                    ></img>
                  </div>
                </div>
              ))}
            </div>

          <div className="ticketReply">
            <textarea
              placeholder="Enter response here"
              className="ticketReplyTextarea"
              value={form.details || ""}
              onChange={(e) => updateForm({ details: e.target.value })}
            />

            <div className="attachmentRow">
              

              <div className="flex aCenter jSpaceBetween flex1">
                <label>
                  <div className="">
                    <div className="buttonBionic">+ Attachment</div>
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        updateForm({
                          attachments: [
                            ...(form.attachments || []),
                            {
                              file,
                              url: URL.createObjectURL(file),
                              uid: Date.now(),
                            },
                          ],
                        });
                      }}
                    />
                  </div>
                </label>

                <div
                  className="darkDefaultButton"
                  onClick={() => addConversation()}
                >
                  Submit
                </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

const formatDate = (date) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return new Date(date).toLocaleString("en-US", options).toLowerCase();
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsScreen);
