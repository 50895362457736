import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import TextAlign from "../../Common/StyleProperties/TextAlign";
import Appearance from "../../Common/StyleProperties/Appearance";
import TextData from "./TextData";
import TextColor from "../../Common/StyleProperties/TextColor";
import Spacing from "../../Common/StyleProperties/Spacing";
import ContainerTabValue from "../../Common/ContainerTabValue";

class TextProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const styleProps = {
      ...this.props,
      value: this.props.styles,
      onChange: this.props.setStyles,
    };

    const isInContainerTab = !!this.props.passedParameters?.find(
      (x) => x.sourceType === "containerTabs"
    );

    return (
      <>
        {isInContainerTab ? <ContainerTabValue {...this.props} /> : null}
        <Appearance {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />

        <TextData
          {...this.props}
          value={value.textData}
          onChange={(x) => mergeChange({ textData: x })}
        />
      </>
    );
  }
}

export default TextProperties;
