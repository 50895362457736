import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import NavBar from "../Common/NavBar";
import CustomSelect from "../../Components/etc/CustomSelect";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../Services/Api/api";
import Avatar from "../../Components/etc/Avatar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SCREEN_NAME = "ANALYTICS";

export class AnalyticsDashboardScreenInner extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.loadAnalytics();
    if (!this.props.projects?.length) this.loadProjects();
  }

  async loadProjects() {
    try {
      const { projects } = await api.get("v1/project");
      this.props.setScreenState({ projects }, false, "PROJECTS");
    } catch (e) {
      toast.error("Error loadig projects: " + e.message);
    }
  }

  dateToDDMM(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${day}/${month}`;
  }

  generateLineData(analytics) {
    return this.chartNames.reduce((acc, key) => {
      if (true || (key !== "_id" && typeof analytics[0]?.[key] === "number")) {
        const formatFn = this.chartConfigs[key]?.format;
        acc[key] = {
          labels: analytics.map((item) => this.dateToDDMM(new Date(item._id))), // Assuming _id is the date
          datasets: [
            {
              labels: key,
              data: analytics.map((item) =>
                formatFn ? formatFn(item[key] || 0) : item[key] || 0
              ),
              borderColor: "#5f7efb",
              borderWidth: 4,
              tension: 0,
              pointRadius: 5,
              pointBorderColor: "rgba(255,255,255,0.0)",
              pointBackgroundColor: "rgba(255,255,255,0.0)",
              pointHoverBorderColor: "#5f7efb",
              pointHoverBackgroundColor: "#5f7efb",
              pointHoverRadius: 5,
              hoverRadius: 5,
              pointHoverBorderWidth: 5,
              borderCapStyle: "round",
            },
          ],
        };
      }
      return acc;
    }, {});
  }

  async loadAnalytics() {
    try {
      const filter = this.props.filter || {};
      const { projects, dateRange } = filter;
      const [startDate, endDate] = dateRange || [];

      if (this.state.loading || !startDate || !endDate) return;

      this.setState({ loading: true });

      // Calculate the number of days in the current date range
      const currentStartDate = new Date(startDate);
      const currentEndDate = new Date(endDate);
      const daysInRange = Math.floor(
        (currentEndDate - currentStartDate) / (1000 * 3600 * 24)
      ); // Days difference

      // Calculate the previous date range by subtracting the same number of days
      const previousEndDate = new Date(currentStartDate);
      previousEndDate.setDate(previousEndDate.getDate() - 1); // Set to the day before the current start date
      const previousStartDate = new Date(previousEndDate);
      previousStartDate.setDate(previousEndDate.getDate() - daysInRange); // Subtract the same number of days

      const req1 = api.get("v1/analytics", {
        startDate,
        endDate,
        projects,
      });

      const req2 = api.get("v1/analytics", {
        startDate: previousStartDate,
        endDate: previousEndDate,
        projects,
      });

      const { analytics } = await req1;
      const { analytics: analyticsPrev } = await req2;

      const totals = analytics.reduce((acc, item) => {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "number") {
            acc[key] = (acc[key] || 0) + item[key];
          }
        });
        return acc;
      }, {});

      const totalsPrev = analyticsPrev.reduce((acc, item) => {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "number") {
            acc[key] = (acc[key] || 0) + item[key];
          }
        });
        return acc;
      }, {});

      const percentageChanges = this.calculatePercentageChange(
        totals,
        totalsPrev
      );

      const lineDataSet = this.generateLineData(analytics);

      this.setState({ loading: false });

      this.props.setScreenState({
        data: {
          analytics,
          totals,
          percentageChanges,
          lineDataSet,
          uid: Date.now(),
        },
      });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
      console.warn(e);
    }
  }

  calculatePercentageChange(totals, totalsPrev) {
    const keys = new Set([...Object.keys(totals), ...Object.keys(totalsPrev)]);
    const percentageChanges = {};

    keys.forEach((key) => {
      const currentValue = totals[key] || 0;
      const prevValue = totalsPrev[key] || 0;

      let percentageChange = null;

      if (prevValue !== 0) {
        percentageChange = ((currentValue - prevValue) / prevValue) * 100;
      } else if (currentValue !== 0) {
        percentageChange = 100;
      }

      if (isNaN(percentageChange)) {
        percentageChange = null;
      }

      percentageChanges[key] = percentageChange;
    });

    return percentageChanges;
  }

  chartNames = [
    "appVisits",
    "uniqueVisits",
    "averageVisitsPerUser",
    "appUsage",
    "averageUsagePerUser",
    "dataTransfer",
    "webVisits",
    "iosVisits",
    "androidVisits",
  ];

  chartConfigs = {
    appVisits: {},
    uniqueVisits: {},
    averageVisitsPerUser: {},
    appUsage: {
      format: (x) => this.msToHours(x),
      formatTotal: (x) => this.formatUsageTime(x),
    },
    averageUsagePerUser: {
      format: (x) => this.msToHours(x),
      formatTotal: (x) => this.formatUsageTime(x),
    },
    dataTransfer: {
      format: (x) => x && this.toGB(x),
      formatTotal: (x) => x && `${this.toGB(x) || 0} GB`,
    },
    webVisits: {},
    iosVisits: {},
    androidVisits: {},
  };

  msToHours(ms) {
    return parseInt((ms || 0) / (1000 * 60 * 60));
  }

  toGB(bytes) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(3);
  }

  formatUsageTime(ms) {
    if (!ms) return ms;
    return ms > 1000 * 60 * 60
      ? `${parseInt(ms / (1000 * 60 * 60))} hours`
      : `${parseInt(ms / (1000 * 60))} mins`;
  }

  reloadTimer = null;
  triggerReload() {
    clearTimeout(this.reloadTimer);
    this.reloadTimer = setTimeout(() => {
      this.loadAnalytics();
    }, 300);
  }

  render() {
    const {
      props: { filter = {}, projects, data },
      state: { loading },
    } = this;

    const mergeFilter = (obj) => {
      this.props.setScreenState({ filter: { ...filter, ...obj } });
      this.triggerReload();
    };

    const [startDate, endDate] = filter?.dateRange || [];
    const setDateRange = (dateRange) => mergeFilter({ ...filter, dateRange });

    const selectedProject = projects?.find(
      (x) => x._id === filter?.projects?.[0]
    );

    return (
      <div className="userPanel">
        <SideNav activeSidebarItem="analytics" key="sidenav" />
        <div className="upMain analyticsScreen">
          <NavBar activeLink="/analytics" />

          <div className="analyticsHeader">
            <div className="analyticsTitle">Analytics Dashboard</div>
            <div className="analyticsFilters">
              <CustomSelect
                onChange={(option) => mergeFilter({ projects: [option.value] })}
                value={filter.projects?.[0] || ""}
                placeholder="All Project"
                options={projects?.map((x) => ({
                  value: x._id,
                  label: x.name,
                }))}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
                labelIcon={
                  <div className="labelIco">
                    <Avatar
                      user={{
                        firstName: "",
                        image: selectedProject?.image,
                      }}
                    />
                  </div>
                }
              />

              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              />
            </div>
          </div>

          <div className="upBody">
            {loading && !data?.analytics ? (
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loader large"></div>
              </div>
            ) : (
              <div className="upProjectList">
                {this.chartNames.map((item, index) => (
                  <Link
                    key={item}
                    className="upProjectItem"
                    to={_.kebabCase(item)}
                  >
                    <ChartBox
                      {...this.props}
                      chartName={item}
                      index={index}
                      key={item + "-" + data?.uid}
                    />
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export class ChartBox extends React.Component {
  chartConfigs = {
    appVisits: {},
    uniqueVisits: {},
    averageVisitsPerUser: {},
    appUsage: { formatTotal: (x) => this.formatUsageTime(x) },
    averageUsagePerUser: { formatTotal: (x) => this.formatUsageTime(x) },
    dataTransfer: {
      formatTotal: (x) => (x ? `${this.toGB(x) || 0} GB` : "..."),
    },
    webVisits: {},
    iosVisits: {},
    androidVisits: {},
  };

  formatUsageTime(ms) {
    if (!ms) return ms;
    return ms > 1000 * 60 * 60
      ? `${parseInt(ms / (1000 * 60 * 60))} hours`
      : `${parseInt(ms / (1000 * 60))} mins`;
  }

  formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  }

  toGB(bytes) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(3);
  }

  renderChart(lineData) {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "",
        },
      },
      scales: {
        x: {
          display: true,
          offset: true, // Adds space on each side of the x-axis items
          grid: {
            display: false, // Optional: remove grid lines on the x-axis
            borderWidth: 0,
          },
          border: {
            display: false, // Removes the x-axis border line
          },
          ticks: {
            color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
            font: {
              family: "Avenir",
              size: 12, // Optional: Set font size for x-axis labels
            },
          },
        },
        y: {
          display: true,
          grid: {
            display: false, // Removes the horizontal grid lines along the y-axis
            borderWidth: 0,
          },
          border: {
            display: false, // Removes the x-axis border line
          },
          ticks: {
            color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
            font: {
              family: "Avenir",
              size: 12, // Optional: Set font size for x-axis labels
            },
          },
          min: 0,
        },
      },
      hover: {
        mode: "nearest", // Ensures the hover mode is set to detect nearest points
        intersect: false, // Ensures it only triggers when directly over a point
      },
    };

    return (
      <div className="chartContainer">
        {lineData ? <Line options={options} data={lineData} /> : null}
      </div>
    );
  }

  render() {
    const {
      props: { chartName, data },
    } = this;
    const chartConfig = this.chartConfigs[chartName];

    const { totals, percentageChanges, lineDataSet } = data || {};
    const total = totals?.[chartName];
    const percentageChange = percentageChanges?.[chartName];
    const lineData = lineDataSet?.[chartName];

    return (
      <div>
        <div className="chartHead">
          <div className="chartTitle">{_.startCase(chartName)}</div>
          <div className="chartValueRow">
            <div className="chartAggregate">
              {(chartConfig?.formatTotal
                ? chartConfig.formatTotal(total)
                : total) || "..."}
            </div>
            <div
              className={
                "chartChangeLabel " +
                (percentageChange < 0
                  ? "negative"
                  : percentageChange > 0
                  ? "positive"
                  : "")
              }
            >
              {percentageChange === null || percentageChange === undefined
                ? "--"
                : (percentageChange > 0 ? "+" : "") +
                  _.round(percentageChange, 2).toString() +
                  "%"}
            </div>
          </div>
        </div>

        {this.renderChart(lineData)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  filter: state.vState[SCREEN_NAME].filter || {},
  data: state.vState[SCREEN_NAME].data || {},
  projects: state.vState.PROJECTS.projects,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const AnalyticsDashboardScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsDashboardScreenInner);

export default AnalyticsDashboardScreen;
