import _processStyle from "./processStyle";
const { processStyle } = _processStyle;

const defaultStyles = {
  container: {
    default: processStyle({
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    }),
  },
  text: {
    default: processStyle({
      fontSize: "13px",
      fontWeight: "normal",
    }),
  },
  image: {
    default: processStyle({ width: "100px", height: "100px" }),
  },
  input: {
    default: processStyle({}),
  },
  map: {
    default: processStyle({
      width: "100%",
      height: 300,
      paddingTop: "20px",
      paddingBottom: "20px",
    }),
  },
  media: {
    default: processStyle({
      width: "100%",
      height: 200,
      display: "flex",
      flexDirection: "column",
    }),
  },
};

export default defaultStyles;
