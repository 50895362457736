import React from "react";
import styleModule from "../../../../Modules/style/style-module";

class Media extends React.PureComponent {
  updateStaticText(x) {
    console.log(x);
  }

  render() {
    const element = this.props.element;

    let style = styleModule.getElementStyleData(element);

    return (
      <div
        style={{
          ...(style || {}),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Media
      </div>
    );
  }
}

export default Media;
