import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <div className="section">
    <div className="containers">
      <div className="wrapper">
        <div className="header">
          <div className="menu">
            <Link to="/" className="menuElement">
              Home
            </Link>
            <Link to="/pricing" className="menuElement">
              Pricing
            </Link>
            <Link to="showcase" className="menuElement">
              Showcase
            </Link>
          </div>
          <Link to="/" className="headLogo">
            AppWolo
          </Link>
          <div className="menu">
            <Link to="/help" className="menuElement">
              Help
            </Link>
            <Link to="/login" className="menuElement">
              Login
            </Link>
            <Link to="/signup" className="headerMain">
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
