import React from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import "../../Styles/landing-pages.css";
import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import Header from "./Header";
import Footer from "./Footer";

const HomeScreen = ({ isLoggedIn }) => {
  if (isLoggedIn) return <Navigate to="/dashboard" />;

  return (
    <>
      <Header />
      <div className="section headerSection">
        <div className="container">
          <div className="wrapper padding">
            <div className="rowItem">
              <div className="headerBox">
                <div className="heading tCenter">
                  Build any app
                  <br />
                  No code. <span>No Limits.</span>
                </div>
                <div className="multiPack tCenter">
                  Welcome to the World’s Most Powerful Visual App Builder
                  <br />
                  —So Easy, Anyone Can Use It.
                </div>
                <div className="headerAction">
                  <Link to="/signup?trial=1" className="mainButton">
                    Start 3-Day Trial
                  </Link>
                  <Link to="/help" className="secondaryButton">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="wrapper padding gap20">
            <div className="rowItem gap20 overflowOff">
              <div className="sliderUnit one">
                <div className="slideItemUnit">
                  <div className="appInitials">RS</div>
                  Rideshare App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">FD</div>
                  Food Delivery App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">EA</div>
                  E-commerce App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">SM</div>
                  Social Media App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">BA</div>
                  Booking App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">EP</div>
                  Event Planning App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">FT</div>
                  Finance Tracking App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">PM</div>
                  Project Management App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">TD</div>
                  To-Do List App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">RF</div>
                  Recipe Finder App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">NA</div>
                  News Aggregator App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">PA</div>
                  Portfolio App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">BA</div>
                  Blogging App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">LL</div>
                  Language Learning App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">FT</div>
                  Fitness Tracking App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">RE</div>
                  Real Estate App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">JB</div>
                  Job Board App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CS</div>
                  Customer Support App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">BA</div>
                  Broadcasting App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">AP</div>
                  Appointment App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">SA</div>
                  Survey App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">VC</div>
                  Virtual Classroom App
                </div>
                <div className="slideItemUnit end3">
                  <div className="appInitials">MA</div>
                  Meditation App
                </div>
                <div className="slideItemUnit end3">
                  <div className="appInitials">PA</div>
                  Podcast App
                </div>
                <div className="slideItemUnit end3">
                  <div className="appInitials">FA</div>
                  Forum App
                </div>
              </div>
              <div className="sliderUnit two">
                <div className="slideItemUnit end3">
                  <div className="appInitials">DW</div>
                  Digital Wallet App
                </div>
                <div className="slideItemUnit end3">
                  <div className="appInitials">HT</div>
                  Habit Tracker App
                </div>
                <div className="slideItemUnit end3">
                  <div className="appInitials">PG</div>
                  Photo Gallery App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">BD</div>
                  Directory App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">WA</div>
                  Weather App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">MS</div>
                  Music Streaming App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">AU</div>
                  Auction App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CA</div>
                  Content Curation App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">DA</div>
                  Donation App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">PA</div>
                  Pet Adoption App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">LB</div>
                  Library App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">TA</div>
                  Therapy App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">TP</div>
                  Travel Planning App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">AP</div>
                  Automation App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">OD</div>
                  On-Demand App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">SA</div>
                  Sports Score App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CA</div>
                  Community App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">ET</div>
                  Event Ticketing App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">RA</div>
                  Referral App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CF</div>
                  Crowdfunding App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CA</div>
                  Chat App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">CM</div>
                  CRM App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">ST</div>
                  Study Timer App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">JA</div>
                  Journal App
                </div>
                <div className="slideItemUnit">
                  <div className="appInitials">VC</div>
                  Video Broadcasting App
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="wrapper padding gap20 pad150">
            <div className="rowItem">
              <div className="featureBox">
                <div className="featureIconRow">
                  <div className="featureIconItem">
                    <img
                      className="featureIconIco"
                      src={require("../../Assets/img/landing-pages/web.png")}
                    />
                  </div>
                  <div className="featureIconItem">
                    <img
                      className="featureIconIco"
                      src={require("../../Assets/img/landing-pages/ios.png")}
                    />
                  </div>
                  <div className="featureIconItem">
                    <img
                      className="featureIconIco"
                      src={require("../../Assets/img/landing-pages/android.png")}
                    />
                  </div>
                </div>
                <div className="fTitle">
                  Build websites and native mobile apps
                </div>
                <div className="fDesc">
                  With our no-code builder, create apps for Web, iOS, and
                  Android—all customized to fit each platform’s needs. Reach any
                  audience, on any device, with ease and sophistication.
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="featureIconRow">
                  <div className="featureIconItem">
                    <img
                      className="featureIconIco"
                      src={require("../../Assets/img/landing-pages/create.png")}
                    />
                  </div>
                </div>
                <div className="fTitle">Unleash Your Creativity</div>
                <div className="fDesc">
                  Our platform is designed for creativity, enabling you to build
                  anything from scratch—all without writing a single line of
                  code. Your vision is limitless, and we provide the tools to
                  make it a reality.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section mfSection">
        <div className="container">
          <div className="wrapper padding gap20">
            <div className="rowItem">
              <div className="mainFeatureItem">
                <div className="mfiTitle lexend">6 Simple Elements</div>
                <div className="mfiSubline">
                  Our platform is easy to learn with just 6 simple building
                  blocks.
                  <br />
                  Say goodbye to complex, overwhelming options.
                </div>

                <div className="elementsBox">
                  <div className="elementItem">Container</div>
                  <div className="elementItem">Text</div>
                  <div className="elementItem">Image</div>
                  <div className="elementItem">Input</div>
                  <div className="elementItem">Map</div>
                  <div className="elementItem">Media</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pad50 bgWhite">
        <div className="container gap20">
          <div className="wrapper padding gap20 aCenter">
            <div className="rowItem">
              <div className="featureBox">
                <div className="ftBig">
                  <img
                    className="ftBigIco"
                    src={require("../../Assets/img/landing-pages/logic.png")}
                  />
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="fTitle">Easy Logic Builder</div>
                <div className="fDesc">
                  Our Easy Logic Builder empowers you to create any logic, no
                  matter how complex, with an intuitive, straightforward
                  approach.
                  <br />
                  <br />
                  Design workflows, set conditions, and build advanced
                  functionality effortlessly—without needing coding skills.{" "}
                  <br />
                  <br />
                  From simple tasks to intricate processes, our platform makes
                  complex logic accessible to everyone.
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper padding gap20 aCenter">
            <div className="rowItem">
              <div className="featureBox">
                <div className="ftBig">
                  <img
                    className="ftBigIco ftBigAdjust"
                    src={require("../../Assets/img/landing-pages/database.png")}
                  />
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="fTitle">Store Data Effortlessly</div>
                <div className="fDesc">
                  Our platform makes it easy to create and connect to databases,
                  empowering you to build powerful applications without hassle.
                  <br />
                  <br />
                  You can effortlessly store and manage your data, update
                  records, search for information, and link various data
                  sources.
                  <br />
                  <br />
                  It’s simple for anyone to keep their data organized and
                  accessible, making data management a breeze.
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper padding gap20 aCenter">
            <div className="rowItem">
              <div className="featureBox">
                <div className="ftBig">
                  <img
                    className="ftBigIco"
                    src={require("../../Assets/img/landing-pages/liveLocation.png")}
                  />
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="fTitle">Live Location Mapping</div>
                <div className="fDesc">
                  Our platform enables live location mapping, allowing users to
                  track and share their positions in real time. <br />
                  <br />
                  Users can easily integrate this functionality into their apps,
                  making it simple to create engaging location-based
                  experiences.
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper padding gap20 aCenter">
            <div className="rowItem">
              <div className="featureBox">
                <div className="ftBig">
                  <img
                    className="ftBigIco"
                    src={require("../../Assets/img/landing-pages/broadcast.png")}
                  />
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="fTitle">Video and Audio Broadcasting</div>
                <div className="fDesc">
                  With our platform, you can create audio and video broadcasting
                  apps with complete flexibility and customizability.
                  <br />
                  <br />
                  Whether you want to build a chat app for live interactions or
                  a streaming app for broadcasting content, the choice is
                  entirely yours.
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper padding gap20 aCenter">
            <div className="rowItem">
              <div className="featureBox">
                <div className="ftBig">
                  <div className="ftMarketing">
                    <div className="ftmItem">Send Email</div>
                    <div className="ftmItem">Send Text Message</div>
                    <div className="ftmItem">Send Push Notification</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className="featureBox">
                <div className="fTitle">Marketing & Communication</div>
                <div className="fDesc">
                  Send emails, text messages, push notifications, and more to
                  keep your users informed and engaged.
                  <br />
                  <br />
                  Our platform makes it easy to reach your audience instantly,
                  whether you want to share updates, reminders, or important
                  announcements.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pad50">
        <div className="container">
          <div className="wrapper padding gap20">
            <div className="rowItem">
              <div className="planBox">
                <div className="mfiTitle lexend">Agency Solutions</div>
                <div className="mfiSubline">
                  An agency account may benefit you, providing access to
                  advanced tools that streamline project management,
                  <br />
                  enhance collaboration, and help scale your services
                  efficiently.
                </div>

                <div className="wrapper gap20">
                  <div className="rowItem">
                    <div className="planItem">
                      <div className="featureIconRow">
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/web.png")}
                          />
                        </div>
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/ios.png")}
                          />
                        </div>
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/android.png")}
                          />
                        </div>
                      </div>
                      <div className="fTitle">Collaborate with Team</div>
                      <div className="fDesc">
                        Add team members to create and collaborate on apps,
                        making teamwork smooth and efficient. With shared access
                        to projects, everyone can contribute, track progress,
                        and refine ideas in real time.
                      </div>
                    </div>
                  </div>
                  <div className="rowItem">
                    <div className="planItem">
                      <div className="featureIconRow">
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/web.png")}
                          />
                        </div>
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/ios.png")}
                          />
                        </div>
                        <div className="featureIconItem">
                          <img
                            className="featureIconIco"
                            src={require("../../Assets/img/landing-pages/android.png")}
                          />
                        </div>
                      </div>
                      <div className="fTitle">Analytics Suite</div>
                      <div className="fDesc">
                        App analytics provides insights into user behavior,
                        helping you understand how your app is being used. By
                        tracking metrics like engagement and retention, you can
                        make data-driven decisions to improve user experience.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="planAction">
                  <Link
                    to="/pricing"
                    className="secondaryButton"
                    preventScrollReset
                  >
                    Compare Plans
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pad50">
        <div className="container">
          <div className="wrapper padding gap20">
            <div className="rowItem">
              <div className="ctaBox">
                <div className="wrapper gap20 aCenter">
                  <div className="rowItem">
                    <div className="ctaItem">
                      <div className="mfiTitle lexend tLeft">Get Started</div>
                      <div className="fDesc">
                        Get started today to turn your app ideas into reality.
                      </div>
                    </div>
                  </div>
                  <div className="ctaAction">
                    <Link
                      to="/pricing"
                      className="mainButton"
                      preventScrollReset={true}
                    >
                      Compare Plans
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
