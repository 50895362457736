import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import SideNav from "../Common/SideNav";
import withRouter from "../../Components/Wrapper/with-router";
import SearchInput from "../../Components/Input/SearchInput";
import { toast } from "react-toastify";
import UserPresence from "../Builder/UserPresence";
import Avatar from "../../Components/etc/Avatar";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import CustomSelect from "../../Components/etc/CustomSelect";
import DraggableList from "../../Components/etc/DraggableList";
import AddDatabasePopup from "./AddDatabasePopup";

const SCREEN_NAME = "DATABASES";

const DatabasesScreen = (props) => {
  const allDatabases = props.databases;

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filteredDatabases, setFilteredDatabases] = useState(null);

  const isAdmin = ["owner", "admin"].includes(props.team.role);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [allDatabases, filter]);

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { databases } = await api.get("v1/database");
      setLoading(false);
      props.setScreenState({ databases }, false, "BUILDER");
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error(e.message);
    }
  }, []);

  const applyFilter = () => {
    if (!filter) {
      setFilteredDatabases(allDatabases);
    } else {
      let nameRegex = filter.q ? new RegExp("^" + filter.q, "i") : null;
      let filtered = allDatabases?.filter((database) => {
        if (filter.type && database?.type !== filter.type) return false;

        if (filter.q && nameRegex) {
          let match = nameRegex.test(database.name);
          if (!match) return false;
        }

        return true;
      });
      setFilteredDatabases(filtered);
    }
  };

  return (
    <div className="userPanel">
      <SideNav activeSidebarItem="databases" key="sidenav" />
      <div className="upMain databasesScreen">
        <NavBar activeLink="/databases" />
        <div className="upHeader">
          <div className="upHeaderTop">
            <div className="upHeaderRow">
              <div className="upTitle">Databases</div>
              {isAdmin ? (
                <div
                  className="upAction"
                  onClick={() => setVisibleModal("addDatabase")}
                >
                  <span>+</span> Create Database
                </div>
              ) : null}
            </div>

            <div className="upTabs">
              {[
                { value: null, label: "All Databases" },
                { value: "local", label: "Local Databases" },
                { value: "remote", label: "Remote Databases" },
              ].map((item) => (
                <div
                  key={item.value}
                  className={
                    "upTabsItem " + (filter?.type == item.value ? "active" : "")
                  }
                  onClick={() =>
                    setFilter({ ...(filter || {}), type: item.value })
                  }
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>

          <div className="upHeaderBottom">
            <div className="projectSearch">
              <img
                className="projectSearchIco"
                src={require("../../Assets/img/user/search.png")}
              ></img>
              <SearchInput
                type="text"
                className="projectSearchInput"
                placeholder="Search here"
                value={filter?.q || ""}
                onChange={(q) => setFilter({ ...(filter || {}), q })}
              />
            </div>

            <UserPresence component={{ _id: props.team.user._id }} />
          </div>
        </div>

        <div className="upBody">
          <div className="upBodyInner">
            <div className="upProjectList">
              {filteredDatabases?.map((database) => (
                <div className="upProjectItem" key={database._id}>
                  <div className="upiHeader">
                    <div className="upiIco">
                      <Avatar user={{ firstName: database.type?.[0] }} />
                    </div>
                    <div className="upiMeta">
                      <div className="upiTitle">{database.name}</div>
                      <div className="upiSubline">{database.type}</div>
                    </div>
                  </div>

                  <div className="upiFooter">
                    <div className="upiFooterLeft">
                      <Link
                        key={database._id}
                        to={`/database/${database?.name}`}
                      >
                        <div className="openApp">Open</div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <AddDatabasePopup
          key={"database" + (visibleModal === "addDatabase")}
          visible={visibleModal === "addDatabase"}
          close={() => setVisibleModal(null)}
          callback={(err, database) => {
            if (!err && database) {
              load().then(() =>
                props.router.navigate(`/database/${database?.name}`)
              );
            }
          }}
          team={props.team}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  databases: state.vState.BUILDER.databases,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatabasesScreen));
