import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
const ImageAppearance = (props) => {
  const { value = {}, onChange, styles = {}, changeStyles } = props;

  const mergeStyles = (obj) => changeStyles({ ...styles, ...obj });

  return (
    <>
      <OptionItem optionItemLabel={"Appearance"}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div className="oibLabel">Width</div>
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    value={styles.width || ""}
                    onChange={(event) =>
                      mergeStyles({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div>
                <div className="oibLabel">Height</div>
                <div>
                  <div className="optionInputIconBox">
                    <img
                      className="optionInputIconBoxIco rotate90"
                      src={require("../../../../../Assets/img/options/container/widthIco.png")}
                      alt="height"
                    ></img>
                    <ControlledInput
                      type="text"
                      onChange={(event) =>
                        mergeStyles({ height: event.target.value })
                      }
                      className="optionInputIconBoxField"
                      placeholder="Auto"
                      value={styles.height || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

export const ImageTypeZoomResizeMode = (props) => {
  const { value = {}, onChange, styles = {}, changeStyles } = props;

  const mergeStyles = (obj) => changeStyles({ ...styles, ...obj });

  return (
    <div className="optionItemRow">
      {/* <div className="optionItemBox">
        <div>
          <div className="oibLabel">Type</div>
          <div>
            <CustomSelect
              onChange={(option) =>
                onChange({
                  imageDisplayMode: option.value,
                })
              }
              value={value.imageDisplayMode || "single"}
              options={[
                { value: "single", label: "Single" },
                { value: "gallery", label: "Gallery" },
              ]}
              placeholder={"Select"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="optionItemBox">
        <div className="oibLabel">Zoom</div>
        <div>
          <CustomSelect
            value={value.zoomboxOnPopup || "disable"}
            onChange={(option) =>
              onChange({
                zoomboxOnPopup: option.value !== "disable",
              })
            }
            options={[
              { value: "disable", label: "Disable" },
              { value: true, label: "Enable" },
            ]}
            placeholder={"Select"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>
      </div>

      <div className="optionItemBox">
        <div className="oibLabel">Resize Mode</div>
        <div>
          <CustomSelect
            value={styles.objectFit}
            onChange={(option) =>
              mergeStyles({
                objectFit: option.value,
              })
            }
            options={[
              { value: "cover", label: "Cover" },
              { value: "contain", label: "Contain" },
              { value: "stretch", label: "Stretch" },
              { value: "center", label: "Center" },
            ]}
            placeholder={"Select"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageAppearance;
