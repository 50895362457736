import React from "react";

const StyleHoverSelector = ({ value, onChange }) => {
  const handleConditionChange = (key, conditionValue) => {
    const updatedCondition = { ...value, [key]: conditionValue };
    onChange(updatedCondition);
  };

  return (
    <div
      className={`hoverStatus ${value?.hover ? "active" : ""}`}
      onClick={() => handleConditionChange("hover", !value?.hover)}
    >
      {/* <input
        type="checkbox"
        style={{ marginLeft: "10px" }}
        checked={value?.hover || false}
        onChange={(e) => handleConditionChange("hover", e.target.checked)}
      /> */}
      H
    </div>
  );
};

export default StyleHoverSelector;
