import React, { useState } from "react";
import _ from "lodash";

import ControlledInput from "../../../../../Components/Input/ControlledInput";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ColorButton from "../../../../../Components/Input/ColorButton";

const fontFamily = [
  "auto",
  "Montserrat",
  "cursive",
  "fantasy",
  "ingerit",
  "initial",
  "monospace",
  "none",
  "sans-serif",
  "serif",
  "unset",
];
const fontWeight = [
  "normal",
  "bold",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
];

const Appearance = (props) => {
  const [hover, setHover] = useState(false);

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  let currentFont = styles.fontFamily;
  let currentFontWeight = styles.fontWeight;
  let currentFontSize = styles.fontSize;
  let currentTextAlign = styles.textAlign || "left";

  const changeFont = (obj) => {
    currentFont = obj.value;
    mergeStyle({ fontFamily: currentFont ? currentFont : "" });
  };
  const changeFontWeight = (obj) => {
    currentFontWeight = obj.value;
    mergeStyle({
      fontWeight: currentFontWeight ? currentFontWeight : "",
    });
  };
  const changeFontSize = (val) => {
    currentFontSize = val;
    mergeStyle({ fontSize: currentFontSize ? currentFontSize : "" });
  };
  const changeTextAlighn = (val) => {
    mergeStyle({ textAlign: val || "" });
  };

  return (
    <OptionItem optionItemLabel="Font" key={hover}>
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          <div className="optionItemBox max90w">
            <div className="optionInputIconBox">
              <ControlledInput
                onChange={(event) => changeFontSize(event.target.value)}
                value={currentFontSize || ""}
                className="optionInputIconBoxField"
                placeholder="Size"
              />
            </div>
          </div>

          <div className="optionItemBox">
            <CustomSelect
              onChange={(option) => changeFont({ value: option.value })}
              value={currentFont || ""}
              options={fontFamily.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Font"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
              labelIcon={
                <img
                  className="optionInputIconBoxIco"
                  src={require("../../../../../Assets/img/options/text/font.png")}
                ></img>
              }
            />
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <CustomSelect
              onChange={(option) => changeFontWeight({ value: option.value })}
              value={currentFontWeight || ""}
              options={fontWeight.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Style"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>

          <div className="optionItemBox">
            <div className="textAlignUnit">
              {[
                {
                  value: "left",
                  label: "Left",
                  ico: require("../../../../../Assets/img/options/text/tLeft.png"),
                },
                {
                  value: "center",
                  label: "Center",
                  ico: require("../../../../../Assets/img/options/text/tCenter.png"),
                },
                {
                  value: "right",
                  label: "Right",
                  ico: require("../../../../../Assets/img/options/text/tRight.png"),
                },
              ].map((item) => (
                <div
                  key={item.value}
                  onClick={() => changeTextAlighn(item.value)}
                  className={
                    "taItem " +
                    (currentTextAlign === item.value ? "active" : "")
                  }
                >
                  <img className="taItemIco" src={item.ico}></img>
                </div>
              ))}
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Colors</div>
      </div>

      <div className="optionItemBody revealOffset colorUnitRow">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.color || "#000000"}
                onChange={(val) => mergeStyle({ color: val })}
              />
              <div className="optionInputIconBoxText">Text Color</div>
            </div>
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.backgroundColor || "#FFFFFF"}
                onChange={(val) => mergeStyle({ backgroundColor: val })}
              />
              <div className="optionInputIconBoxText">Background</div>
            </div>
          </div>
        </div>
      </div>
    </OptionItem>
  );
};

export const AppearanceForInput = (props) => {
  const [hover, setHover] = useState(false);

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  let currentFont = styles.fontFamily;
  let currentFontWeight = styles.fontWeight;
  let currentFontSize = styles.fontSize;
  let currentTextAlign = styles.textAlign || "left";

  const changeFont = (obj) => {
    currentFont = obj.value;
    mergeStyle({ fontFamily: currentFont ? currentFont : "" });
  };
  const changeFontWeight = (obj) => {
    currentFontWeight = obj.value;
    mergeStyle({
      fontWeight: currentFontWeight ? currentFontWeight : "",
    });
  };
  const changeFontSize = (val) => {
    currentFontSize = val;
    mergeStyle({ fontSize: currentFontSize ? currentFontSize : "" });
  };
  const changeTextAlighn = (val) => {
    mergeStyle({ textAlign: val || "" });
  };

  return (
    <>
      <div className="optionItemRow">
        <div className="optionItemBox max90w">
          <div className="optionInputIconBox">
            <ControlledInput
              onChange={(event) => changeFontSize(event.target.value)}
              value={currentFontSize || ""}
              className="optionInputIconBoxField"
              placeholder="Size"
            />
          </div>
        </div>

        <div className="optionItemBox">
          <CustomSelect
            onChange={(option) => changeFont({ value: option.value })}
            value={currentFont || ""}
            options={fontFamily.map((x) => ({
              value: x,
              label: _.startCase(x),
            }))}
            placeholder={"Font"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
            labelIcon={
              <img
                className="optionInputIconBoxIco"
                src={require("../../../../../Assets/img/options/text/font.png")}
              ></img>
            }
          />
        </div>
      </div>

      <div className="optionItemRow">
        <div className="optionItemBox">
          <CustomSelect
            onChange={(option) => changeFontWeight({ value: option.value })}
            value={currentFontWeight || ""}
            options={fontWeight.map((x) => ({
              value: x,
              label: _.startCase(x),
            }))}
            placeholder={"Style"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>

        <div className="optionItemBox">
          <div className="textAlignUnit">
            {[
              {
                value: "left",
                label: "Left",
                ico: require("../../../../../Assets/img/options/text/tLeft.png"),
              },
              {
                value: "center",
                label: "Center",
                ico: require("../../../../../Assets/img/options/text/tCenter.png"),
              },
              {
                value: "right",
                label: "Right",
                ico: require("../../../../../Assets/img/options/text/tRight.png"),
              },
            ].map((item) => (
              <div
                key={item.value}
                onClick={() => changeTextAlighn(item.value)}
                className={
                  "taItem " + (currentTextAlign === item.value ? "active" : "")
                }
              >
                <img className="taItemIco" src={item.ico}></img>
              </div>
            ))}
          </div>
        </div>

        <div></div>
      </div>

      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Colors</div>
      </div>

      <div className="optionItemBody revealOffset colorUnitRow">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.color || "#000000"}
                onChange={(val) => mergeStyle({ color: val })}
              />
              <div className="optionInputIconBoxText">Text Color</div>
            </div>
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles["--placeholder-color"] || "gray"}
                onChange={(val) => mergeStyle({ ["--placeholder-color"]: val })}
              />
              <div className="optionInputIconBoxText">Placeholder</div>
            </div>
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.backgroundColor || "#FFFFFF"}
                onChange={(val) => mergeStyle({ backgroundColor: val })}
              />
              <div className="optionInputIconBoxText">Background</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appearance;
