import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CustomSelect from "../../../Components/etc/CustomSelect";
import PActions from "../../../Stores/redux/Persisted/Actions";
import UnpActions from "../../../Stores/redux/Unpersisted/Actions";
import { store } from "../../../store";

const DatabaseSelector = (props) => {
  const [selectorId, setSelectorId] = useState(Math.random());

  return (
    <div className="optionConditionTableSelectorHead">
      <div className="optionConditionTableSelectorHeadSubline">Data Source</div>
      <CustomSelect
        classNames={{
          head: "optionConditionTableSelectorHeadSelect",
          label: "optionConditionTableSelectorHeadSelectLabel",
          chevron: "optionConditionTableSelectorHeadSelectIco",
        }}
        placeholder={"Select"}
        {...getDatabaseSelectorOptions({ ...props, selectorId })}
      />
    </div>
  );
};

var selectorIds = {};

export const getDatabaseSelectorOptions = (props) => {
  const databases = props.databases || props.defaultDatabases || [];
  const localDatabases = databases.filter((x) => x.type === "local");
  const remoteDatabases = databases.filter((x) => x.type === "remote");

  let options = [
    {
      label: "Local Database",
      options: localDatabases.map((x) => ({
        valueObj: { dbId: x._id },
        label: x.name,
        options: x.tables?.map((table) => ({
          value: `${x._id}-${table?._id}`,
          valueObj: { dbId: x._id, tableId: table?._id },
          label: table?.name,
        })),
      })),
    },
    {
      label: "Remote Database",
      options: remoteDatabases.map((x) => ({
        valueObj: { dbId: x._id },
        label: x.name,
        options: x.tables?.map((table) => ({
          value: `${x._id}-${table?._id}`,
          valueObj: { dbId: x._id, tableId: table?._id },
          label: table?.name,
        })),
      })),
    },
  ];

  if (props.externalApi || props.webrtcStreams) {
    options.push({
      label: "Other",
      options: [
        props.externalApi && {
          value: "externalApi-",
          valueObj: { dbId: "externalApi", tableId: "" },
          label: "External API",
        },
        props.externalApi && {
          value: "staticValues-",
          valueObj: { dbId: "staticValues", tableId: "" },
          label: "Comma Separated Value",
        },
        props.webrtcStreams && {
          value: "webrtcStreams-",
          valueObj: { dbId: "webrtcStreams", tableId: "" },
          label: "Webrtc Streams",
        },
      ].filter((x) => x),
    });
  }

  options = options.filter((x) => !x.options || x.options.length);

  let output = {
    options,
    value: `${props.value?.dbId || ""}-${props.value?.tableId || ""}`,
    onChange: (option) => {
      props.onChange(option.valueObj);

      store.dispatch(
        PActions.setPScreenState("BUILDER", {
          lastSelectedDatabaseData: option.valueObj,
        })
      );
    },
  };

  const selectorId = "s" + props.selectorId;

  if (!selectorIds[selectorId]) {
    const { value, onChange, options } = output;

    if (databases?.length && (!value || value === "-")) {
      const lastSelectedDatabaseData =
        store.getState().pState.BUILDER?.lastSelectedDatabaseData;

      if (lastSelectedDatabaseData) {
        let matched = false;

        const matchValue = (option) => {
          if (
            option.valueObj?.dbId === lastSelectedDatabaseData.dbId &&
            option.valueObj?.tableId === lastSelectedDatabaseData.tableId
          ) {
            matched = option;
          }
        };

        const matchOption = (option) => {
          if (option.options) {
            matchOptions(option.options);
          } else {
            matchValue(option);
          }
        };

        const matchOptions = (options) => {
          for (let i = 0; i < options?.length && !matched; i++) {
            const option = options[i];
            matchOption(option);
          }
        };

        matchOptions(options);

        if (matched) {
          setTimeout(() => {
            onChange(matched);
          }, 0);
          selectorIds[selectorId] = true;
        }
      }
    }
  }

  return output;
};

const mapStateToProps = (state) => ({
  defaultDatabases: state.vState.BUILDER.databases,
  lastSelectedDatabaseData: state.vState.BUILDER?.lastSelectedDatabaseData,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseSelector);
