import { Component } from "react";
import { toast } from "react-toastify";
import api from "../../../Services/Api/api";

class ProjectDetailsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: props.project || {},
      toggleables: {},
      loadingObj: {},
    };
  }

  setLoading = (key, value) => {
    this.setState((prevState) => ({
      loadingObj: { ...prevState.loadingObj, [key]: value },
    }));
  };

  setToggleables = (key, value) => {
    this.setState((prevState) => ({
      toggleables: { ...prevState.toggleables, [key]: value },
    }));
  };

  submitEdit = async (updatedProject) => {
    try {
      await api.put("v1/project", {
        _id: this.state.project?._id,
        ...updatedProject,
      });
      this.props.reload();
    } catch (e) {
      throw e;
    }
  };

  submit = async (obj, key = "any", opt = {}) => {
    try {
      if (this.state.loadingObj[key]) return;
      this.setLoading(key, true);
      await this.submitEdit(obj);
      this.setLoading(key, false);
      if (opt.onSuccess) opt.onSuccess();
    } catch (e) {
      this.setLoading(key, false);
      this.setState({ project: this.props.project || {} });
      toast.error(e.message);
      if (opt.onFail) opt.onFail();
    }
  };

  deleteProject = async () => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this project? Confirm by typing the project name"
      );
      if (typed && typed === this.state.project.name) {
        await api.delete(`v1/project/${this.state.project._id}`);

        if (this.props.onDelete) this.props.onDelete(null, this.state.project);
        else this.props.router.navigate("/projects");
      } else if (typed !== null) {
        window.alert("Name mismatched");
        this.deleteProject();
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  formatDate(date) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(date).toLocaleString("en-US", options).toLowerCase();
  }

  sleep(ms) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, ms)
    );
  }
}

export default ProjectDetailsBase;
