import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import { Form, Button, Table, Container } from "react-bootstrap";
import { toast } from "react-toastify";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";

export const AddComponentPopup = (props) => {
  const [form, setForm] = useState({ ...(props.component || {}) });

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const content = (
    <div>
      <div className="errormsg">{props.formError}</div>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          required
          value={form?.name || ""}
          onChange={(e) => {
            updateForm({ name: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter Description"
          value={form?.description || ""}
          onChange={(e) => {
            updateForm({ description: e.target.value });
          }}
        />
      </Form.Group>

      {props.loading ? <div>Loading...</div> : null}
      {/* <Button disabled={props.loading} type="submit">
        Create Component
      </Button>{" "}
      <Button type="button" onClick={() => props.setPopup(false)}>
        Cancel
      </Button> */}
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Create New Screen",
        visible: true,
        handleClose: () => props.setPopup(false),
        disableBtns: props.loading,
        submitBtnText: props.loading
          ? "Loading"
          : props.submitBtnText || "Create Screen",
        onSubmit: (e) => props.handleSubmit(e, form),
      }}
    >
      {content}
    </AppModal>
  );
};

const AddComponent = (props) => {
  const [popup, setPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const appendComponent = (component) => {
    props.setComponents(update(props.components, { $push: [component] }));
  };

  const handleSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      const fileRes = form.image
        ? await api.media("v1/file", { file: form.image })
        : null;

      const res = await api
        .post("v1/component", {
          name: form.name,
          description: form.description,
          image: fileRes?.file._id,
          project: props.projectId,
        })
        .catch(async (e) => {
          if (fileRes?.file?._id)
            await api
              .delete(`v1/file/${fileRes?.file._id}`)
              .catch(console.error);
          throw e;
        });
      setLoading(false);
      setPopup(false);

      appendComponent(res.component);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <div>
      <div>
        <div onClick={() => setPopup(true)}>Add Screen</div>
      </div>

      {popup ? (
        <AddComponentPopup
          setPopup={setPopup}
          handleSubmit={handleSubmit}
          formError={formError}
          loading={loading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const ListComponent = (props) => {
  return (
    <div>
      {!props.components ? (
        <div className="noList"></div>
      ) : !props.components?.length ? (
        <div className="noList">Currently you have no screens</div>
      ) : (
        <div style={{ display: "flex", gap: "10px" }}>
          {props.components.map((component) => (
            <div className="box" key={component._id}>
              <div>{component.name}</div>
              <div>{component.description}</div>
              <div>
                <Link key={component._id} to={`/canvas/${component._id}`}>
                  <button variant="success">Open</button>
                </Link>
                <button onClick={() => props.edit(component)} variant="primary">
                  Edit
                </button>
                <button
                  onClick={() => props.delete(component)}
                  variant="danger"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Screens = (props) => {
  const [components, setComponents] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const projectId = props.projectId;

  const fetchComponents = useCallback(async () => {
    try {
      const { components } = await api.get("v1/component", {
        filter: JSON.stringify({
          where: { project: projectId },
        }),
      });
      setComponents(components);
    } catch (e) {
      console.error(e.message);
    }
  }, [projectId]);

  useEffect(() => {
    fetchComponents();
  }, [fetchComponents]);

  const deleteComponent = async (component) => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this screen ? Confirm by typing the screeen name"
      );
      if (typed && typed === component.name) {
        await api.delete(`v1/component/${component._id}`);
        await fetchComponents();
      } else if (typed !== null) {
        window.alert("Name mismatched");
        deleteComponent(component);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleEditSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      let image = form.image;

      if (form.newImage && form.image) {
        const fileRes = await api.media("v1/file", { file: form.image });
        image = fileRes?.file?._id;
      }

      await api
        .put("v1/component", {
          _id: form._id,
          name: form.name,
          description: form.description,
          image,
        })
        .catch(async (e) => {
          if (form.newImage && image) {
            api.delete(`v1/file/${image}`).catch(console.error);
          }
          throw e;
        });
      setLoading(false);
      setEditModal(null);

      fetchComponents();
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <>
      <div>
        <div className="flex sb">
          <div>Screens</div>
          <AddComponent
            components={components}
            setComponents={setComponents}
            projectId={projectId}
          />
        </div>
        <ListComponent
          components={components}
          setComponents={setComponents}
          edit={(x) => setEditModal(x)}
          delete={deleteComponent}
        />
      </div>

      {editModal ? (
        <AddComponentPopup
          setPopup={setEditModal}
          handleSubmit={handleEditSubmit}
          formError={formError}
          loading={loading}
          component={editModal}
          title="Edit Screen"
          submitBtnText="Submit"
          projectId={projectId}
        />
      ) : null}
    </>
  );
};

export default Screens;
