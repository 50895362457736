import React, { useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";
import { DatabaseSelector } from "./DatabaseOperations_v2/DatabaseDataSelector";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

export class OnlineDeviceLoggerInner extends React.Component {
  initialState = {
    loading: false,
    error: null,
    onlineDevicelogger: this.props.project?.data?.onlineDevicelogger || {},
  };

  state = { ...this.initialState };

  load() {
    this.setState({
      rtclogger: this.props.project?.data?.onlineDevicelogger || {},
    });
  }

  async onSubmit() {
    try {
      if (this.state.loading) return;

      const onlineDevicelogger = this.state.onlineDevicelogger;

      if (!onlineDevicelogger?.tableId) throw new Error("Select one table");
      if (!onlineDevicelogger?.dbId) throw new Error("Select one database");

      const selectedTable = this.props.databases
        ?.find(
          (x) => x._id?.toString() === onlineDevicelogger?.dbId?.toString()
        )
        ?.tables?.find(
          (x) => x._id?.toString() === onlineDevicelogger?.tableId?.toString()
        );

      const existingColumnNames = selectedTable?.columns?.map((x) => x.name);

      const columnsToBeAdded = [
        "deviceId",
        "dataType",
        "updatedAt",
        "status",
      ].filter((x) => !existingColumnNames?.includes(x));

      this.setState({ loading: true, error: null });

      for (let i = 0; i < columnsToBeAdded.length; i++) {
        const column = columnsToBeAdded[i];
        await api.post(`v1/database/${onlineDevicelogger.dbId}/column`, {
          tableName: selectedTable?.name,
          columnName: column,
        });
      }

      await api.put("v1/project", {
        _id: this.props.project?._id,
        data: {
          ...(this.props.project?.data || {}),
          onlineDevicelogger: this.state.onlineDevicelogger,
        },
      });
      if (this.props.onSuccess) this.props.onSuccess();

      this.setState({ loading: false, visible: false });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  render() {
    const value = this.state.onlineDevicelogger;
    const isSet = this.props.project?.data?.onlineDevicelogger?.tableId;

    return (
      <div className="borderItem">
        <div className="mLabel">Online Device Logger</div>
        <div className="publishRow">
          <div className="mValue">
            Online Device Logger
            {isSet ? " is configured" : " not setup"}
          </div>
          <div
            className="defaultButton mtm15"
            onClick={() => {
              this.load();
              this.setState({ visible: !this.state.visible });
            }}
          >
            {isSet ? "View" : "Link"}
          </div>
        </div>

        <div
          className="publishDetails"
          style={{ display: this.state.visible ? "flex" : "none" }}
        >
          <div className="pbRow">
            <div className="pbItem">
              <div className="pbLabel">Database Table</div>
              <DatabaseSelector
                {...this.props}
                value={value}
                onChange={(obj) =>
                  this.setState({ onlineDevicelogger: { ...value, ...obj } })
                }
              />
            </div>
          </div>

          {this.state.error ? (
            <div className="errormsg">{this.state.error}</div>
          ) : null}
          <div className="minActionRow">
            <div className="minButton" onClick={this.onSubmit.bind(this)}>
              {this.state.loading ? "Processing" : "Submit"}
            </div>
            <div
              className="minLightButton"
              onClick={() => {
                this.load();
                this.setState({ visible: !this.state.visible });
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const OnlineDeviceLogger = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineDeviceLoggerInner);

export const OnlineDeviceLoggerModalInner = (props) => {
  const onlineDevicelogger = useRef(null);
  return (
    <AppModal
      {...{
        title: "Online Device Logger",
        visible: props.visible,
        handleClose: () => props.close(),
        onSubmit: () => onlineDevicelogger.current.onSubmit(),
      }}
    >
      {props.visible ? (
        <OnlineDeviceLoggerInner ref={onlineDevicelogger} {...props} />
      ) : null}
    </AppModal>
  );
};

export const OnlineDeviceLoggerModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineDeviceLoggerModalInner);

export class ShowOnlineDeviceLoggerBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <button onClick={() => this.setState({ visible: true })}>
          Online Device Logger
        </button>
        <OnlineDeviceLoggerModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible: this.state.visible,
          }}
        />
      </>
    );
  }
}
