import axios from "axios";
import config from "../../Config";
import { getSocket } from "../../Providers/socket";
import { store } from "../../store";
import authModule from "../../Modules/auth/auth-module";
import { Encryption } from "../../appxolo-engine/modules/apiEncryption/frontend";

let API_URL = config.apiUrl;

const getErMsg = (error) => {
  let erroObj = (error && error.response && error.response.data) || {};
  console.log(error, erroObj);

  return erroObj.code === 500 || !(erroObj.message || error.message)
    ? "Something went wrong"
    : erroObj.message || error.message;
};

const getErCode = (error) => {
  let erroObj = (error && error.response && error.response.data) || {};

  return erroObj.code;
};

const catchError = (error, uri, opt) => {
  error.message = getErMsg(error);
  error.code = getErCode(error);
  console.warn(
    `Error in ${opt._method}:${API_URL + uri} status: ${
      error.response && error.response.status
    } message: ${getErMsg(error)}`
  );
  switch (error.response && error.response.status) {
    case 401:
      !opt?.noRedirect && logoutUser();
      break;
    default:
      break;
  }
  throw error;
};

const processRes = async (response, uri, opt) => {
  await processHeader(response.headers);
  return decryptPayload(response.data);
};

const api = {};

const encryption = new Encryption({ api, config });

api.download = (link) => {
  window.location.href = API_URL + link;
};

api.get = async (uri, params, option = {}) => {
  const opt = { ...option, _method: "GET" };
  const headers = await getHeaders(opt);

  return axios
    .get(option.fullUrl ? uri : API_URL + uri, {
      headers,
      params: await encryption.encrypt(params),
    })
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.post = async (uri, payload, option = {}) => {
  const opt = { ...option, _method: "POST" };
  const headers = await getHeaders(opt);

  return axios
    .post(API_URL + uri, await encryption.encrypt(payload), {
      headers,
    })
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.put = async (uri, payload, option = {}) => {
  const opt = { ...option, _method: "PUT" };
  const headers = await getHeaders(opt);

  return axios
    .put(API_URL + uri, await encryption.encrypt(payload), {
      headers,
    })
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.patch = async (uri, payload, option = {}) => {
  const opt = { ...option, _method: "PATCH" };
  const headers = await getHeaders(opt);

  return axios
    .patch(API_URL + uri, await encryption.encrypt(payload), {
      headers,
    })
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.delete = async (uri, payload, option = {}) => {
  const opt = { ...option, _method: "DELETE" };
  const headers = await getHeaders(opt);

  return axios
    .delete(API_URL + uri, {
      headers,
      data: await encryption.encrypt(payload),
    })
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.formData = async (uri, payload = {}, option = {}) => {
  const { method = "post", cb } = option;
  const opt = { ...option, _method: `${method}_FORMDATA`.toUpperCase() };

  if (!["post", "put"].includes(method)) {
    throw new Error("Unsupported method");
  }

  let headers = await getHeaders(opt);
  headers = {
    ...headers,
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };

  let axiosConfig = {
    headers,
    onUploadProgress: function (progressEvent) {
      if (!cb) return;

      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      cb({ progress: percentCompleted });
    },
  };

  let formData = new FormData();

  Object.keys(payload).map((key) => {
    let value = payload[key];

    value =
      typeof value === "object" && ["photo", "file"].indexOf(key) === -1
        ? JSON.stringify(value)
        : value;

    formData.append(key, value);
    return value;
  });

  return axios[method](`${API_URL}${uri}`, formData, axiosConfig)
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.media = async (uri = "v1/file", data = {}, option = {}) => {
  const { method = "post", cb } = option;
  const opt = { ...option, _method: `${method}_FORMDATA`.toUpperCase() };

  if (!["post", "put"].includes(method)) {
    throw new Error("Unsupported method");
  }

  let headers = await getHeaders(opt);
  headers = {
    ...headers,
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };

  let params = option.params || {};
  data.fileType && (params.fileType = data.fileType);
  data.public && (params.public = data.public);
  data.metaData && (params.metaData = data.metaData);
  params.uploadedFrom = "builder";

  const projectId = store.getState().vState.BUILDER.project?._id;
  projectId && (params.projectId = projectId);

  let axiosConfig = {
    headers,
    params: await encryption.encrypt(params),
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      cb && cb(percentCompleted);
    },
  };

  var endpoint = API_URL + uri;

  let file = data.file || {};
  let formData = new FormData();

  if (data.file) {
    formData.append("file", file);
  } else {
    throw new Error("Missing valid required input");
  }

  return axios[method](endpoint, formData, axiosConfig)
    .then((res) => processRes(res, uri, opt))
    .catch((error) => catchError(error, uri, opt));
};

api.socket = async (uri, payload, options = {}) => {
  let socket = getSocket();
  if (!socket) throw new Error("Connection Error!");

  let headers = await getHeaders({ ...options, _method: "socket" });
  const socketPayload = await encryption.encrypt({
    headers,
    body: payload,
  });

  return new Promise((resolve, reject) => {
    socket.emit(uri, socketPayload, (err, data) => {
      if (err) {
        console.warn("Error in socket request: ", { uri, err });
        if (typeof err === "string") return reject(new Error(err));
        else if (err?.message) return reject(err);
        else return reject(new Error("Something went wrong"));
      }

      return resolve(decryptPayload(data));
    });
  });
};

api.getFileLink = (id, opt = {}) => {
  const {
    height = 0,
    width = 0,
    video = false,
    thumbnail = false,
    params,
  } = opt || {};
  if (!id) return "";

  id = id._id || id;

  if (video) {
    return `${API_URL}v1/file/${id}`;
  }

  let query = `${height ? `height=${height || 0}` : ""}${
    width ? `&width=${width || 0}` : ``
  }${thumbnail ? "&thumbnail=1" : ""}`;

  if (params) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key];
        query = query ? query + "&" : query;
        query = query + `${key}=${value}`;
      }
    }
  }

  return `${API_URL}v1/file/${id}${query ? `?${query}` : ""}`;
};

api.getApiUrl = () => API_URL;

const processHeader = async (responHeaders = {}) => {
  return;
};

export const getHeaders = async (opt = {}) => {
  if (process.env.NODE_ENV === "development") {
    await sleep(1000);
  }

  let result = {};

  let token = store?.getState()?.pState?.AUTH?.token;
  if (token) result = { ...result, authorization: "Bearer " + token };

  let team = store?.getState()?.pState?.AUTH?.team;
  if (team?.user?._id)
    result = {
      ...result,
      "x-team-id": team.user._id,
      "x-team-role": team.role,
    };

  result = { ...result, ...(opt.headers || {}) };

  return result;
};

export const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

const logoutUser = () => {
  authModule.logout();
};

const sleep = (ms) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );

const decryptPayload = (data) => {
  if (!data) return data;

  let responseData = { ...data };

  if (responseData.e && responseData.i) {
    const decryptedStr = encryption.decrypt(
      responseData.e,
      responseData.i,
      config.encryption.apiSecret
    );

    const decrypted = JSON.parse(decryptedStr);

    delete responseData.i;
    delete responseData.e;

    responseData = { ...responseData, ...decrypted };
  }

  return responseData;
};

export default api;
