import React from "react";
import update from "immutability-helper";

import SearchIcons from "../../Builder/Properties/Common/ImageSources/SearchIcons";
import api from "../../../Services/Api/api";

class FileManagerModal extends React.Component {
  state = {
    uploadings: [],
    ts: 0,
    resetFileInput: 0,
    oldFiles: null,
    oldImageFiles: null,
    oldVideoFiles: null,
    oldAudioFiles: null,
    iconSearchText: "",
  };

  maxLength = Infinity;
  uploadProgress = {};

  componentDidMount() {
    this.loadOldFiles();
  }

  async loadOldFiles() {
    try {
      const { files } = await api.get("v1/file", {
        projectId: this.props.project?._id,
        uploadedFrom: "builder",
        file_type: { $ne: "androidBuild" },
      });
      console.log({ files });

      this.setState({ oldFiles: files });
    } catch (error) {
      console.error(error);
    }
  }

  appendStaticUrls = (urls) => {
    const staticUrls = this.props.value?.valueObj?.staticUrls || [];
    let updated = update(staticUrls, { $push: urls });

    if (updated.length > this.maxLength) {
      const elementsToRemove = updated.length - this.maxLength;
      updated.splice(0, elementsToRemove);
    }
    this.props.onChange(updated);
  };

  uploadHandler = async (event) => {
    try {
      let files = event.target.files;
      let localUrls = [];

      for (let i = 0; i < files.length && i < this.maxLength; i++) {
        const file = files[i];
        const localUrl = URL.createObjectURL(file);
        localUrls.push({ url: localUrl, uid: Math.random(), file });
      }

      let updatedUploadings = [...this.state.uploadings, ...localUrls];
      this.setState({
        uploadings: updatedUploadings,
        resetFileInput: Date.now(),
      });

      const promises = updatedUploadings.map(async (uploading) => {
        const fileRes = await api.media(
          "v1/file",
          {
            file: uploading.file,
          },
          {
            cb: (x) => {
              this.uploadProgress[uploading.uid] = x;
              this.setState({ ts: Date.now() });
            },
          }
        );
        const staticUrl = api.getFileLink(fileRes?.file);
        return staticUrl;
      });

      const staticUrls = await Promise.all(promises);
      this.setState({
        uploadings: this.state.uploadings.filter(
          (x) => !updatedUploadings.find((u) => u.uid === x.uid)
        ),
      });
      this.appendStaticUrls(staticUrls);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  content() {
    const {
      props: {
        value: staticUrls = [],
        onChange,
        styleData,
        acceptFileMime = "image/*",
      },
      state: { uploadings, iconSearchText, oldFiles },
    } = this;

    return (
      <div className="optionDatabaseTextBox">
        <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
          <label className="optionDatabaseTextBoxFooterUpload">
            <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
            <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
            <input
              key={this.state.resetFileInput}
              type="file"
              hidden
              onChange={this.uploadHandler.bind(this)}
              multiple
              accept={acceptFileMime}
            />
          </label>

          {JSON.stringify(staticUrls)}

          {staticUrls?.map((src, i) => (
            <div key={src + i} className="uploadPhotoItem">
              <img className="uploadPhoto" src={src} alt="uploadedPhoto" />
              <div
                className="deletePhoto"
                onClick={() =>
                  onChange(update(staticUrls, { $splice: [[i, 1]] }))
                }
              >
                <div className="deletePhotoLine one"></div>
                <div className="deletePhotoLine two"></div>
              </div>
            </div>
          ))}

          {uploadings.map((uploading) => (
            <div className="uploadPhotoItem" key={uploading.uid}>
              <img
                className="uploadPhoto"
                src={uploading.url}
                alt="uploadedPhoto"
              />
              <div
                style={{
                  background: "#fff6",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  height: 100 - (this.uploadProgress[uploading.uid] || 0) + "%",
                }}
              ></div>
              <div
                className="deletePhoto"
                onClick={() => {
                  this.setState({
                    uploadings: this.uploadings.filter(
                      (x) => x.uid !== uploading.uid
                    ),
                  });
                }}
              >
                <div className="deletePhotoLine one"></div>
                <div className="deletePhotoLine two"></div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="optionDatabaseTextBoxFooter"
          style={iconSearchText ? { display: "none" } : null}
        >
          {oldFiles?.map((file) => (
            <div
              key={file._id}
              className="uploadPhotoItem"
              onClick={() =>
                this.appendStaticUrls([
                  api.getFileLink(file, { height: 0, width: 0 }),
                ])
              }
            >
              <img
                className="uploadPhoto"
                src={api.getFileLink(file)}
                alt="uploadedPhoto"
              />
            </div>
          ))}
        </div>

        <SearchIcons
          iconChooseHandler={(urlObj, src) => {
            let source = src || urlObj?.images?.["128"];
            if (source) this.appendStaticUrls([source]);
          }}
          uploadHandler={this.uploadHandler.bind(this)}
          iconSize={styleData?.width?.replace("px", "")}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="calculationOutside">
        <div className="calculationWindow" ref={this.scrollRef}>
          <div className="calcHead">
            <div className="calcHeadAction" onClick={() => this.props.close()}>
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            File
          </div>
          {this.content()}
        </div>
      </div>
    );
  }
}

export default FileManagerModal;
