import React from "react";
import { toast } from "react-toastify";

import ProjectDetailsBase from "./ProjectDetailsBase";
import SearchInput from "../../../Components/Input/SearchInput";
import api from "../../../Services/Api/api";
import Avatar from "../../../Components/etc/Avatar";

class ProjectMembers extends ProjectDetailsBase {
  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setLoading("memebers", true);
      const { members: teamMembers } = await api.get(`v1/user/members`);

      let members = [];
      for (let i = 0; i < teamMembers.length; i++) {
        const teamMember = teamMembers[i];
        const projects = teamMember.projects;

        if (
          teamMember.role === "member" &&
          projects.find((x) => x.project?._id === this.state.project._id)
        )
          members.push(teamMember.user);
      }

      this.setState({ members, teamMembers }, () =>
        this.handleSearchUser(this.state.q)
      );
      this.setLoading("memebers", false);
    } catch (e) {
      this.setLoading("memebers", false);
      console.warn(e);
    }
  }

  removeUser = async (userId) => {
    this.setLoading("permissions", userId);
    try {
      const { members: allMembers } = await api.get(`v1/user/members`);

      let updatedMembers = allMembers?.map((x) => {
        if (x.user._id !== userId) return x;

        let projects = x.projects?.filter(
          (x) => x.project?._id !== this.state.project?._id
        );

        return { ...x, projects };
      });

      const members = updatedMembers.map((x) => ({
        ...x,
        user: x.user._id,
        projects: x.projects
          ?.filter((x) => x.project)
          ?.map((x) => ({ ...x, project: x.project?._id })),
      }));

      await api.patch(`v1/user/members`, {
        members,
      });

      this.setState({
        members: this.state.members.filter((x) => x._id !== userId),
      });

      this.load();
    } catch (e) {
      toast.error(e.message);
      console.warn(e);
    }
    this.setLoading("permissions", false);
  };

  addUser = async (user) => {
    try {
      if (this.state.loadingObj.permissions) return;
      this.setLoading("permissions", user._id);

      const { members: allMembers } = await api.get(`v1/user/members`);

      let updatedMembers = allMembers?.map((x) => {
        if (x.user._id !== user._id) return x;

        let projects = x.projects || [];
        const isIncluded = projects.find(
          (x) => x.project?._id === this.state.project?._id
        );

        if (!isIncluded)
          projects = [
            ...projects,
            { permission: "admin", project: this.state.project },
          ];

        return { ...x, projects };
      });

      const members = updatedMembers.map((x) => ({
        ...x,
        user: x.user._id,
        projects: x.projects
          ?.filter((x) => x.project)
          ?.map((x) => ({ ...x, project: x.project?._id })),
      }));

      await api.patch(`v1/user/members`, {
        members,
      });

      this.setState({ members: [...this.state.members, user] }, () => {
        this.handleSearchUser(this.state.q);
      });
      this.setLoading("permissions", false);

      this.load();
    } catch (e) {
      this.setState({ project: this.props.project });

      this.setLoading("permissions", false);
      toast.error(e.message);
    }
  };

  getInitials(name = "") {
    const nameParts = name?.trim().split(/\s+/);

    if (nameParts.length === 1) {
      return nameParts[0].substring(0, 2).toUpperCase();
    } else {
      return (
        nameParts[0][0] + nameParts[nameParts.length - 1][0]
      ).toUpperCase();
    }
  }

  validateEmail = async (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  handleSearchUser = async (q) => {
    this.setState({ q });

    if (!q) {
      return this.setState({
        permittedUsers: this.state.members,
        searchedUsers: null,
      });
    }

    const regex = new RegExp(q, "i");

    const match = (user) =>
      `${user.firstName || ""} ${user.lastName || ""}`.match(regex) ||
      `${user.email || ""}`.match(regex);

    const permittedUsers = this.state.members?.filter(match) || [];
    const permittedUserIds = permittedUsers.map((x) => x._id);
    const otherTeamMembers =
      this.state.teamMembers
        ?.filter(
          ({ user, role }) =>
            ["member"].includes(role) &&
            !permittedUserIds.includes(user._id) &&
            match(user)
        )
        ?.map((x) => ({ ...x.user, notPermitted: true })) || [];

    const searchedUsers = otherTeamMembers;

    this.setState({ searchedUsers, filteredMembers: permittedUsers });
  };

  render() {
    const members = this.state.members;

    const filteredMembers = this.state.q ? this.state.filteredMembers : members;

    const isAdmin = ["owner", "admin"].includes(this.props.team.role);

    return (
      <>
        <div className="pageTitle">
          <div>Project Members</div>
          <span>View and manage project members</span>
        </div>

        <div className="contentBody">
          <div className="tLabel mb10">Members</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">Project users</div>

              <SearchInput
                type="text"
                className="teamSearch"
                placeholder="Search users"
                value={this.state.q}
                onChange={(q) => this.handleSearchUser(q)}
              />

              {members ? (
                <div className="mLabel mt10">
                  {members.length} member{members.length > 1 ? "s" : ""}
                </div>
              ) : (
                <div className="mLabel mt10">Loading</div>
              )}

              {filteredMembers?.map((user) => {
                return (
                  <div className="teamItem" key={user._id}>
                    <div className="teamContext">
                      <div className="teamIco">
                        <Avatar user={user} />
                      </div>
                      <div className="teamDetails">
                        <div className="teamName">{user?.firstName}</div>
                        <div className="teamSubline">{user?.email}</div>
                      </div>
                    </div>
                    {isAdmin && user._id !== this.props.user._id ? (
                      this.state.loadingObj.permissions ? (
                        this.state.loadingObj.permissions === user._id ? (
                          <div className="loader" />
                        ) : (
                          <div />
                        )
                      ) : (
                        <div
                          onClick={() => this.removeUser(user._id)}
                          className="teamRemove"
                        >
                          <div></div>
                          <div></div>
                        </div>
                      )
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}

              {isAdmin &&
                this.state.searchedUsers?.map((user) => {
                  return (
                    <div className="teamItem" key={user._id}>
                      <div className="teamContext">
                        <div className="teamIco">
                          <Avatar user={user} />
                        </div>
                        <div className="teamDetails">
                          <div className="teamName">{user?.firstName}</div>
                          <div className="teamSubline">{user?.email}</div>
                        </div>
                      </div>
                      {user._id !== this.props.team.user._id ? (
                        this.state.loadingObj.permissions ? (
                          this.state.loadingObj.permissions === user._id ? (
                            <div className="loader" />
                          ) : (
                            <div />
                          )
                        ) : (
                          <div
                            onClick={() => this.addUser(user)}
                            className="teamAdd"
                          >
                            Add
                          </div>
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectMembers;
