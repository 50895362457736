import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";

const SCREEN_NAME = "ACCOUNT_DETAILS";

const AccountDetailsScreen = (props) => {
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);
    try {
      const { transactions } = await api.get("v1/user/transaction", {
        filter: JSON.stringify({ where: { status: "success" } }),
      });
      setTransactions(transactions);
      console.log({ transactions });
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <div className="userPanel">
      <SideNav
        activeSidebarItem=""
        activeSidebarSubItem={"billing"}
        key="sidenav"
      />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/account/billing" />
        <div className="upHeader">
          <div className="upHeaderTop">
            <div className="upHeaderRow">
              <div className="upTitle">Billing</div>
            </div>
          </div>
        </div>

        <div className="upBody">
          <div className="upBodyInner">
            <div className="fullDataTable">
              <table>
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Amount</th>
                    <th>Time</th>
                    <th>Type</th>
                    <th>Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((transaction, index) => {
                    const {
                      amount,
                      createdAt,
                      status,
                      transactionType
                    } = transaction;
                    return (
                      <tr key={index} onClick={() => {}}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="tableData">{amount}</div>
                        </td>
                        <td>
                          <div className="tableData">
                            {formatDate(createdAt)}
                          </div>
                        </td>
                        <td>{_.startCase(transactionType)}</td>
                        <td>{_.startCase(status)}</td>
                        {/* <td>
                          <div className="tableData">
                            <div className="minActionRow">
                              <div
                                onClick={() =>
                                  setVisibleModal("transaction", transaction)
                                }
                                className="minButton"
                              >
                                Details
                              </div>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatDate = (date) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return new Date(date).toLocaleString("en-US", options).toLowerCase();
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsScreen);
