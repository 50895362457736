import React, { useState, useEffect } from "react";
import axios from "axios";

const ContentRenderer = ({ url }) => {
  const [contentType, setContentType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContentType = async () => {
      try {
        setLoading(true);
        const response = await axios.head(url);
        const type = response.headers["content-type"];
        setContentType(type);
      } catch (err) {
        setError("Failed to render");
      } finally {
        setLoading(false);
      }
    };

    fetchContentType();
  }, [url]);

  const renderContent = () => {
    if (contentType.startsWith("image/")) {
      return (
        <img
          src={url}
          alt="Content"
          style={{ width: "100%", height: "auto" }}
        />
      );
    } else if (contentType.startsWith("video/")) {
      return (
        <video controls style={{ width: "100%", height: "auto" }}>
          <source src={url} type={contentType} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (contentType.startsWith("audio/")) {
      return (
        <audio controls style={{ width: "100%" }}>
          <source src={url} type={contentType} />
          Your browser does not support the audio tag.
        </audio>
      );
    } else if (contentType.startsWith("text/html")) {
      return (
        <iframe
          src={url}
          title="HTML Content"
          style={{ width: "100%", height: "500px", border: "none" }}
        ></iframe>
      );
    } else {
      // Open the URL in a new tab if the content type is unknown
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Click here to open the content in a new tab
        </a>
      );
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <div>
        <p style={{ color: "red" }}>{error}</p>
        <a href={url} target="_blank" rel="noopener noreferrer">
          Click here to open the content in a new tab
        </a>
      </div>
    );
  }

  if (!contentType) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Click here to open the content in a new tab
      </a>
    );
    return <p>Unable to determine content type.</p>;
  }

  return <div>{renderContent()}</div>;
};

export default ContentRenderer;
