import React from "react";

const OptionToggle = ({ value = {}, onChange, options }) => {
  return (
    <>
      {options.map((item) => {
        return (
          <div className="optionItemBox" key={item.value}>
            <div
              className={
                "advancedRadioItem" +
                (value === item.value ? " lightActive" : "")
              }
              onClick={() => onChange(item)}
            >
              <div className="advancedRadioLabel">{item.label}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OptionToggle;
