import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import update from "immutability-helper";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import modules from "../../Modules";
import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";
import Header from "../Home/Header";
import CustomSelect from "../../Components/etc/CustomSelect";
import withRouter from "../../Components/Wrapper/with-router";
import SignupWithPayment from "./SignupWithPayment";

const LoginScreenInner = (props) => {
  const { isLoggedIn, defaultScreen = "login" } = props;
  const [newUser, setNewUser] = useState(defaultScreen === "signup");
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginRes, setLoginRes] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const gotohome = () => {
        let defaultNextScreen = "/dashboard";
        let nextScreen =
          modules.navigation.getQuery("nextScreen") || defaultNextScreen;
        nextScreen = modules.navigation.matchCurrentPath(nextScreen)
          ? defaultNextScreen
          : nextScreen;

        navigate(nextScreen);
      };

      gotohome();
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn) return null;

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };
  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      if (loading) return;
      setLoading(true);
      setFormError(null);

      const loginRes = await api.post("v1/user/login", {
        email: form?.email,
        password: form?.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      setLoading(false);

      let inviteAcceptedTeams = loginRes.user.teams.filter(
        (x) => x.acceptedAt || x.role === "owner"
      );

      loginRes.user.teams = inviteAcceptedTeams;

      if (inviteAcceptedTeams.length > 1) {
        setLoginRes(loginRes);
      } else {
        await onTeamSelect(inviteAcceptedTeams[0], loginRes);
      }
    } catch (e) {
      setFormError(e.message);
      setLoading(false);
    }
  };

  const onTeamSelect = async (team, loginRes) => {
    modules.auth.initAuth({ ...loginRes, teamId: team.user._id, team });
  };

  if (newUser)
    return (
      <SignupWithPayment
        {...{
          ...props,
          loginRes,
          setLoginRes,
          handleLogin,
          onTeamSelect,
          setNewUser,
        }}
      />
    );
  else
    return (
      <>
        <div className="frontWrapper">
          {/* <Header /> */}

          <div className="onboardingLeft">
            <div className="frontHeader">
              <div className="headLogo">AppWolo</div>
              <Link className="headerMain" to="/signup">Sign Up</Link>
            </div>

            {formError ? <div className="error">{formError}</div> : ""}

            <form className="login-form" onSubmit={(e) => handleLogin(e)}>
              <div className="onboardingBox">
                <div className="obBox">
                  <h2>Log into your account now</h2>

                  <div className="obItem">
                    <div className="obLabel">Email</div>
                    <input
                      type="email"
                      placeholder="email"
                      onChange={(e) => {
                        updateForm({ email: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="obItem">
                    <div className="obLabel">Password</div>
                    <input
                      type="password"
                      placeholder="password"
                      onChange={(e) => {
                        updateForm({ password: e.target.value });
                      }}
                      required
                    />
                  </div>
                  <button className="frontDarkButton">
                    {loading ? "Logging In" : "Login"}
                  </button>
                  {/* <p className="message">
                    Not registered?{" "}
                    <span className="a" onClick={() => setNewUser(true)}>
                      Create an account
                    </span>
                  </p> */}
                </div>
              </div>
            </form>
          </div>

          <div className="enrollPlanOptions">
            {/* <h4>Plan Details</h4>
            <div className="epoLabel">Select your plan</div>
            <div className="epoWrapper">
              <div className="epoItem active">
                <div className="epoHead">
                  <div className="epoHeadContent">
                    <div className="epoLabel">Plan Name</div>
                    <div className="epoValue">Builder</div>
                  </div>
                  <div className="epoHeadDecor"></div>
                </div>

                <div className="epoBody">
                  <div className="epoMulti">
                    <div className="epoMultiItem">
                      <div className="epoLabel">Frequency</div>
                      <div className="epoValue">Monthly</div>
                    </div>

                    <div className="epoMultiItem">
                      <div className="epoLabel">Plan Cost</div>
                      <div className="epoValue">$49</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="epoItem">
                <div className="epoHead">
                  <div className="epoHeadContent">
                    <div className="epoLabel">Plan Name</div>
                    <div className="epoValue">Builder</div>
                  </div>
                  <div className="epoHeadDecor"></div>
                </div>

                <div className="epoBody">
                  <div className="epoMulti">
                    <div className="epoMultiItem">
                      <div className="epoLabel">Frequency</div>
                      <div className="epoValue">Monthly</div>
                    </div>

                    <div className="epoMultiItem">
                      <div className="epoLabel">Plan Cost</div>
                      <div className="epoValue">$49</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <img
              className="onboardingIco"
              src={require("../../Assets/img/landing-pages/decorBig.png")}
            />
            <div className="epoContentBox">
              <div className="enrollMain">Join over 2000+ builders.</div>
              <div className="enrollContextArea">
                Unleash your creativity and build powerful apps without writing
                a single line of code. Our no-code app builder makes turning
                ideas into reality fast, easy, and accessible for everyone!
              </div>
            </div>
          </div>

          <AppModal
            showFooter={false}
            title={"Select Team"}
            visible={!!loginRes}
            handleClose={() => setLoginRes(null)}
          >
            {loginRes?.user.teams.map((team) => (
              <div
                key={team.user._id}
                onClick={() => onTeamSelect(team, loginRes)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: "#efefef",
                  marginBottom: "10px",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {team.user?.firstName} {team.user?.lastName}
                </div>
                <div>{_.startCase(team.role)}</div>
              </div>
            ))}
          </AppModal>
        </div>
      </>
    );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "LOGIN_SCREEN") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const LoginScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginScreenInner));

export const SignupScreen = (props) => {
  return <LoginScreen {...props} defaultScreen="signup" />;
};

const styleObj = {
  hidden: {
    display: "none",
  },
};

export default LoginScreen;
