import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import { toast } from "react-toastify";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import api from "../../Services/Api/api";
import RenderItem from "./RenderItem";

class TableViewer extends React.Component {
  state = {
    loading: false,
    error: null,
    count: null,
    page: 1,
    limit: 5,
    loadedPage: null,
    query: {},
  };

  componentDidMount() {
    this.load();
  }

  getTable() {
    return this.props.database?.tables?.find(
      (x) => x._id === this.props.tableId
    );
  }

  searchDb(propQuery) {
    const {
      props: { database },
    } = this;

    const table = this.getTable();

    if (table) {
      let query = {
        dbId: database._id,
        tableId: table._id,
        filters: [],
        limit: 50,
        skip: 0,
        sortby: "updatedAt",
        order: -1,
        ...propQuery,
      };

      return api.post("v1/database/read", query).then((x) => x.data);
    }
  }

  async load() {
    try {
      const {
        props: { database },
        state: { page, limit, query },
      } = this;

      const table = this.getTable();

      if (
        !database?._id ||
        !table ||
        !table.columns ||
        ["local"].includes(database.type)
      )
        return;

      this.setState({ loading: true, error: null });

      const filters = Object.keys(query).map((x) => ({
        name: x,
        value: query[x],
      }));

      const count = await this.searchDb({
        countOnly: true,
        filters,
      });
      this.setState({ count });

      let skip = limit * (parseInt(page) - 1);
      const items = await this.searchDb({
        limit,
        skip,
        filters,
      });
      this.props.setScreenState({ selectedItems: null, items });
      this.setState({ loading: false, loadedPage: page });
    } catch (e) {
      toast.error(e.message || e.toString?.());
      this.setState({ loading: false, error: e.message || e.toString?.() });
    }
  }

  reload() {
    this.setState({ page: 1 }, () => this.load());
  }

  async deleteItem(_id) {
    const payload = {
      dbId: this.props.database._id,
      tableId: this.props.tableId,
      filters: [{ name: "_id", value: _id }],
      limit: 1,
      valueType: "deleteRecord",
    };

    return api.post("v1/database/write", payload).then((data) => {
      let index = this.props.items?.findIndex((x) => x._id === _id);
      if (index > -1)
        this.props.setScreenState({
          items: update(this.props.items || [], { $splice: [[index, 1]] }),
        });
      return data;
    });
  }

  async updateItem(item) {
    const document = {};
    for (const key in item) {
      if (Object.hasOwnProperty.call(item, key)) {
        const value = item[key];
        if (key !== "_id") document[key] = { value };
      }
    }

    let payload = {
      dbId: this.props.database._id,
      tableId: this.props.tableId,
      document,
      valueType: "addRecord",
    };

    if (!item._id) {
      api.post("v1/database/write", payload).then((data) => {
      });

      //  (err, data) => {
      //   console.log({ err, data });

      //   if (err || !data) return reject(new Error(err?.toString?.()));

      //   let newRecordId = data.insertedIds && data.insertedIds[0];
      //   return resolve({ ...item, _id: newRecordId });
      // }
    } else {
      payload = {
        ...payload,
        valueType: "editRecord",
        filters: [{ name: "_id", value: item._id }],
        limit: 1,
      };

      api.post("v1/database/write", payload).then((data) => {

        let index = this.props.items?.findIndex((x) => x._id === item._id);
        if (index > -1)
          this.props.setScreenState({
            items: update(this.props.items || [], {
              $merge: { [index]: item },
            }),
          });

        return data;
      });
    }
  }

  renderPagination() {
    const {
      state: { count, limit, page, loadedPage, loading },
    } = this;

    if (count <= limit) return null;

    return (
      <div className="paginationBar">
        <div className="paginationLeft">
          <button
            className={`paginateItem previous ${
              loadedPage <= 1 ? "disabled" : ""
            }`}
            disabled={loadedPage <= 1}
            onClick={() =>
              this.setState({ page: loadedPage - 1 }, () => this.load())
            }
          >
            <img
              className="paginateIco"
              src={require("../../Assets/img/options/options/reveal.png")}
            ></img>
          </button>

          <div className="currentPage">
            {loadedPage}
            {" / "}
            {Math.ceil(count / limit)}
          </div>
          <button
            className={`paginateItem next ${
              loadedPage >= Math.ceil(count / limit) ? "disabled" : ""
            }`}
            disabled={loadedPage >= Math.ceil(count / limit)}
            onClick={() =>
              this.setState({ page: loadedPage + 1 }, () => this.load())
            }
          >
            <img
              className="paginateIco"
              src={require("../../Assets/img/options/options/reveal.png")}
            ></img>
          </button>
        </div>
        <div className="paginationRight">
          {"    "}
          Page:{" "}
          <input
            value={page}
            min={1}
            max={Math.ceil(count / limit)}
            onChange={(e) => this.setState({ page: e.target.value })}
            type="number"
            style={{ width: "50px" }}
          />
          <button
            onClick={() => {
              this.setState(
                {
                  page: Math.max(1, Math.min(page, Math.ceil(count / limit))),
                },
                () => this.load()
              );
            }}
          >
            {loading ? "Loading" : "Load"}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      state: { query, loading },
      props: { selectedItems, items, triggerEdit },
    } = this;

    const table = this.getTable();
    const columns = table?.columns ? table?.columns?.map((x) => x.name) : null;

    return (
      <>
        <div className="dataTable">
          <div>{loading ? "Loading..." : null}</div>
          {/* {this.renderPagination()} */}
          <table>
            <thead>
              <tr>
                {columns?.map((col) => (
                  <th key={col}>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.load();
                        }}
                      >
                        <input
                          value={query[col]?.toString() || ""}
                          onChange={(e) => {
                            let value = e.target.value;

                            this.setState({
                              query: value
                                ? { ...query, [col]: value }
                                : update(query, { $unset: [col] }),
                            });
                          }}
                          placeholder={col}
                        ></input>
                      </form>
                    </div>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {items?.map((item, index) => {
                const isSelectedIndex = selectedItems?.findIndex(
                  (x) => x._id === item._id
                );
                const isSelected = isSelectedIndex > -1;
                return (
                  <RenderItem
                    {...{
                      item,
                      index,
                      key: item?._id || index,
                      columns: columns,
                      deleteItem: this.deleteItem.bind(this),
                      updateItem: this.updateItem.bind(this),
                      reload: this.reload.bind(this),
                      triggerEdit,
                      isSelected,
                      toggleSelection: () => {
                        this.props.setScreenState({
                          selectedItems: isSelected
                            ? update(selectedItems, {
                                $splice: [[isSelectedIndex, 1]],
                              })
                            : update(selectedItems || [], { $push: [item] }),
                        });
                      },
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        {this.renderPagination()}
      </>
    );
  }
}

const SCREEN_NAME = "DATABASE_PANEL";

const mapStateToProps = (state) => ({
  database: state.vState[SCREEN_NAME].database,
  tableId: state.vState[SCREEN_NAME].tableId,
  selectedItems: state.vState[SCREEN_NAME].selectedItems,
  triggerEdit: state.vState[SCREEN_NAME].triggerEdit,
  items: state.vState[SCREEN_NAME].items,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableViewer);
