import React, { useState, useRef, useEffect } from "react";
import _, { set } from "lodash";
import OptionItem, {
  OptionToggle1,
} from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import JustifyContent from "./JustifyContent";
import AlignItems from "./AlignItems";
import Background from "./Background";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import SliderConfig from "../../ElementTypes/container/ContainerType/SliderConfig";
import ContainerTabsConfig from "../../ElementTypes/container/ContainerType/ContainerTabsConfig";
const ContainerAppearance = (props) => {
  const [hover, setHover] = useState(false);
  const [popup, setPopup] = useState(false);

  const isEmailTemplate = ["emailTemplate"].includes(props.availableFor);

  const { styleProps } = props;

  const _styles = hover ? styleProps.hover.value || {} : styleProps.value || {};
  const styles = hover ? { ...(styleProps.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? styleProps.hover.onChange : styleProps.onChange)({
      ..._styles,
      ...obj,
    });

  const hoverIndependentStyle = styleProps.value || {};
  const mergeHoverIndependentStyle = (obj) =>
    styleProps.onChange({ ...hoverIndependentStyle, ...obj });

  const flexDirection = styles.flexDirection;

  const optionHeadTabs = (
    <>
      <div className="bgSetButton" onClick={() => setPopup(!popup)}>
        +
      </div>

      <ToggleButton
        classNames={{
          container: "optionHeadTabs headTabsDark displayNone",
          item: "optionHeadTabItem",
        }}
        overideDefaultStyle
        value={styles.flex || null}
        onChange={(x) =>
          mergeStyle(
            x
              ? {
                  flex: x,
                  width: null,
                  height: null,
                }
              : { flex: null }
          )
        }
        options={[
          { value: null, label: "Custom" },
          { value: 1, label: "fill" },
        ]}
      />
    </>
  );

  const containerType = props.value?.containerType || {};
  const mergeContainerType = (obj) =>
    props.onChange({
      ...(props.value || {}),
      containerType: { ...containerType, ...obj },
    });

  return (
    <>
      <OptionItem
        optionItemLabel={"Appearance"}
        optionHeadTabs={optionHeadTabs}
        key={hover}
      >
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div className="oibLabel">Width</div>
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                    value={styles.flex === 1 ? "" : styles.width || ""}
                    disabled={styles.flex === 1}
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div>
                <div className="oibLabel">Height</div>
                <div>
                  <div className="optionInputIconBox">
                    <img
                      className="optionInputIconBoxIco rotate90"
                      src={require("../../../../../Assets/img/options/container/widthIco.png")}
                      alt="height"
                    ></img>
                    <ControlledInput
                      type="text"
                      onChange={(event) =>
                        mergeStyle({ height: event.target.value })
                      }
                      className="optionInputIconBoxField"
                      placeholder="Auto"
                      value={styles.flex === 1 ? "" : styles.height || ""}
                      disabled={styles.flex === 1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="optionItemRow">
            <FlexDirection
              value={styles}
              onChange={(styles) =>
                mergeStyle({ ...styles, display: styles.display || "flex" })
              }
            />

            <div className="optionItemBox">
              <div>
                <div className="oibLabel">Type</div>
                <div>
                  <CustomSelect
                    onChange={(option) =>
                      mergeContainerType({
                        type: option.value,
                      })
                    }
                    value={containerType.type || "container"}
                    options={
                      isEmailTemplate
                        ? [{ value: "container", label: "Basic" }]
                        : [
                            { value: "container", label: "Basic" },
                            { value: "slider", label: "Slider" },
                            { value: "containerTabs", label: "Tabs" },
                          ]
                    }
                    placeholder={"Select"}
                    classNames={{
                      head: "optionInputIconBox",
                      label: "optionInputIconBoxField",
                      chevron: "optionDatabaseSelectChevron",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
      {popup ? (
        <div>
          <Background {...{ ...styleProps, backgroundImage: true }} />{" "}
        </div>
      ) : null}

      {containerType.type === "slider" ? (
        <SliderConfig
          {...props}
          value={containerType.sliderConfig}
          onChange={(x) => mergeContainerType({ sliderConfig: x })}
        />
      ) : containerType.type === "containerTabs" ? (
        <ContainerTabsConfig
          {...props}
          value={containerType.containerTabsConfig}
          onChange={(x) => mergeContainerType({ containerTabsConfig: x })}
        />
      ) : null}
    </>
  );
};

const alignmentOptions = {
  row: {
    label: "Horizontal",
    alignments: [
      {
        id: "horizontalAlignment",
        value: "justifyContent",
        label: "Horizontal",
        options: [
          {
            value: "flex-start",
            label: "Left",
          },
          {
            value: "flex-end",
            label: "Right",
          },
          {
            value: "space-between",
            label: "Stretch",
          },
          {
            value: "space-evenly",
            label: "Even",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
      {
        id: "verticalAlignment",
        value: "alignItems",
        label: "Vertical",
        options: [
          {
            value: "flex-start",
            label: "Top",
          },
          {
            value: "flex-end",
            label: "Bottom",
          },
          {
            value: "stretch",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
    ],
  },
  column: {
    label: "Vertical",
    alignments: [
      {
        id: "verticalAlignment",
        value: "justifyContent",
        label: "Horizontal",
        options: [
          {
            value: "flex-start",
            label: "Top",
          },
          {
            value: "flex-end",
            label: "Bottom",
          },
          {
            value: "space-between",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
      {
        id: "horizontalAlignment",
        value: "alignItems",
        label: "Vertical",
        options: [
          {
            value: "flex-start",
            label: "Left",
          },
          {
            value: "flex-end",
            label: "Right",
          },
          {
            value: "stretch",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
    ],
  },
};

const findAlignmentOption = ({
  flexDirection,
  alignmentValue,
  alignmentId,
  value,
}) => {
  const options = alignmentOptions[flexDirection]?.alignments?.find((x) =>
    alignmentId ? x.id === alignmentId : x.value === alignmentValue
  )?.options;
  return options?.find((x) => x.value === value);
};

const FlexDirection = (props) => {
  const { value: styles } = props;

  const [visible, setVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div className="optionItemBox">
      <div>
        <div className="oibLabel">Alignment</div>
        <div>
          <div className="custom-select" ref={dropdownRef}>
            <div
              onClick={() => setVisible((prev) => !prev)}
              className="custom-select-head optionInputIconBox"
            >
              <div className="selected-option optionInputIconBoxField">
                {alignmentOptions[styles?.flexDirection]?.label}

                {findAlignmentOption({
                  flexDirection: styles?.flexDirection,
                  alignmentValue: "justifyContent",
                  value: styles.justifyContent,
                })?.label || "--"}

                {findAlignmentOption({
                  flexDirection: styles?.flexDirection,
                  alignmentValue: "alignItems",
                  value: styles.alignItems,
                })?.label || "--"}
              </div>
              <img
                className="optionDatabaseSelectChevron"
                src={require("../../../../../Assets/img/options/container/chevronDown.png")}
              ></img>
            </div>
            {visible && (
              <FlexDirectionPopup
                {...{ ...props, close: () => setVisible(false) }}
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

const FlexDirectionPopup = (props) => {
  const { value: styles, onChange: mergeStyle, close } = props;

  const [form, setForm] = useState({
    ..._.pick(styles || {}, ["flexDirection", "justifyContent", "alignItems"]),
    flexDirection: ["row", "column"].includes(styles.flexDirection)
      ? styles.flexDirection
      : "column",
  });

  const { flexDirection } = form;

  return (
    <div className="options-list customizeDropdown">
      <div className="alignmentRow">
        <div className="alignmentItem">
          <div className="alignmentLabel">Direction</div>
          <div className="alignmentList">
            {[
              { value: "row", label: "Horizontal" },
              { value: "column", label: "Vertical" },
            ].map((direction) => (
              <div
                className={
                  "option" +
                  (flexDirection === direction.value ? " active" : "")
                }
                key={{ direction }}
                onClick={() =>
                  setForm({ ...form, flexDirection: direction.value })
                }
              >
                {/* <input
                type="radio"
                checked={flexDirection === direction.value}
                onChange={() =>
                  setForm({ ...form, flexDirection: direction.value })
                }
              /> */}
                {direction.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="alignmentRow">
        {alignmentOptions[flexDirection]?.alignments?.map((alignment) => (
          <div className="alignmentItem" key={alignment.id}>
            <div className="alignmentLabel">{alignment.label} Alignment</div>
            <div className="alignmentList">
              {alignment.options.map((option) => (
                <div
                  className={
                    "option" +
                    (option.value === form[alignment.value] ? " active" : "")
                  }
                  key={option.value}
                  onClick={() =>
                    setForm({ ...form, [alignment.value]: option.value })
                  }
                >
                  {/* <input
                    type="radio"
                    checked={option.value === form[alignment.value]}
                    onChange={() =>
                      setForm({ ...form, [alignment.value]: option.value })
                    }
                  /> */}
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="alignmentAction">
        <div
          className="optionPrimary"
          onClick={() => {
            mergeStyle(form);
            close();
          }}
        >
          Apply
        </div>
        <div className="optionSecondary" onClick={() => close()}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default ContainerAppearance;
