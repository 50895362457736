import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className="section sectionFooter">
    <div className="container">
      <div className="wrapper padding gap20 footerWrapper">
        <div className="rowItem footerAdjust">
          <div className="footerBox">
            <div className="headLogo">AppWolo</div>
            <div>Build Anything.</div>
          </div>
        </div>
        <div className="rowItem">
          <div className="footerBox">
            <div className="footerHead">Page</div>
            <Link to="/" className="footerItem">
              Home
            </Link>
            <Link to="/pricing" className="footerItem">
              Pricing
            </Link>
            <Link to="/showcase" className="footerItem">
              Showcase
            </Link>
          </div>
        </div>
        <div className="rowItem">
          <div className="footerBox">
            <div className="footerHead">Support</div>
            <Link to="/help" className="footerItem">
              Help
            </Link>
            <Link to="/help" className="footerItem">
              Documentation
            </Link>
            <a href="mailto:info@AppWolo.com" className="footerItem">
              info@AppWolo.com
            </a>
          </div>
        </div>
        <div className="rowItem flexInitial">
          <div className="footerBox">
            <div className="footerHead">Policy</div>
            <Link to="#" className="footerItem">
              Terms of Service
            </Link>
            <Link to="#" className="footerItem">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>

      <div className="wrapper padding gap20 footerWrapper jSpaceBetween mtBottomFooter">
        <div className="footerLine">AppWolo 2024.</div>
        <div className="footerLine">All Rights Reserved.</div>
      </div>
    </div>
  </div>
);

export default Footer