import React from "react";
import update from "immutability-helper";

import api from "../../../../../Services/Api/api";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import OptionItem from "../../../../../Components/etc/OptionItem";
import RichTextData from "../../Common/DataProperties/RichTextData";
import SearchIcons from "../../Common/ImageSources/SearchIcons";
import { ImageTypeZoomResizeMode } from "../../Common/StyleProperties/ImageAppearance";
class ImageData extends React.Component {
  state = {
    uploadings: [],
    ts: 0,
    resetFileInput: 0,
    oldFiles: null,
    iconSearchText: "",
  };

  maxLength = Infinity;
  uploadProgress = {};

  componentDidMount() {
    this.loadOldFiles();
  }

  async loadOldFiles() {
    try {
      const { files } = await api.get("v1/file", {
        projectId: this.props.project?._id,
        uploadedFrom: "builder",
      });
      this.setState({ oldFiles: files });
    } catch (error) {
      console.error(error);
    }
  }

  mergeChange(obj) {
    this.props.onChange({ ...(this.props.value || {}), ...obj });
  }

  mergeValueObj(obj) {
    this.mergeChange({
      valueObj: { ...(this.props.value?.valueObj || {}), ...obj },
    });
  }

  appendStaticUrls = (urls) => {
    const staticUrls = this.props.value?.valueObj?.staticUrls || [];
    let updated = update(staticUrls, { $push: urls });

    if (updated.length > this.maxLength) {
      const elementsToRemove = updated.length - this.maxLength;
      updated.splice(0, elementsToRemove);
    }
    this.mergeValueObj({ staticUrls: updated });
  };

  uploadHandler = async (event) => {
    try {
      let files = event.target.files;
      let localUrls = [];

      for (let i = 0; i < files.length && i < this.maxLength; i++) {
        const file = files[i];
        const localUrl = URL.createObjectURL(file);
        localUrls.push({ url: localUrl, uid: Math.random(), file });
      }

      let updatedUploadings = [...this.state.uploadings, ...localUrls];
      this.setState({
        uploadings: updatedUploadings,
        resetFileInput: Date.now(),
      });

      const promises = updatedUploadings.map(async (uploading) => {
        const fileRes = await api.media(
          "v1/file",
          {
            file: uploading.file,
          },
          {
            cb: (x) => {
              this.uploadProgress[uploading.uid] = x;
              this.setState({ ts: Date.now() });
            },
          }
        );
        const staticUrl = api.getFileLink(fileRes?.file);
        return staticUrl;
      });

      const staticUrls = await Promise.all(promises);
      this.setState({
        uploadings: this.state.uploadings.filter(
          (x) => !updatedUploadings.find((u) => u.uid === x.uid)
        ),
      });
      this.appendStaticUrls(staticUrls);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  staticUrlConfig(opt = {}) {
    const { value = {} } = this.props;
    const staticUrls = value.valueObj?.staticUrls || [];

    return (
      <div className="optionDatabaseTextBox">
        <div className="optionDatabaseTextBoxLabel">
          {opt.title || "Image File"}
        </div>
        <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
          <label className="optionDatabaseTextBoxFooterUpload">
            <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
            <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
            <input
              key={this.state.resetFileInput}
              type="file"
              hidden
              onChange={this.uploadHandler.bind(this)}
              multiple
              accept={`image/*`}
            />
          </label>

          {staticUrls?.map((src, i) => (
            <div key={src + i} className="uploadPhotoItem">
              <img className="uploadPhoto" src={src} alt="uploadedPhoto" />
              <div
                className="deletePhoto"
                onClick={() =>
                  this.mergeValueObj({
                    staticUrls: update(staticUrls, { $splice: [[i, 1]] }),
                  })
                }
              >
                <div className="deletePhotoLine one"></div>
                <div className="deletePhotoLine two"></div>
              </div>
            </div>
          ))}

          {this.state.uploadings.map((uploading) => (
            <div className="uploadPhotoItem" key={uploading.uid}>
              <img
                className="uploadPhoto"
                src={uploading.url}
                alt="uploadedPhoto"
              />
              <div
                style={{
                  background: "#fff6",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  height: 100 - (this.uploadProgress[uploading.uid] || 0) + "%",
                }}
              ></div>
              <div
                className="deletePhoto"
                onClick={() => {
                  this.setState({
                    uploadings: this.state.uploadings.filter(
                      (x) => x.uid !== uploading.uid
                    ),
                  });
                }}
              >
                <div className="deletePhotoLine one"></div>
                <div className="deletePhotoLine two"></div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="optionDatabaseTextBoxFooter"
          style={this.state.iconSearchText ? { display: "none" } : null}
        >
          {this.state.oldFiles?.map((file) => (
            <div
              key={file._id}
              className="uploadPhotoItem"
              onClick={() =>
                this.appendStaticUrls([
                  api.getFileLink(file, { height: 0, width: 0 }),
                ])
              }
            >
              <img
                className="uploadPhoto"
                src={api.getFileLink(file)}
                alt="uploadedPhoto"
              />
            </div>
          ))}
        </div>

        <SearchIcons
          iconChooseHandler={(urlObj, src) => {
            let source = src || urlObj?.images?.["128"];
            if (source) this.appendStaticUrls([source]);
          }}
          uploadHandler={this.uploadHandler.bind(this)}
          iconSize={this.props.styleData?.width?.replace("px", "")}
        />
      </div>
    );
  }

  textPartsConfig() {
    const { value = {} } = this.props;

    return (
      <TextPartsOptionItemRow
        {...{
          ...this.props,
          title: "Reference",
          placeholder: "Reference to image",
          value: value?.valueObj,
          onChange: (valueObj) =>
            this.mergeChange({
              valueObj: { ...(value?.valueObj || {}), ...valueObj },
            }),
        }}
      />
    );
  }

  render() {
    const { value = {} } = this.props;
    const valueType = value.valueType || "staticUrls";

    const optionHeadTabs = (
      <ToggleButton
        classNames={{
          container: "optionHeadTabs headTabsDark",
          item: "optionHeadTabItem",
        }}
        overideDefaultStyle
        value={valueType}
        onChange={(x) =>
          this.mergeChange({
            valueType: x,
          })
        }
        options={[
          { value: "staticUrls", label: "File" },
          { value: "textParts", label: "Reference" },
        ]}
      />
    );

    return (
      <OptionItem optionItemLabel="DATA" optionHeadTabs={optionHeadTabs}>
        <div className="optionItemBody">
          <ImageTypeZoomResizeMode {...this.props.imageAppearanceProps} />

          <div className="optionItemRow">
            <div className="optionDatabaseBoxWrapper">
              {valueType === "textParts"
                ? this.textPartsConfig()
                : this.staticUrlConfig()}
            </div>
          </div>
        </div>
      </OptionItem>
    );
  }
}

const TextPartsOptionItemRow = (props) => {
  const { title, placeholder, value, onChange } = props;
  return (
    <div className="optionDatabaseTextBox">
      <div className="optionDatabaseTextBoxLabel">{title}</div>
      <RichTextData
        {...{
          ...props,
          placeholder,
          value,
          onChange,
          immediateProps: { fileButton: true },
        }}
      />
    </div>
  );
};

export default ImageData;
