import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import PositionStyle from "../../Common/StyleProperties/PositionStyle";
import etcModule from "../../../../../Modules/etc/etc-module";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import BoxShadow from "../../Common/StyleProperties/BoxShadow";
import ContainerData from "./ContainerData";
import ContainerAppearance from "../../Common/StyleProperties/ContainerAppearance";
import Spacing from "../../Common/StyleProperties/Spacing";
import ContainerOptions from "../../Common/StyleProperties/ContainerOptions";

class ContainerProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const styleProps = {
      ...this.props,
      value: this.props.styles,
      onChange: this.props.setStyles,
    };

    return (
      <>
        <ContainerAppearance
          {...{ ...this.props, value, onChange: mergeChange, styleProps }}
        />

        <Spacing {...styleProps} />

        <BorderRadius {...styleProps} />
        <Border {...styleProps} />
        <BoxShadow {...styleProps} />

        <ContainerOptions
          {...{
            ...styleProps,
            safeAreaViewConfig: value.safeAreaViewConfig || {},
            setSafeAreaViewConfig: (x) =>
              mergeChange({ safeAreaViewConfig: x }),
          }}
        />

        <PositionStyle {...styleProps} />

        <ContainerData
          {...this.props}
          onChange={mergeChange}
          linkBoxOptions={{
            conditionalDataType: true,
            showTriggerOption: true,
            styles: {
              container: {
                margin: "3px 0",
                padding: "5px",
                border: "solid 0.5px grey",
                borderRadius: "2px",
                backgroundColor: "#fff",
                color: "#333",
              },
            },
          }}
        />
      </>
    );
  }
}

export default ContainerProperties;
