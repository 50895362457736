import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Sample data
// const initialItems = [
//   {
//     id: "1",
//     content: (
//       <div>
//         <div>ss</div>Item 1
//       </div>
//     ),
//   },
//   { id: "2", content: "Item 2" },
//   { id: "3", content: "Item 3" },
//   { id: "4", content: "Item 4" },
//   { id: "5", content: "Item 5" },
// ];

const DraggableList = ({
  value: items = [],
  onChange: setItems,
  droppableId = "unique-droppable-id",
  classNames = {},
}) => {
  //   const [items, setItems] = useState(initialItems);

  // Handle drag end
  const handleDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside a valid destination

    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={"draggable-list " + (classNames.droppableList || "")}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
