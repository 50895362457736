import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import config from "../../Config";
import api from "../../Services/Api/api";

const stripePromise = loadStripe(config.stripe.publicKey);

const PaymentElementWrapper = forwardRef((_, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  // Expose the handleSetupIntent function to the parent via ref
  useImperativeHandle(ref, () => ({
    async handleSetupIntent() {
      if (!stripe || !elements) {
        setError("Stripe or Elements not initialized.");
        return null;
      }

      try {
        const { setupIntent, error: stripeError } = await stripe.confirmSetup({
          elements,
          confirmParams: {},
          redirect: "if_required",
        });

        console.log({ setupIntent, stripeError });

        if (stripeError) {
          throw new Error(stripeError.message);
        }

        return setupIntent;
      } catch (err) {
        setError(err.message);
        console.error("SetupIntent Error:", err);
        return null;
      }
    },
  }));

  return (
    <div>
      <PaymentElement options={{ layout: "tabs" }} />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
});

const PaymentComponent = forwardRef((props, ref) => {
  const { clientSecret, setClientSecret } = props;
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!clientSecret) {
      api
        .post("v1/user/subscription/create-setup-intent")
        .then((data) => setClientSecret(data.clientSecret))
        .catch((err) => {
          setError(err.message);
          console.error("Error fetching client secret:", err);
        });
    }
  }, [clientSecret]);

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  if (!clientSecret) {
    return (
      <div style={{ textAlign: "center", margin: "20px" }}>
        <div className="loader large" />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: { theme: "stripe" },
      }}
    >
      <PaymentElementWrapper ref={ref} />
    </Elements>
  );
});

export default PaymentComponent;
