export const INITIAL_STATE = {
  LOGIN_SCREEN: {},
  BUILDER: {},
  DATABASE_PANEL: {},
  ANALYTICS: {
    filter: {
      dateRange: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date(Date.now() + 1000 * 60 * 60 * 24),
      ],
    },
  },
  BACKUPS: {
    filter: {},
  },
  PROJECTS: {},
};
