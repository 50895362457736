import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import InputType from "./InputType";
import Border from "../../Common/StyleProperties/Border";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import TextColor from "../../Common/StyleProperties/TextColor";
import InputLabelStyle from "../../Common/StyleProperties/InputLabelStyle";
import InputResponse from "../../Common/StyleProperties/InputResponse";
import ImagePickerProperties from "./ImagePickerProperties";
import DateTimePickerConfig from "./DateTimePickerConfig";
import InputData from "./InputData";
import Appearance from "../../Common/StyleProperties/Appearance";
import Spacing from "../../Common/StyleProperties/Spacing";
import TextInputConfig from "./TextInputConfig";
import InputAppearance from "../../Common/StyleProperties/InputAppearance";

class InputProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const inputStyleProps = {
      ...this.props,

      value: this.props.styles,
      onChange: this.props.setStyles,
    };

    const inputDataProps = {
      ...this.props,
      value: value.inputData,
      onChange: (inputData) => mergeChange({ inputData }),
      mergeChange: (obj) =>
        mergeChange({ inputData: { ...(value.inputData || {}), ...obj } }),
    };

    return (
      <>
        <InputAppearance
          {...{
            ...this.props,
            inputStyleProps,
            inputDataProps,
          }}
        />

        <Border {...inputStyleProps} />
        <Spacing {...inputStyleProps} />
        <BorderRadius {...inputStyleProps} />
        <InputData
          {...this.props}
          inputAppearanceProps={{
            ...this.props,
            inputStyleProps,
            inputDataProps,
          }}
          inputType={value.inputType}
          value={value.inputData}
          onChange={(x) => mergeChange({ inputData: x })}
          linkBoxOptions={{
            conditionalDataType: true,
            showTriggerOption: true,
            styles: {
              container: {
                margin: "3px 0",
                padding: "5px",
                border: "solid 0.5px grey",
                borderRadius: "2px",
                backgroundColor: "#fff",
                color: "#333",
              },
            },
          }}
        />
      </>
    );
  }
}

export default InputProperties;
