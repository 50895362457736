import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import NavBar from "../Common/NavBar";
import CustomSelect from "../../Components/etc/CustomSelect";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../Services/Api/api";
import Avatar from "../../Components/etc/Avatar";

const SCREEN_NAME = "BACKUPS";

class BackupsScreen extends React.Component {
  state = {
    loading: false,
    loadingObj: {},
    loadingObj: {},
  };

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  reloadTimer = null;
  triggerReload() {
    clearTimeout(this.reloadTimer);
    this.reloadTimer = setTimeout(() => {
      this.load();
    }, 100);
  }

  setLoading = (key, value) => {
    this.setState((prevState) => ({
      loadingObj: { ...prevState.loadingObj, [key]: value },
    }));
  };

  setToggleables = (key, value) => {
    this.setState((prevState) => ({
      toggleables: { ...prevState.toggleables, [key]: value },
    }));
  };

  async loadProjects() {
    try {
      const { projects } = await api.get("v1/project");
      this.props.setScreenState({ projects }, false, "PROJECTS");
      return projects;
    } catch (e) {
      toast.error("Error loadig projects: " + e.message);
    }
  }

  async getProjects() {
    return this.props.projects?.length
      ? this.props.projects
      : await this.loadProjects();
  }

  async load() {
    try {
      const filter = this.props.filter || {};
      const { project: projectId, selectedDate } = filter;

      if (this.state.loading) return;

      this.setState({ loading: true });

      const allProjects = await this.getProjects();
      const projectIds = projectId
        ? [projectId]
        : allProjects.map((x) => x._id);

      let where = {
        type: "project",
        project: { $in: projectIds },
      };

      if (selectedDate) {
        const startTime = new Date(selectedDate).setHours(0, 0, 0, 0);
        const endTime = startTime + 1000 * 60 * 60 * 24;
        where = {
          ...where,
          createdAt: {
            $gt: startTime,
            $lte: endTime,
          },
        };
      }

      let { changeLogs } = await api.get("v1/project/changelog", {
        filter: JSON.stringify({
          where,
          order: -1,
          sortby: "createdAt",
          limit: 1000,
        }),
      });

      changeLogs = changeLogs.map((x) => ({
        ...x,
        project: allProjects?.find((project) => project._id === x.project),
      }));

      this.props.setScreenState({
        data: {
          changeLogs: changeLogs,
          uid: Date.now(),
        },
      });

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
      console.warn(e);
    }
  }

  formatDate(date) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(date).toLocaleString("en-US", options).toLowerCase();
  }

  createBackup = async (projectId) => {
    try {
      if (this.state.loadingObj.createBackup || !projectId) return;

      this.setLoading("createBackup", true);

      await api.post("v1/project/changelog", {
        project: projectId,
        type: "project",
      });

      this.setLoading("createBackup", false);
      this.load();
    } catch (e) {
      this.setLoading("createBackup", false);
      toast.error(e.message);
    }
  };

  RestoreBackup = async ({ _id: changelogId, project, createdAt }) => {
    try {
      if (
        this.state.loadingObj.createBackup ||
        this.state.loadingObj.restoreBackup ||
        !window.confirm(
          `Are you sure you want to restore the project "${
            project.name
          }" to "${this.formatDate(createdAt)}"?`
        )
      )
        return;

      this.setLoading("restoreBackup", changelogId);
      this.setToggleables("restoreBackupSuccess", null);

      let { changeLogs } = await api.get("v1/project/changelog", {
        filter: JSON.stringify({
          where: {
            type: "project",
            project: project._id,
          },
          order: -1,
          sortby: "createdAt",
          limit: 1,
        }),
      });

      let lastBackupTime = changeLogs?.[0]?.createdAt;

      const lastBackupTimeMs = lastBackupTime
        ? new Date(lastBackupTime).getTime()
        : 0;

      if (Date.now() - lastBackupTimeMs > 1000 * 60 * 60) {
        await this.createBackup(project._id);
      }

      await api.patch("v1/project/changelog", {
        project: project._id,
        changeLog: changelogId,
      });

      this.load();

      this.setLoading("restoreBackup", false);

      // toast.success(`Project is restored successfully`);
      this.setToggleables("restoreBackupSuccess", changelogId);
    } catch (e) {
      this.setLoading("restoreBackup", false);

      toast.error(e.message);
    }
  };

  render() {
    const {
      props: { filter = {}, projects, data },
      state: { loading },
    } = this;

    const mergeFilter = (obj) => {
      this.props.setScreenState({ filter: { ...filter, ...obj } });
      this.triggerReload();
    };

    const selectedProject = projects?.find((x) => x._id === filter?.project);

    return (
      <div className="userPanel">
        <SideNav activeSidebarItem="backups" key="sidenav" />
        <div className="upMain analyticsScreen">
          <NavBar activeLink="/backups" />

          <div className="analyticsHeader">
            <div className="analyticsTitle">
              Backups Dashboard{" "}
              {loading ? (
                <div
                  style={{
                    float: "right",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <div className="loader"></div>
                </div>
              ) : null}
            </div>
            <div className="analyticsFilters">
              <CustomSelect
                onChange={(option) => mergeFilter({ project: option.value })}
                value={filter.project || ""}
                placeholder="All Project"
                options={projects?.map((x) => ({
                  value: x._id,
                  label: x.name,
                }))}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
                labelIcon={
                  <div className="labelIco">
                    <Avatar
                      user={{
                        firstName: "",
                        image: selectedProject?.image,
                      }}
                    />
                  </div>
                }
              />

              <DatePicker
                selected={filter.selectedDate}
                onChange={(date) => mergeFilter({ selectedDate: date })}
              />

              <div
                className="upAction"
                onClick={() => this.createBackup(filter.project)}
                style={
                  filter.project
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed" }
                }
              >
                {this.state.loadingObj.createBackup ? (
                  <>Processing</>
                ) : (
                  <>
                    <span>+</span> Backup Now
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="upBody">
            <div className="upBodyInner">
              {loading && !data?.changeLogs ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loader large"></div>
                </div>
              ) : (
                <div className="fullDataTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Sl</th>
                        <th>Project</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.changeLogs?.map((changeLog, index) => {
                        return (
                          <tr key={index} onClick={() => {}}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="tableData">
                                <div
                                  style={{ width: "20px", maxHeight: "20px" }}
                                >
                                  <Avatar user={changeLog.project} />
                                </div>
                                {changeLog.project?.name}
                              </div>
                            </td>
                            <td>
                              <div className="tableData">
                                {this.formatDate(changeLog.createdAt)}
                              </div>
                            </td>
                            <td>
                              {this.state.loadingObj.restoreBackup ===
                              changeLog._id ? (
                                <div className="tableData">Processing</div>
                              ) : this.state.toggleables
                                  ?.restoreBackupSuccess === changeLog._id ? (
                                <div className="tableData cGreen">Complete</div>
                              ) : null}
                            </td>
                            <td>
                              <div className="tableData">
                                <div className="minActionRow">
                                  <div
                                    onClick={() =>
                                      this.RestoreBackup(changeLog)
                                    }
                                    className="minButton"
                                  >
                                    Restore
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  filter: state.vState[SCREEN_NAME].filter || {},
  data: state.vState[SCREEN_NAME].data || {},
  projects: state.vState.PROJECTS.projects,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupsScreen);
